
import {
  defineComponent,
  onMounted,
  getCurrentInstance,
  ref,
  computed,
  provide,
} from "vue";
import { useStore } from "vuex";
import { Mutations,Actions } from "@/store/enums/StoreEnums";
import Customers from "@/core/services/Callable/Customers";
import { useRoute } from "vue-router";
import browser from "./browser.js";
import Auths from "./core/services/Callable/Auths";
import { statusModal } from "@/store/stateless/store";
import BrowserModal from "@/components/modals/extras/Browser.vue";
import socket from "@/main-socket";
import Etcs from "@/core/services/Callable/Etcs";
import { ElNotification } from "element-plus";

export default defineComponent({
  name: "app",
  components: {
    BrowserModal,
  },
  setup() {
    const store = useStore();
    const currentInst = getCurrentInstance();
    const roles = currentInst?.appContext.config.globalProperties.roles;
    const router = useRoute();
    let countdown = ref(60);

    // const datetime = ref<any>(null);
    // const respo = ref<any>([]);

    const user = computed(() => {
      const users = window.localStorage.getItem("user");
      const user = users ? JSON.parse(users || "") : "";
      return user;
    })

    const user_id = computed(() => {
      // return store.getters.myId;
      let userId = window.localStorage.getItem('uid');
      if(!userId){
        userId = store.getters.myId;
      }
      return userId;
    });

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */

      // const users = window.localStorage.getItem("user");
      // const user = users ? JSON.parse(users || "") : "";

      if (user && user.value.role_id === 1) {
        Customers.setFilter({
          agent: "all",
          customer_status_id: -1,
          end_date: "",
          index: 1,
          keyword: "",
          lead_status_id: -1,
          page: 1,
          per_page: 50,
          start_date: "",
          task_due_dates: -1,
          hot_enquiry: -1,
        });
      }

      /**
       * Calling routes api if user logged in and
       * myRoute empty in localstorage
       */
      if (
        user &&
        typeof user.value.id !== "undefined" &&
        !window.localStorage.getItem("myRoute")
      ) {
        Etcs.getRoutes();
      }

      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      // if (router.name != "sign-in") {
      //   countDownTimer();
      // } else {
      //   countdownToken();
      // }
      // countdown.value = refreshTime(0);

      checkBroweser();

      const currentUserId = store.getters.myId;
      console.log("currentUserId", currentUserId);


      // socket
      //   .channel("health-channel-" + user_id.value)
      //   .listen("Health.HealthPopupNotification", async (response: any) => {
      //     console.log("HealthPopupNotification 3", response);
      //     respo.value.push(response);

      //     if (response.is_popup) {
      //       let contentHtml = (await response.url) != ""
      //                         ? "<a href='#" + response.url + "'>" + response.content + "</a>"
      //                         : response.content;
      //       setTimeout(async () => {
      //         if (datetime.value !== response.dateTime) {
      //           await respo.value.filter(
      //             (item) => item.dateTime === response.dateTime
      //           );
      //           await respo.value.forEach((x) => {
      //             popupNotification(contentHtml, x.isNotifStay);
      //           });

      //           respo.value = [];
      //         }
      //         datetime.value = response.dateTime;
      //       }, 1000);
      //     }
      //   });

      if(window.localStorage.getItem("id_token")){
        const recheckToken = true;
        if(recheckToken){
          Auths.getNewAuthCheck(recheckToken);
        }    

      }

      
    });

    // const popupNotification = (contentHtml, isNotifStay) => {
    //   ElNotification({
    //     customClass: isNotifStay ? "notification-skye" : "notification-success",
    //     title: "Notification",
    //     message: contentHtml,
    //     duration: isNotifStay ? 10000 : 2500,
    //     type: isNotifStay ? "info" : "success",
    //     dangerouslyUseHTMLString: true,
    //   });
    // };

    // const tokenCheckerLocalStorageTime = ref(5);
    // const tokenCheckerLocalStorageTimeSignIn = ref(5);

    // const countdownToken = () => {
    //   // if(tokenCheckerLocalStorageTimeSignIn.value > 0) {
    //   setTimeout(() => {
    //     tokenCheckerLocalStorageTimeSignIn.value -= 1;
    //     countdownToken();
    //   }, 1000);
    //   // }

    //   if (tokenCheckerLocalStorageTimeSignIn.value <= 0) {
    //     const token = window.localStorage.getItem("id_token");
    //     tokenCheckerLocalStorageTimeSignIn.value = 5;
    //     countdownToken();
    //     if (token) {
    //       if (router.name == "sign-in") {
    //         // router.push('/dashboard')
    //         window.location.reload();
    //       }
    //     }
    //   }
    // };

    // const countDownTimer = () => {
    //   const refresh_token = window.localStorage.getItem("refresh_token") || "";
    //   if (refresh_token) {
    //     if (countdown.value > 0) {
    //       setTimeout(() => {
    //         countdown.value -= 1;
    //         countDownTimer();
    //         // console.log('countdown',countdown.value)
    //       }, 1000);
    //     }

    //     if (countdown.value <= 0) {
    //       countdown.value = refreshTime(0);
    //       countDownTimer();
    //       const token = window.localStorage.getItem("id_token");
    //       if (token) {
    //         const payload = {
    //           refresh_token,
    //         };

    //         Auths.VerifyToken(payload);

    //         // store.dispatch(Actions.CHECK_TOKEN, payload)
    //       }
    //     }

    //     if (tokenCheckerLocalStorageTime.value > 0) {
    //       setTimeout(() => {
    //         tokenCheckerLocalStorageTime.value -= 1;
    //       }, 1000);
    //     }

    //     if (tokenCheckerLocalStorageTime.value <= 0) {
    //       const token = window.localStorage.getItem("id_token");
    //       tokenCheckerLocalStorageTime.value = 5;
    //       if (!token) {
    //         // store.commit(Mutations.SET_USER, {});
    //         // store.commit(Mutations.SET_USER_ACL, {});
    //         window.localStorage.clear();

    //         window.location.reload();
    //       }
    //     }
    //   }
    // };

    // const refTime = store.getters.getRefreshTime;

    // const refreshTime = (value) => {
    //   // return 5;
    //   if (value != 0) {
    //     return value;
    //   } else {
    //     const expiry = parseInt(
    //       window.localStorage.getItem("expires_in") || "3300"
    //     );
    //     return expiry / 2 - 300;
    //   }
    // };

    const checkBroweser = () => {
      var result = browser.getParser(window.navigator.userAgent);
      if (
        result.parsedResult.browser.name != "Firefox" &&
        result.parsedResult.browser.name != "Chrome" &&
        result.parsedResult.browser.name != "Opera"
      ) {
        statusModal.isNotValidBrowser = true;
      }
    };

    function changeRole() {
      console.log('change')
      window.location.href = '/#/dashboard'
      window.location.reload()
    }
    
    function updateToken(data) {
      console.log('updateToken')
        window.localStorage.setItem('item_data', JSON.stringify(data))
        if(data.status == 400) {
          Auths.setAuthNull()
        }

        if(data && data.status == 200) {
          window.localStorage.setItem('refresh_token', data.refresh.refresh_token)
          window.localStorage.setItem('id_token', data.refresh.access_token)
          window.location.reload()
        }  
    }

    /**
     * Websocket for switch app reload
     */
    function switchAppReloadWS(data) {
      if(data.switch_app_url){
          if (!document.hidden) {
              /**
               * Only redirect to the switch app url 
               * received from the swithc app api in 
               * the active tab
                 */
                window.location.href = data.switch_app_url;
          }else{
                /**
                 * All other tabs we will clear 
                 * the local storage and redirect which will
                 * open the sign in page
                 */
                window.localStorage.clear();
                window.location.reload();
          }
      }
    }

    provide('details', {
      aspect: window.devicePixelRatio
    })

    return {
      user_id,
      updateToken,
      switchAppReloadWS,
      changeRole
    }
  },
});
