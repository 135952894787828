
import { defineComponent, computed } from "vue";
import { leads_tasks } from "@/core/utils/leads.js";
import { store } from "@/store";
import { UserTypesMixin } from "@/core/plugins/vcMixins";
import { customer, copyText, common } from "@/store/stateless";
import router from "@/router/clean";
import moment from "moment";

export default defineComponent({
  setup() {
    const loading = computed(() => {
      return store.state.customer.loading;
    });

    const lists = computed(() => {
      return store.getters.getLeadsAndTasks;
    });

    const toDetailed = (e) => {
      const route = router.resolve({
        name: "CustomerDetails",
        params: { customer_id: e.customer_id },
      });
      if (route) {
        window.open(route.href);
      }
    };

    const formatDate = (date) => {
      return moment(date, "YYYY-MM-DD HH:ss").format("DD/MM/YYYY HH:ss");
    };

    const clickTransfer = () => {
      customer.transferAll = true;
    }

    return {
      lists,
      common,
      loading,
      customer,
      copyText,
      leads_tasks,
      toDetailed,
      formatDate,
      UserTypesMixin,
      clickTransfer
    };
  },
});
