
    import { defineComponent, onMounted, computed, ref } from "vue";
    import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
    import router from '@/router/clean';
    import moment from 'moment';
    import { store } from "@/store";
    import { useRoute } from 'vue-router'
  
  
    export default defineComponent({
      name: "dashboard",
      props:{
        allowAll:{
          required:false,
          type: Boolean,
          default: false
        }
      },
      setup(_,{ emit }) {

        const route = useRoute()
  
        const user = computed(() => {
          const users = window.localStorage.getItem("user");
          const user = users ? JSON.parse(users || "") : "";
          console.log('user', user)
          return user;
        })
  
  
        const filter = computed(() => {
          return store.state.dashboard.filter;
        })
  
  
        const daterange = ref < any > ();
  
        const year = new Date().getFullYear();
  
        const yearsList = computed(() => {
          return Array.from(
            { length: year - 2017 },
            (value, index) => year - index
          );
        });

        const salesOverview = computed(() => {
          return store.getters.getSalesOverviewData
        })
  
  
        onMounted(() => {
            
        });
  
        const onChangeDateTab = (value) => {
          filter.value.type = value;
          var date = new Date();

          if (value == "month") {
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            daterange.value = [firstDay, lastDay];
          }

          if (value == "lastMonth") {
            var firstDay = new Date(date.getFullYear(), date.getMonth() -1, 1);
            var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
            daterange.value = [firstDay, lastDay];
          }

          if (value == "today") {
            daterange.value = [date, date];
          }

          if (value == "yesterday") {
            var previous = new Date(date.getTime());
            previous.setDate(date.getDate() - 1);
            daterange.value = [previous, previous];
          }

          if (value == 'PreviousMonth') {
            var firstDay = new Date(date.getFullYear(), date.getMonth() -2, 1);
            var lastDay = new Date(date.getFullYear(), date.getMonth() -1, 0);
            daterange.value = [firstDay, lastDay];
          }

          getStatistics(daterange.value);

        };
  
        const onYearSelect = (event) => {
          let firstDay = new Date(event, 1 - 1);
          let lastDay = new Date(event, 12, 0);
          daterange.value = [firstDay, lastDay];
  
          const start = moment(daterange[0]).format('YYYY-MM-DD');
          const end = moment(daterange[1]).format('YYYY-MM-DD');
  
          filter.value.start_date = start;
          filter.value.end_date = end;
          filter.value.type = "";
  
          console.log('filter year', filter.value)
          getStatistics(daterange.value);
        };
  
        const onChangeDatePicker = (listPeriod) => {
          console.log('change Date',listPeriod)
  
          filter.value.start_date = "";
          filter.value.end_date = "";
          filter.value.type = "";
          filter.value.year = "";
  
          if (listPeriod) {
  
  
            const start = moment(listPeriod[0]).format('YYYY-MM-DD');
            const end = moment(listPeriod[1]).format('YYYY-MM-DD');
  
            filter.value.start_date = start;
            filter.value.end_date = end;
            filter.value.type = "";
  
            console.log('filter', filter.value)
  
            // const dateTitle = '('+startTS.getDate()+' '+startTS.toLocaleString('en-us',{ month:'long', year:'numeric'})+' - '+endTS.getDate() + ' ' +endTS.toLocaleString('en-us',{ month:'long', year:'numeric'})+')';
            // store.commit(Mutations.SET_DASHBOARD_DATE_TITLE, dateTitle)
  
            getStatistics(listPeriod);
          }
          else {
            const nameRoute = router.currentRoute.value.name;
            if ([1, 14].includes(user.value.role_id) || nameRoute == 'admin-dashboard') {
              // getToday();
              onChangeDateTab('today')
            }
            else {
              filter.value.type = 'month';
              onChangeDateTab('month')
              // currentMonth();
            }
          }
        };
  
        const tabHandle = (e: number) => {
          filter.value.tab = e
          emit('getData')
        }

        const getStatistics = (daterange) => {
            const startDate = daterange[0];
            const endDate = daterange[1];

            filter.value.start_date =  moment(startDate).format("YYYY-MM-DD")
            filter.value.end_date =  moment(endDate).format("YYYY-MM-DD")
            emit('getData')
        }

        const underwriterOverview = computed(() => {
          return store.getters.getUnderwriterData
        })

        const showOverDues = computed(() => {
          if(route.name == 'adminDashboard') return false
          return true
        })
        
  
        return {
          underwriterOverview,
          user,
          daterange,
          onChangeDateTab,
          onYearSelect,
          onChangeDatePicker,
          yearsList,
          filter,
          tabHandle,
          salesOverview,
          showOverDues
  
        }
      },
    });
  