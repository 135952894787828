import { reactive } from "vue";
import { store } from "@/store";

const user = JSON.parse(window.localStorage.getItem('user'));

const constant = reactive({
    signin: {
        email: null,
        password: null,
        error:null
    },
    quicklead: {
        email: "",
        is_health: 1,
        lead_source: "skye",
        name: "",
        phone_country_code: "+971",
        phone_number: "",
        salesAgents: user && user.role_id != 1 ? user.id : "",
    },

    view_task: {
        open: false,
        lead_id: 0,
        lead_status_id: 0,
        customer_id: 0,
        agent: 0,
        is_fullscreen: false
    },

    view_declarations: {
        open: false,
        lead_id: 0,
        lead_information_id: 0,
        is_fullscreen: false,
        disabled:false
    },

    view_policies: {
        open: false,
        lead_id: 0,
        customer_id: 0,
        is_fullscreen: false
    },

    merge_lead: {
        open: false,
        quick_lead_id: 0,
        merge_lead_id: 0,
    },

    user_status: [
        {
            label: 'Select user status',
            value: '',
        },
        {
            label: 'Active',
            value: '1',
        },
        {
            label: 'Inactive',
            value: '0',
        },
    ],

    insuredFamilyValue : 3,

    insuredParentValue : 5,
    insuredEmployeeValue : 6,
    insuredOtherValue : 7,

    insuredMyselfValue : 1,

    adminRole: 1,
    salesAgentRole: 2,
    underwriterRole:4,
    accountantRole:6,
    salesManagerRole:9,
    tlRole:13,
    qcaRole:14,

    policy_statuses:[
        {
            "value": 2,
            "label": "Assigned"
        },
        {
            "value": 6,
            "label": "Cancellation Approval"
        },
        {
            "value": 8,
            "label": "Cancellation Approved"
        },
        {
            "value": 9,
            "label": "Cancellation Declined"
        },
        {
            "value": 5,
            "label": "Cancellation Request"
        },
        {
            "value": 7,
            "label": "Cancelled"
        },
        {
            "value": 4,
            "label": "Completed"
        },
        {
            "value": 11,
            "label": "Deal Transferred"
        },
        {
            "value": 10,
            "label": "Refund Closed"
        },
        {
            "value": 1,
            "label": "Transaction done"
        }
      ],

      invoice_status : [
            {"value": 1,'label':'Pending'},
            {"value": 2,'label':'Approved'},
            {"value": 3,'label':'Declined'},
      ],

      upload_documents: {
        disabled: false,
        lead_id: 0,
        group_name: '',
        policy_id: 0
    },

    

    delete_members: {
        open: false,
        lead_id: 0,
        customer_id: 0,
        is_fullscreen: false
    },

    northern_emirates : [1,3,4,5,6],
    basmah_emirates : [2],
    dubai_emirate: 2,

});

export {
    constant
};