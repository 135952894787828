
import { defineComponent, ref, computed } from 'vue'
import Customers from '@/core/services/Callable/Customers';
import { store } from "@/store";
import router from '@/router/clean';
import { customer } from '@/store/stateless';

export default defineComponent({
    setup() {
        const pages = ref([50, 100, 150, 200, 500])

        const filter = computed(() => {
            return store.state.customer.filter
        })

        const selectedPage = async () => {
            await Customers.customerLists(filter.value);
        }
        const customerLists = computed(() => {
            return store.state.customer.customerLists
        })

        console.log('customerLists',customerLists.value)

        const getLists = async (e: any) => {
            customer.checkedTransfer = [];
            const page = e;
            router.replace({ query: { page } })
                .catch(error => {
                    if (
                        error.name !== 'NavigationDuplicated' &&
                        !error.message.includes('Avoided redundant navigation to current location')
                    ) {
                        console.log(error)
                    }
                });
            filter.value.page = page;

            await Customers.customerLists(filter.value);
        }

        return {
            filter,
            pages,
            customerLists,
            selectedPage,
            getLists
        }
    },
})
