
import { computed, defineComponent, ref, getCurrentInstance } from 'vue'
import { store } from "@/store";
import Customers from '@/core/services/Callable/Customers';
import { notification } from '@/store/stateless';

interface AddImportParams {
    file: string;
}

export default defineComponent({
    setup() {
        let addImportParams = ref<AddImportParams>({
            file: "",
        });

        const importModal = computed(() => {
            return store.state.customer.importModal;
        })

        const loading = ref(false)

        const importRef = ref()

        let data: any;
        const handleFileUpload = (event) => {
            data = new FormData();
            let file = event.target.files[0];
            addImportParams.value.file = file;
            data.append("file", file);
        };

        const rules = ref({
            file_data: {
                show: false,
                message: "File is required",
            },
        });

        const inst = getCurrentInstance()

        const importFile = async () => {
            if (addImportParams.value.file == "") {
                rules.value.file_data.show = true;
            }
            else {
                if(loading.value)
                    return

                loading.value = true

                await Customers.import(data)
                    .then((data) => {
                        notification.success(
                            'Import',
                            data.data.message,
                            0
                        );

                        const uploadRef:any = inst?.refs[`upload`]
                        if(uploadRef){
                            uploadRef.value = null;
                        }
                        // resetFormValue();
                        loading.value = false
                        // Customers.setImportModal(false);
                        closeImportMdal()
                    });
            }
        };

        const resetFormValue = () => {
            addImportParams.value.file = "";
            rules.value.file_data.show = false;
        };

        const closeImportMdal = () => {
            importRef.value.reset();
            resetFormValue();
            Customers.setImportModal(false);
        }

        return {
            rules,
            loading,
            importModal,
            addImportParams,
            closeImportMdal,
            handleFileUpload,
            importFile,
            importRef
        }
    },
})
