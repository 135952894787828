
import router from '@/router/clean'
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        value: {
            type: String,
            required: true,
            default: ''
        },
        text: {
            type: String,
            required: false,
            default: 'Cancel'
        },
        params: {
            type: Object,
            required: false,
            default: {}
        },
        query: {
            type: Object,
            required: false,
            default: {}
        },
        isIcon: {
            type: Boolean,
            required: false,
            default: false
        },
        icon: {
            type: String,
            required: false,
            default: 'far fa-arrow-alt-circle-left'
        },
        className: {
            type: String,
            required: false,
            default: 'btn btn-secondary me-3'
        },
        onlyIcon: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    setup(_) {
        const nav = () => {
            let route: any = {}
            if(Object.keys(_.params).length > 0) {
                route = router.resolve({ name: _.value, params: _.params })
            } else {
                route = router.resolve({ name: _.value })
            }
            if(Object.keys(_.query).length > 0) route.query = _.query

            const corrected = router.resolve(route)
            window.location.href = corrected.href
        }
        return {
            nav
        }
    }

})
