
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        loading: {
            type: Boolean,
            required: true,
            default: false
        },
        text: {
            type: String,
            required: false,
            default: '&nbsp;&nbsp;Save&nbsp;&nbsp;'
        },
        icon : {
            type: String,
            required: false,
            default: ''
        },
        disabled : {
            type: Boolean,
            required: false,
            default: false
        }
        
    },

})
