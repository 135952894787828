import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import {store} from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import Auths from '@/core/services/Callable/Auths';
import Etcs from '@/core/services/Callable/Etcs';

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "admin-dashboard",
        name: "adminDashboard",
        component: () => import("@/views/AdminDashboard.vue"),
      },

      {
        path: "test",
        name: "test",
        component: () => import("@/views/test.vue"),
      },
    ],
  },
  {
    path: "/customers",
    redirect: "/customers/list",
    component: () => import("@/layout/Layout.vue"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'CustomerLists',
        component: () => import("@/views/Customers/index.vue")
      },
      {
        path: 'add-detail',
        name: 'AddDetail',
        component: () => import("@/views/Customers/Store.vue")
      },
      {
        path: 'add-corporate',
        name: 'AddCorporate',
        component: () => import("@/views/Customers/Corporate.vue")
      },
      {
        path: 'details/:customer_id',
        name: 'CustomerDetails',
        component: () => import("@/views/Customers/Detailed.vue")
      },
      {
        path: 'edit-detail/:lead_id',
        name: 'EditDetail',
        component: () => import("@/views/Customers/Edit.vue")
      },
      {
        path: 'edit-corporate-detail/:lead_id',
        name: 'EditCorporateDetail',
        component: () => import("@/views/Customers/EditCorporate.vue")
      },
      {
        path: 'generate-quote/:lead_id',
        name: 'GenerateQuote',
        component: () => import("@/views/Customers/GenerateQuote.vue")
      },
      {
        path: 'make-invoice/:lead_id',
        name: 'MakeInvoice',
        component: () => import("@/views/Customers/MakeInvoice.vue")
      },
      {
        path: 'new-lead/:customer_id',
        name: 'NewDetail',
        component: () => import("@/views/Customers/New.vue")
      },
      {
        path: 'new-corporate-lead/:customer_id',
        name: 'NewGroupDetail',
        component: () => import("@/views/Customers/NewCorporate.vue")
      },
      {
        path: 'make-group-invoice/:lead_id',
        name: 'MakeGroupInvoice',
        component: () => import("@/views/Customers/MakeGroupInvoice.vue")
      },
    ],
  },
  {
    path: "/invoice",
    redirect: "/invoice/approval",
    component: () => import("@/layout/Layout.vue"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'approval',
        name: 'invoiceApproval',
        component: () => import("@/views/Invoice/Approval.vue")
      },
      {
        path: 'list',
        name: 'invoiceApproved',
        component: () => import("@/views/Invoice/InvoiceApproved.vue")
      },
      {
        path: 'decline',
        name: 'invoiceDeclined',
        component: () => import("@/views/Invoice/InvoiceDeclined.vue")
      },
      {
        path: 'amend/add/:invoice_id/:lead_id',
        name: 'addAmendInvoice',
        component: () => import("@/views/Invoice/AddAmendInvoice.vue")
      },
      {
        path: 'amend/sub/:invoice_id/:lead_id',
        name: 'subAmendInvoice',
        component: () => import("@/views/Invoice/SubAmendInvoice.vue")
      },
      {
        path: 'group/approval',
        name: 'groupInvoiceApproval',
        component: () => import("@/views/Invoice/GroupApproval.vue")
      },
      {
        path: 'group/list',
        name: 'groupInvoiceApproved',
        component: () => import("@/views/Invoice/GroupInvoiceApproved.vue")
      },
      {
        path: 'group/decline',
        name: 'groupInvoiceDeclined',
        component: () => import("@/views/Invoice/GroupInvoiceDeclined.vue")
      },
      {
        path: 'group/amend/add/:invoice_id/:lead_id',
        name: 'addGroupAmendInvoice',
        component: () => import("@/views/Invoice/AddGroupAmendInvoice.vue")
      },
      {
        path: 'group/amend/add-sub-group/:invoice_id/:lead_id',
        name: 'addSubGroupInvoice',
        component: () => import("@/views/Invoice/AddSubGroupAmendInvoice.vue")
      },
      {
        path: 'group/amend/sub/:invoice_id/:lead_id',
        name: 'subGroupAmendInvoice',
        component: () => import("@/views/Invoice/SubGroupAmendInvoice.vue")
      },
    ],
  },
  {
    path: "/policy",
    redirect: "/policy/assigned",
    component: () => import("@/layout/Layout.vue"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'assigned',
        name: 'assignedPolicies',
        component: () => import("@/views/Policies/AssignedPolicies.vue")
      },
      {
        path: 'edit/:policy_id',
        name: 'editPolicy',
        component: () => import("@/views/Policies/EditPolicy.vue")
      },
      {
        path: 'view/:policy_id',
        name: 'viewPolicy',
        component: () => import("@/views/Policies/ViewPolicy.vue")
      },
      {
        path: 'completed',
        name: 'completedPolicies',
        component: () => import("@/views/Policies/CompletedPolicies.vue")
      },
      {
        path: 'group/assigned',
        name: 'groupAssignedPolicies',
        component: () => import("@/views/Policies/GroupAssignedPolicies.vue")
      },
      {
        path: 'group/edit/:policy_id',
        name: 'groupEditPolicy',
        component: () => import("@/views/Policies/GroupEditPolicy.vue")
      },
      {
        path: 'group/view/:policy_id',
        name: 'groupViewPolicy',
        component: () => import("@/views/Policies/GroupViewPolicy.vue")
      },
      {
        path: 'group/completed',
        name: 'groupCompletedPolicies',
        component: () => import("@/views/Policies/GroupCompletedPolicies.vue")
      },

      
    ],
  },
  {
    path: "/report",
    component: () => import("@/layout/Layout.vue"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'policies-assigned-vs-completed',
        name: 'PoliciesAssignedVsCompleted',
        component: () => import("@/views/Reports/PoliciesAssignedVsCompleted.vue")
      },
      {
        path: 'agents',
        name: 'ReportAgents',
        component: () => import("@/views/Reports/AgentSales.vue")
      },
      {
        path: 'admin-report',
        name: 'ReportAdmin',
        component: () => import("@/views/Reports/AdminReport.vue")
      },
      {
        path: 'approved-invoice',
        name: 'ReportApprovedInvoices',
        component: () => import("@/views/Reports/ApprovedInvoices.vue")
      },
      {
        path: 'account-master',
        name: 'ReportAccountMaster',
        component: () => import("@/views/Reports/AccountMaster.vue")
      },
      {
        path: 'dashboard-details',
        name: 'ReportDashboardDetails',
        component: () => import("@/views/Reports/DashboardDetails.vue")
      },
      {
        path: 'deleted-leads',
        name: 'ReportDeletedLeads',
        component: () => import("@/views/Reports/DeletedLeads.vue")
      },
      {
        path: 'closed-leads',
        name: 'ReportClosedLeads',
        component: () => import("@/views/Reports/ClosedLeads.vue")
      },
      {
        path: 'converted-leads',
        name: 'ReportConvertedLeads',
        component: () => import("@/views/Reports/ConvertedLeads.vue")
      },

      {
        path: 'lead-by-source',
        name: 'ReportLeadBySource',
        component: () => import("@/views/Reports/LeadBySource.vue")
      },

      {
        path: 'production-report',
        name: 'ReportProduction',
        component: () => import("@/views/Reports/ProductionReport.vue")
      },

      {
        path: 'marketing-report',
        name: 'ReportMarketing',
        component: () => import("@/views/Reports/MarketingCampaignReport.vue")
      },
      
      {
        path: 'marketing-report-agent',
        name: 'ReportMarketingAgent',
        component: () => import("@/views/Reports/MarketingCampaignReportAgent.vue")
      },
      
    ],
  },
  {
    path: "/sales/achievements",
    name: "sales-achievements",
    component: () => import("@/views/SalesDashboard.vue"),
    meta: {
      requiresAuth: true
    },
  },
 
  {
    path: "/user",
    redirect: "/user/list",
    component: () => import("@/layout/Layout.vue"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'UserLists',
        component: () => import("@/views/Users/index.vue")
      },
      {
        path: 'store',
        name: 'StoreUser',
        component: () => import("@/views/Users/Store.vue")
      },
      {
        path: 'update/:user_id',
        name: 'UpdateUser',
        component: () => import("@/views/Users/Update.vue")
      },
    ],
  },
  {
    path: "/inquiry",
    redirect: "/inquiry/list",
    component: () => import("@/layout/Layout.vue"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'UserLists',
        component: () => import("@/views/Inquiry/index.vue")
      },
    ],
  },
  {
    path: "/notification",
    redirect: "/notification/list",
    component: () => import("@/layout/Layout.vue"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'NotificationLists',
        component: () => import("@/views/Notification/index.vue")
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    meta: {
      requiresVisitor: true
    },
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
      {
        path: "/auth/token/:token",
        name: "fetchToken",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/Authentication.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
  {
    path: "/switch-app/:switchTo/:refreshToken",
    name: "switch-app",
    component: () => import("@/views/SwitchApp.vue"),
  },
  {
    path: "/marketing",
    redirect: "/marketing/spend",
    component: () => import("@/layout/Layout.vue"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'spend',
        name: 'MarketingSpend',
        component: () => import("@/views/MarketingSpend.vue")
      },
    ],
  },

];



const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.auth.token) {
        next({
          path: 'sign-in'
        });
    } else {
        next();
    }
} else if(to.matched.some(record => record.meta.requiresVisitor)) {
  if(store.state.auth.token) {
    next({
      path: '/dashboard'
    })
  } else {
    next();
  }
} else {
  next();
}
store.commit(Mutations.RESET_LAYOUT_CONFIG);

if(store.state.auth.token) {
  // Auths.VerifyToken();
}



});

export default router;
