import { Quicklead, Lists, Counts, Health, Lead_information, Details, Transfer, Lead_task, Task_details, Notes, Listing, Emails, Customer_status, customer_log, Declarations } from '@/core/types/Customer';
import moment from 'moment';

export type State = {
  quickLead: Quicklead | null;
  quckLeadModal: boolean;
  importModal: boolean;
  transferModal: boolean;
  detailLoading: boolean;
  loading: boolean;
  filter: Lists;
  customerLists: Listing;
  customerListCounts: Array<Counts>;
  leadFilterCounts: Object | null;
  health: Health
  leadInformation: Lead_information;
  declarations:Array<Declarations>
  leadId: number;
  detailsCustomer: Details;
  checkedTransfer: Array<number>;
  transfer: Transfer;
  lead_task: Lead_task
  task_loading: boolean
  task_details: Task_details
  notes_loading: boolean
  notes: Array<Notes>
  email_loading: boolean
  emails: Array<Emails>
  sms_loading: boolean
  sms: Array<any>
  activity_log_loading: boolean
  status_log_loading: boolean
  lead_activity_log_loading: boolean
  lead_status_log_loading: boolean
  customer_activity: Array<customer_log>
  customer_view_activity: Array<customer_log>
  customer_status: Array<Customer_status>
  lead_activity_log: Array<customer_log>
  lead_status_log: Array<Customer_status>
  email_content_data:{
    open: boolean,
    content:String
  },
  detailsCustomerLead:any
}

export const state: State = {
  quickLead: {
    email: "",
    is_health: 1,
    lead_source: "web",
    name: "",
    phone_country_code: "+971",
    phone_number: "",
    salesAgents: -1,
  },
  quckLeadModal: false,
  importModal: false,
  transferModal: false,
  loading: false,
  filter: {
    agent: "all",
    customer_status_id: -1,
    end_date: "",
    index: 1,
    keyword: "",
    lead_status_id: -1,
    page: 1,
    per_page: 50,
    start_date: "",
    task_due_dates: -1,
    hot_enquiry: -1,
  },

  customerLists: {
    current_page: 1,
    data: [],
    first_page_url: "",
    from: 1,
    last_page: 1,
    last_page_url: "",
    links: [],
    next_page_url: "",
    path: "",
    per_page: "",
    prev_page_url: "",
    to: 1,
    total: 1,
  } as Listing,
  customerListCounts: [],
  leadFilterCounts: {},
  health: {
    id: null,
    name: null,
    email: null,
    contact_person: null,
    group_size: 0,
    phone_number: null,
    phone_country_code: "+971",
    other_contact_info:null,
    insured_city: null,
    insured_type_id: 0,
    insured_type_id_prev: 0,
    insurance_type: 0,
    is_salary: null,
    expected_insurance_start_date: null,
    customer_source: null,
    is_health: 1,
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_content: null,
    dob: null,
    nationality_id: null,
    gender: null,
    is_married: false,
    company_name: null,
    is_self: null,
    agent: null,
    health_lead_map_id: 0,
    deleted_members: [],
    members: [{
      id: null,
      insured_type_id:  null,
      insured_city: null,
      member_name: null,
      relationship: null,
      gender: null,
      dob: null,
      nationality_id: null,
      is_salary: null,
      declaration_answers: [],
      is_married: false,
      }],
      categories: [],
      deleted_categories:[],
      is_renewal:false,
      is_llr:false,
      hasDealInvoice:false
  },
  leadInformation: {} as Lead_information,
  declarations:[] as Array<Declarations>,
  leadId: 0,

  detailsCustomer: {} as Details,
  detailLoading: true,
  checkedTransfer: [],
  transfer: {
    agent: '',
    notes: ''
  },
  lead_task: {
    customer_id: 0,
    agent: 0,
    lead_id: 0,
    disposition_id: 0,
    lost_lead_reason_id: 0,
    due_date: moment().format('YYYY-MM-DD HH:mm'),
    task_notes: '',
    converted_leads:[]
  },
  task_loading: false,
  task_details: {} as Task_details,
  notes_loading: true,
  notes: [] as Array<Notes>,
  email_loading: false,
  emails: [] as Array<Emails>,
  sms_loading: false,
  sms: [] as Array<any>,
  activity_log_loading: false,
  status_log_loading: false,
  customer_activity: [] as Array<customer_log>,
  customer_view_activity: [] as Array<customer_log>,
  customer_status: [] as Array<Customer_status>,
  lead_activity_log_loading: false,
  lead_status_log_loading: false,
  lead_activity_log: [] as Array<customer_log>,
  lead_status_log: [] as Array<Customer_status>,
  email_content_data:{
    open: false,
    content:''
  },
  detailsCustomerLead:{}
};
