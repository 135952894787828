// TODO: Remove no-shadow eslint disable after fixed: https://github.com/typescript-eslint/typescript-eslint/issues/2484
// eslint-disable-next-line no-shadow
export enum DocumentMutationTypes {
    SET_DOCUMENT_LISTS = 'SET_DOCUMENT_LISTS',
    SET_DOCUMENT_LIST_LOADING = 'SET_DOCUMENT_LIST_LOADING',
    SET_DOCUMENT_TYPES = 'SET_DOCUMENT_TYPES',
    SET_DOCUMENT_LOGS = 'SET_DOCUMENT_LOGS',
    SET_AMEND_DOCUMENT_LISTS = 'SET_AMEND_DOCUMENT_LISTS',
    SET_DOCUMENT_VALUE = 'SET_DOCUMENT_VALUE'
  }
  