import { MutationTree } from 'vuex';
import { ReportMutationTypes } from '@/store/enums/Report/Mutations';


import { State } from './state';

export type Mutations<S = State> = {
  
}

export const mutations: MutationTree<State> & Mutations = {

    [ReportMutationTypes.SET_REPORT_DASHBOARD_DETAILS](state: State, payload: any) {
        state.dashboard_details = payload
    },

    [ReportMutationTypes.SET_REPORT_DELETED_LEADS](state: State, payload: any) {
        state.deleted_leads = payload
    },

    [ReportMutationTypes.SET_REPORT_LEAD_BY_SOURCE_FILTER](state: State, payload: any) {
        console.log(payload)
        state.filters = payload
    },

    [ReportMutationTypes.SET_REPORT_LEAD_SOURCE_FILTER](state: State, payload: any) {
        state.lead_sources = payload
    },

    [ReportMutationTypes.SET_AGENT_SALES_REPORT](state: State, payload: any) {
        state.agent_sales = payload
    },

    [ReportMutationTypes.SET_AGENT_GROUP_SALES_REPORT](state: State, payload: any) {
        state.agent_group_sales = payload
    },

    [ReportMutationTypes.SET_POLICIES_ASSIGNED_VS_COMPLETED_REPORT](state: State, payload: any) {
        state.policies = payload
    },

    [ReportMutationTypes.SET_POLICIES_GROUP_ASSIGNED_VS_COMPLETED_REPORT](state: State, payload: any) {
        state.policies_group = payload
    },

    [ReportMutationTypes.SET_ADMIN_REPORT](state: State, payload: any) {
        state.admin_report = payload
    },
    
    [ReportMutationTypes.SET_APPROVED_INVOICES_REPORT](state: State, payload: any) {
        state.approved_invoices = payload
    },

    [ReportMutationTypes.SET_APPROVED_GROUP_INVOICES_REPORT](state: State, payload: any) {
        state.approved_group_invoices = payload
    },
    
    [ReportMutationTypes.SET_CLOSED_LEADS_REPORT](state: State, payload: any) {
        state.closed_leads = payload
    },

    [ReportMutationTypes.SET_CONVERTED_LEADS_REPORT](state: State, payload: any) {
        state.converted_leads = payload
    },

    [ReportMutationTypes.SET_LEAD_BY_SOURCE_REPORT](state: State, payload: any) {
        state.lead_by_source = payload
    },

    [ReportMutationTypes.SET_PRODUCTION_REPORT](state: State, payload: any) {
        state.production_report = payload
    },

    [ReportMutationTypes.SET_ACCOUNT_MASTER_REPORT](state: State, payload: any) {
        state.account_master = payload
    },

    [ReportMutationTypes.SET_MARKETING_CAMPAIGN_FILTERS](state: State, payload: any) {
        state.campaign_fiters = payload
    },

    [ReportMutationTypes.SET_MARKETING_CAMPAIGN_AMOUNT_SPEND](state: State, payload: any) {
        state.amount_spend = payload
    },

    [ReportMutationTypes.SET_MARKETING_REPORT](state: State, payload: any) {
        state.marketing_report = payload
    },
};
