import { App, Component } from "vue";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

/**
 * Reusables
 */
import vcAmount from '@/components/reusables/vcAmount.vue';
import vcAmountV2 from '@/components/reusables/vcAmountV2.vue';
import vcScocket from '@/components/reusables/vcSocket.vue';
import vcButton from '@/components/reusables/vcButton.vue';
import vcButtonSave from '@/components/reusables/vcButtonSave.vue';
import vcButtonNavigator from '@/components/reusables/vcButtonNavigator.vue';
import vcTable from '@/components/reusables/vcTable.vue';
import vcInput from '@/components/reusables/vcInput.vue';
import vcInputEmirateID from '@/components/reusables/vcInputEmirateID.vue';
import vcChangeApp from '@/components/reusables/vcChangeApp.vue';
import vcImports from '@/components/reusables/vcImports.vue';
import vcTransfer from '@/components/reusables/vcTransfer.vue';
import vcAgents from '@/components/reusables/vcAgents.vue';
import vcGender from '@/components/reusables/vcGender.vue';
import vcYesNo from '@/components/reusables/vcYesNo.vue';
import vcRelationships from '@/components/reusables/vcRelationships.vue';
import vcRelationshipDomestic from '@/components/reusables/vcRelationshipDomestic.vue';
import vcRelationshipParent from '@/components/reusables/vcRelationshipParent.vue';
import vcRelationshipEmployee from '@/components/reusables/vcRelationshipEmployee.vue';
import vcRelationshipOthers from '@/components/reusables/vcRelationshipOthers.vue';
import vcAgeGroup from '@/components/reusables/vcAgeGroup.vue';
import vcCountries from '@/components/reusables/vcCountries.vue';
import vcEmirates from '@/components/reusables/vcEmirates.vue';
import vcInsuranceType from '@/components/reusables/vcInsuranceType.vue';
import vcPaymentType from '@/components/reusables/vcPaymentType.vue';
import vcPaymentWarning from '@/components/reusables/vcPaymentWarning.vue';
import vcInsurancePayment from '@/components/reusables/vcInsurancePayment.vue';
import vcHealth from '@/components/reusables/vcHealth.vue';
import vcButtonLeads from '@/components/reusables/vcButtonLeads.vue';
import vcPlans from '@/components/reusables/vcPlans.vue';
import vcDatePicker from '@/components/reusables/vcDatePicker.vue';
import vcDatePickerV2 from '@/components/reusables/vcDatePickerV2.vue';
import vcDatePickerTime from '@/components/reusables/vcDatePickerTime.vue';
import vcDateRange from '@/components/reusables/vcDateRange.vue'
import vcProviders from '@/components/reusables/vcProviders.vue';
import vcPolicies from '@/components/reusables/vcPolicies.vue';
import vcTasklist from '@/components/reusables/vcTasklist.vue';
import vcLeadReason from '@/components/reusables/vcLeadReason.vue';
import vcPhone from '@/components/reusables/vcPhone.vue';
import vcRoles from '@/components/reusables/vcRoles.vue';
import vcStatus from '@/components/reusables/vcStatus.vue';
import vcAgentType from '@/components/reusables/vcAgentType.vue';
import vcRenewalDeals from '@/components/reusables/vcRenewalDeals.vue';
import vcAssignLeads from '@/components/reusables/vcAssignLeads.vue';
import vcAssignLanguage from '@/components/reusables/vcAssignLanguage.vue';
import vcTransferAgent from '@/components/reusables/vcTransferAgent.vue';
import vcCustomerStatus from '@/components/reusables/vcCustomerStatus.vue';
import vcUnderwriters from '@/components/reusables/vcUnderwriters.vue';
import vcTeamLeads from '@/components/reusables/vcTeamLeads.vue';
import vcCustomerDetails from '@/components/reusables/vcCustomerDetails.vue';
import vcEmployeeCategories from '@/components/reusables/vcEmployeeCategories.vue';
import vcDividers from '@/components/reusables/vcDividers.vue';
import vcDropDown from '@/components/reusables/vcDropDown.vue';
import vcClientClassification from '@/components/reusables/vcClientClassification.vue';
import vcGroupSize from '@/components/reusables/vcGroupSize.vue';
import vcReportFilters from '@/components/reusables/vcReportFilters.vue'
import vcExportReset from '@/components/reusables/vcExportReset.vue'

/**
 * Partials
 */
import healthTop from '@/components/customers/partials/healthTop.vue';
import healthMiddle from '@/components/customers/partials/healthMiddle.vue';
import myself from '@/components/customers/partials/myself.vue';
import domesticWorker from '@/components/customers/partials/domesticWorker.vue';
import family from '@/components/customers/partials/family.vue';
import declarations from '@/components/customers/partials/declarations.vue';
import healthLast from '@/components/customers/partials/healthLast.vue';
import quotePolicyDetails from '@/components/customers/partials/quotePolicyDetails.vue';
import invoiceInformation from '@/components/customers/partials/invoiceInformation.vue';
import tasks from '@/components/customers/partials/tasks.vue';
import notes from '@/components/customers/partials/notes.vue';
import taskData from '@/components/customers/partials/taskData.vue';
import notesData from '@/components/customers/partials/notesData.vue';
import emailsData from '@/components/customers/partials/emailsData.vue';
import smsData from '@/components/customers/partials/smsData.vue';
import customerLogData from '@/components/customers/partials/customerLogData.vue';
import taskForm from '@/components/customers/partials/taskForm.vue';
import accordionData from '@/components/customers/partials/accordionData.vue';
import corporateTop from '@/components/customers/partials/corporate/corporateTop.vue';
import corporateMiddle from '@/components/customers/partials/corporate/corporateMiddle.vue';
import employee from '@/components/customers/partials/corporate/employee.vue';
import employeeTable from '@/components/customers/partials/corporate/employeeTable.vue';
import groupInvoiceInformation from '@/components/customers/partials/corporate/groupInvoiceInformation.vue';
import amendGroupTable from '@/components/customers/partials/corporate/amendGroupTable.vue';
import groupInvoiceLog from '@/components/customers/partials/corporate/groupInvoiceLog.vue';
import groupSubAmendInvoiceInformation from '@/components/customers/partials/corporate/groupSubAmendInvoiceInformation.vue';
import subAmendGroupTable from '@/components/customers/partials/corporate/subAmendGroupTable.vue';
import subGroupAmendTable from '@/components/customers/partials/corporate/subGroupAmendTable.vue';


/**
 * Modals | Dialog
 */
import vcQuickLeads from '@/components/modals/vcQuickLeads.vue';
import UpdateCustomer from '@/components/modals/customer/UpdateCustomer.vue';
import UpdateOtherContactInfo from '@/components/modals/customer/UpdateOtherContactInfo.vue';
import ViewSponsor from '@/components/modals/customer/ViewSponsor.vue';
import ViewDetails from '@/components/modals/customer/ViewDetails.vue';
import ViewTasks from '@/components/modals/customer/ViewTasks.vue';
import ViewNotes from '@/components/modals/customer/ViewNotes.vue';
import ViewEmails from '@/components/modals/customer/ViewEmails.vue';
import ViewSMS from '@/components/modals/customer/ViewSMS.vue';
import ViewPolicies from '@/components/modals/customer/ViewPolicies.vue';
import ViewEmailContent from '@/components/modals/customer/ViewEmailContent.vue';
import ViewCustomerLog from '@/components/modals/customer/ViewCustomerLog.vue';
import ViewLeadActivity from '@/components/modals/customer/ViewLeadActivity.vue';
import ViewDocuments from '@/components/modals/customer/ViewDocuments.vue';
import ViewQuotes from '@/components/modals/customer/ViewQuotes.vue';
import CloseTask from '@/components/modals/customer/CloseTask.vue';
import AddNotes from '@/components/modals/customer/AddNotes.vue';
import MergeLead from '@/components/modals/customer/MergeLead.vue';
import ComposeEmails from '@/components/modals/customer/ComposeEmails.vue';
import InterestedPolicyModal from '@/components/modals/general/InterestedPolicyModal.vue';

/**
 * Customers Component
 */
import customerAccordion from '@/components/customers/customerAccordion.vue';
import customerTabs from '@/components/customers/customerTabs.vue';
import customerTaskDetails from '@/components/customers/customerTaskDetails.vue';
import customerFirstLayer from '@/components/customers/customerFirstLayer.vue';
import customerSecondLayer from '@/components/customers/customerSecondLayer.vue';
import customerThirdLayer from '@/components/customers/customerThirdLayer.vue';
import customerFourthLayer from '@/components/customers/customerFourthLayer.vue';
import customerTable from '@/components/customers/customerTable.vue';
import customerTableV2 from '@/components/customers/customerTableV2.vue'
import customerLastLayer from '@/components/customers/customerLastLayer.vue';

/**
 * Users Components
 */
import userForm from '@/components/users/userForm.vue'
import userTable from '@/components/users/userTable.vue'
import userLastLayer from '@/components/users/userLastLayer.vue'
import userFirstLayer from '@/components/users/userFirstLayer.vue'

/**
 * Plugins
 */
import Editor from "@tinymce/tinymce-vue";

/**
 * Documents
 */
import Upload from '@/components/documents/upload.vue'
import DocumentData from '@/components/documents/data.vue'

/**
 * Lead Components
 */

import ViewDeclarations from '@/components/modals/customer/lead/ViewDeclarations.vue'

/**
 * Invoice Components
 */

import InvoiceFirstLayer from '@/components/invoice/invoiceFirstLayer.vue'
import InvoiceSecondLayer from '@/components/invoice/invoiceSecondLayer.vue'
import InvoiceTable from '@/components/invoice/invoiceTable.vue'
import InvoicePagination from "@/components/invoice/invoicePagination.vue";
import InvoiceModal from "@/components/invoice/InvoiceModal.vue"; 
import InvoiceApprovalActionModal from "@/components/invoice/invoiceApprovalActionModal.vue"; 
import InvoiceApprovalActionDrpDown from "@/components/invoice/invoiceApprovalActionDrpDown.vue"; 
import InvoiceDetails from "@/components/invoice/invoiceDetails.vue"; 
import ApprovedInvoiceFirstLayer from "@/components/invoice/ApprovedInvoiceFirstLayer.vue";
import ApprovedInvoiceSecondLayer from "@/components/invoice/ApprovedInvoiceSecondLayer.vue";
import DeclinedInvoiceFirstLayer from "@/components/invoice/DeclinedInvoiceFirstLayer.vue";
import SubAmendInvoiceInformation from "@/components/invoice/SubAmendInvoiceInformation.vue";

import groupInvoiceTable from '@/components/invoice/groupInvoiceTable.vue'
import groupInvoiceFirstLayer from '@/components/invoice/groupInvoiceFirstLayer.vue'
import groupInvoiceSecondLayer from '@/components/invoice/groupInvoiceSecondLayer.vue'
import groupInvoicePagination from '@/components/invoice/groupInvoicePagination.vue'
import groupInvoiceActionDrpDown from "@/components/invoice/groupInvoiceActionDrpDown.vue";
import groupInvoiceDetails from "@/components/invoice/groupInvoiceDetails.vue";

import invoiceHasPending from "@/components/invoice/invoiceHasPending.vue";

/**
 * Policies Components
 */

import AssignedPoliciesFirstLayer from "@/components/policies/AssignedPoliciesFirstLayer.vue";
import AssignedPoliciesSecondLayer from "@/components/policies/AssignedPoliciesSecondLayer.vue";
import policyTable from "@/components/policies/policyTable.vue";
import policyGroupTable from "@/components/policies/policyGroupTable.vue";
import policyPagination from "@/components/policies/policyPagination.vue";
import AssignedPoliciesActionDrpDown from "@/components/policies/AssignedPoliciesActionDrpDown.vue";
import EditPolicyFirstLayer from "@/components/policies/EditPolicyFirstLayer.vue";
import EditPolicySecondLayer from "@/components/policies/EditPolicySecondLayer.vue";
import EditPolicyCreditDebitLayer from "@/components/policies/EditPolicyCreditDebitLayer.vue";
import EditPolicyUpdateDocumentsLayer from "@/components/policies/EditPolicyUpdateDocumentsLayer.vue";
import EditPolicyAmendDocumentsLayer from "@/components/policies/EditPolicyAmendDocumentsLayer.vue";
import EditPolicyEmailActivityLayer from "@/components/policies/EditPolicyEmailActivityLayer.vue";
import UpdateAmendDocuments from "@/components/policies/UpdateAmendDocuments.vue";
import EditPolicySendEmailLayer from "@/components/policies/EditPolicySendEmailLayer.vue";
import PolicyDocuments from "@/components/policies/PolicyDocuments.vue";
import PolicyTransferSalesAgent from "@/components/policies/PolicyTransferSalesAgent.vue";
import PolicyTransferUnderwriter from "@/components/policies/PolicyTransferUnderwriter.vue";

import EditPolicyGroupLayer from "@/components/policies/EditPolicyGroupLayer.vue";
import EditGroupPolicySendEmailLayer from "@/components/policies/EditGroupPolicySendEmailLayer.vue";

import groupPolicyFirstLayer from "@/components/policies/groupPolicyFirstLayer.vue";
import groupPolicySecondLayer from "@/components/policies/groupPolicySecondLayer.vue";
import groupPolicyTable from "@/components/policies/groupPolicyTable.vue";
import groupPolicyPagination from "@/components/policies/groupPolicyPagination.vue";
import groupPolicyActionDrpDown from "@/components/policies/groupPolicyActionDrpDown.vue";
import groupPolicyCreditDebitLayer from "@/components/policies/EditGroupPolicyCreditDebitLayer.vue";


/**
 * Inquiry Components
 */
import inquiryTable from '@/components/inquiry/inquiryTable.vue'
import inquiryLastLayer from '@/components/inquiry/inquiryLastLayer.vue'
import inquiryFirstLayer from '@/components/inquiry/inquiryFirstLayer.vue'

/**
 * Notification Components
 */
import notificationTable from '@/components/notification/notificationTable.vue'
import notificationLastLayer from '@/components/notification/notificationLastLayer.vue'
/**
 * General Modal
 */
import InformationModal from '@/components/modals/general/InformationModal.vue'

/**
 * Dashboard Componnents
 */

import DashboardLayer1 from '@/components/dashboard/DashboardLayer1.vue'
import DashboardOverview from '@/components/dashboard/DashboardOverview.vue'
import DashboardSalesOverview from '@/components/dashboard/DashboardSalesOverview.vue'
import DashboardUnderwriter from '@/components/dashboard/DashboardUnderwriter.vue'
import DashboardAccounts from '@/components/dashboard/DashboardAccounts.vue'
import DashboardMarketing from '@/components/dashboard/DashboardMarketing.vue'
import DashboardSalesPersonInfo from '@/components/dashboard/DashboardSalesPersonInfo.vue'
import DashboardStatistics from '@/components/dashboard/DashboardStatistics.vue'
import DashboardOthers from '@/components/dashboard/DashboardOthers.vue'
import PieChart from '@/components/dashboard/PieChart.vue'
import TableStat from '@/components/dashboard/TableStat.vue'

/**
 * Report Components
 */

import agentSalesTable from '@/components/reports/agentSalesTable.vue'
import agentGroupSalesTable from '@/components/reports/agentGroupSalesTable.vue'

import policiesAssignedVsCompletedTable from '@/components/reports/policiesAssignedVsCompletedTable.vue'
import policiesGroupAssignedVsCompletedTable from '@/components/reports/policiesGroupAssignedVsCompletedTable.vue'

import approvedInvoicesTable from '@/components/reports/approvedInvoicesTable.vue'
import approvedGroupInvoicesTable from '@/components/reports/approvedGroupInvoicesTable.vue'


/**
 * Initialize custom component
 * @param app vue instance
 * @created vincent carabbacan
 */

export function initVincentComponents(app: App<Element>) {
  const data = [
    { key: 'vc-amount', component: vcAmount },
    { key: 'vc-amount-v2', component: vcAmountV2 },
    { key: 'vc-socket', component: vcScocket },
    { key: 'vc-phone', component: vcPhone },
    { key: 'vc-dividers', component: vcDividers },
    { key: 'vc-dropdown', component: vcDropDown },
    { key: 'vc-button', component: vcButton },
    { key: 'vc-button-save', component: vcButtonSave },
    { key: 'vc-button-navigator', component: vcButtonNavigator },
    { key: 'vc-table', component: vcTable },
    { key: 'vc-input', component: vcInput },
    { key: 'vc-input-emirate-id', component: vcInputEmirateID },
    { key: 'vc-change-app', component: vcChangeApp },
    { key: 'vc-quick-leads', component: vcQuickLeads },
    { key: 'vc-imports', component: vcImports },
    { key: 'vc-transfer', component: vcTransfer },
    { key: 'vc-insurance-type', component: vcInsuranceType },
    { key: 'vc-payment-type', component: vcPaymentType },
    { key: 'vc-modal-payment-warning', component: vcPaymentWarning },
    { key: 'vc-insurance-payment', component: vcInsurancePayment },
    { key: 'vc-agents', component: vcAgents},
    { key: 'vc-leads', component: vcButtonLeads },
    { key: 'vc-roles', component: vcRoles },
    { key: 'vc-gender', component: vcGender },
    { key: 'vc-underwriters', component: vcUnderwriters },
    { key: 'vc-team-leads', component: vcTeamLeads },
    { key: 'vc-yes-no', component: vcYesNo },
    { key: 'vc-agent-type', component: vcAgentType },
    { key: 'vc-renewal-deal', component: vcRenewalDeals },
    { key: 'vc-assign-lead', component: vcAssignLeads },
    { key: 'vc-assign-customer-status', component: vcCustomerStatus },
    { key: 'vc-assign-language', component: vcAssignLanguage },
    { key: 'vc-transfer-agent', component: vcTransferAgent },
    { key: 'vc-relationships', component: vcRelationships },
    { key: 'vc-relationships-domestic', component: vcRelationshipDomestic },
    { key: 'vc-relationships-parent', component: vcRelationshipParent },
    { key: 'vc-relationships-employee', component: vcRelationshipEmployee },
    { key: 'vc-relationships-others', component: vcRelationshipOthers },
    { key: 'vc-age-group', component: vcAgeGroup },
    { key: 'vc-countries', component: vcCountries },
    { key: 'vc-emirates', component: vcEmirates },
    { key: 'vc-tasklist', component: vcTasklist },
    { key: 'vc-lead-reason', component: vcLeadReason },
    { key: 'vc-plans', component: vcPlans }, 
    { key: 'vc-user-status', component: vcStatus }, 
    { key: 'vc-date-picker', component: vcDatePicker },
    { key: 'vc-date-picker-v2', component: vcDatePickerV2 },
    { key: 'vc-date-picker-time', component: vcDatePickerTime },
    { key: 'vc-date-picker-range', component: vcDateRange },
    { key: 'vc-health', component: vcHealth },
    { key: 'vc-health-top', component: healthTop },
    { key: 'vc-health-middle', component: healthMiddle },
    { key: 'vc-health-myself', component: myself },
    { key: 'vc-health-domestic-worker', component: domesticWorker },
    { key: 'vc-health-family', component: family },
    { key: 'vc-health-employee', component: employee },
    { key: 'vc-health-employee-table', component: employeeTable },
    { key: 'vc-health-declaration', component: declarations },
    { key: 'vc-health-last', component: healthLast },
    { key: 'vc-quote-policy-details', component: quotePolicyDetails },
    { key: 'vc-invoice-information', component: invoiceInformation },
    { key: 'vc-health-corporate-top', component: corporateTop },
    { key: 'vc-health-corporate-middle', component: corporateMiddle },
    { key: 'vc-providers', component: vcProviders },
    { key: 'vc-policies', component: vcPolicies },
    { key: 'vc-customer-first-layer', component: customerFirstLayer },
    { key: 'vc-customer-second-layer', component: customerSecondLayer },
    { key: 'vc-customer-third-layer', component: customerThirdLayer },
    { key: 'vc-customer-fourth-layer', component: customerFourthLayer },
    { key: 'vc-customer-last-layer', component: customerLastLayer },
    { key: 'vc-customer-table', component: customerTable },
    { key: 'vc-customer-table-v2', component: customerTableV2 },
    { key: 'vc-customer-details', component: vcCustomerDetails },
    { key: 'vc-customer-accordion', component: customerAccordion },
    { key: 'vc-customer-accordion-data', component: accordionData },
    { key: 'vc-customer-task-details', component: customerTaskDetails },
    { key: 'vc-customer-tabs', component: customerTabs },
    { key: 'vc-modal-customer-update', component: UpdateCustomer },
    { key: 'vc-modal-view-sponsor', component: ViewSponsor },
    { key: 'vc-customer-view-details', component: ViewDetails },
    { key: 'vc-customer-view-notes', component: ViewNotes },
    { key: 'vc-customer-view-emails', component: ViewEmails },
    { key: 'vc-customer-view-sms', component: ViewSMS },
    { key: 'vc-customer-view-policies', component: ViewPolicies},
    { key: 'vc-customer-view-email-content', component: ViewEmailContent },
    { key: 'vc-customer-view-customer-log', component: ViewCustomerLog },
    { key: 'vc-customer-view-lead-activity', component: ViewLeadActivity },
    { key: 'vc-customer-view-documents', component: ViewDocuments },
    { key: 'vc-customer-view-quotes', component: ViewQuotes },
    { key: 'vc-customer-add-notes', component: AddNotes },
    { key: 'vc-customer-compose-emails', component: ComposeEmails },
    { key: 'vc-customer-view-tasks-drawer', component: ViewTasks },
    { key: 'vc-customer-view-tasks-drawer-close-task', component: CloseTask },
    { key: 'vc-customer-tasks-drawer-data', component: tasks },
    { key: 'vc-customer-tasks-drawer-data-value', component: taskData },
    { key: 'vc-customer-notes-data', component: notes },
    { key: 'vc-customer-notes-data-value', component: notesData },
    { key: 'vc-customer-email-data-value', component: emailsData },
    { key: 'vc-customer-sms-data-value', component: smsData },
    { key: 'vc-customer-customer-log-data-value', component: customerLogData },
    { key: 'vc-customer-task-form', component: taskForm },
    { key: 'vc-customer-merge-lead', component: MergeLead },
    { key: 'vc-employee-categories', component: vcEmployeeCategories },
    { key: 'vc-user-first-layer', component: userFirstLayer },
    { key: 'vc-user-last-layer', component: userLastLayer },
    { key: 'vc-user-table', component: userTable },
    { key: 'vc-user-form', component: userForm },
    { key: 'vc-editor', component: Editor },
    { key: 'vc-document-upload', component: Upload },
    { key: 'vc-document-table-data', component: DocumentData },
    { key: 'vc-lead-declarations', component: ViewDeclarations },
    // Invoice
    { key: 'vc-invoice-first-layer', component: InvoiceFirstLayer },
    { key: 'vc-invoice-second-layer', component: InvoiceSecondLayer },
    { key: 'vc-invoice-table', component: InvoiceTable },
    { key: 'vc-invoice-pagination', component: InvoicePagination },
    { key: 'vc-invoice-modal', component: InvoiceModal },
    { key: 'vc-interested-policy-modal', component: InterestedPolicyModal },
    { key: 'vc-client-classification', component: vcClientClassification },
    { key: 'vc-invoice-approval-action-modal' , component: InvoiceApprovalActionModal},
    { key: 'vc-invoice-approval-action-drpdown' , component: InvoiceApprovalActionDrpDown},
    { key: 'vc-invoice-details' , component: InvoiceDetails},
    { key: 'vc-approved-invoice-first-layer' , component: ApprovedInvoiceFirstLayer},
    { key: 'vc-approved-invoice-second-layer' , component: ApprovedInvoiceSecondLayer},
    { key: 'vc-declined-invoice-first-layer' , component: DeclinedInvoiceFirstLayer},
    { key: 'vc-assigned-policies-first-layer', component: AssignedPoliciesFirstLayer},
    { key: 'vc-assigned-policies-second-layer', component: AssignedPoliciesSecondLayer},
    { key: 'vc-policies-table', component: policyTable},
    { key: 'vc-policies-group-table', component: policyGroupTable},
    { key: 'vc-policies-pagination', component: policyPagination},
    { key: 'vc-assigned-policies-action-drpdown', component: AssignedPoliciesActionDrpDown},
    { key: 'vc-edit-policy-first-layer', component: EditPolicyFirstLayer},
    { key: 'vc-edit-policy-second-layer', component: EditPolicySecondLayer},
    { key: 'vc-edit-policy-credit-debit-layer', component: EditPolicyCreditDebitLayer},
    { key: 'vc-edit-policy-update-documents-layer', component: EditPolicyUpdateDocumentsLayer},
    { key: 'vc-edit-policy-amend-documents-layer', component: EditPolicyAmendDocumentsLayer},
    { key: 'vc-edit-policy-email-activity-layer', component: EditPolicyEmailActivityLayer},
    { key: 'vc-update-amend-documents', component: UpdateAmendDocuments},
    { key: 'vc-edit-policy-send-email-layer' , component: EditPolicySendEmailLayer},
    { key: 'vc-policy-documents', component: PolicyDocuments},
    { key: 'vc-policy-transfer-sales-agent', component: PolicyTransferSalesAgent},
    { key: 'vc-policy-transfer-underwriter', component: PolicyTransferUnderwriter},
    { key: 'vc-sub-amend-invoice-information', component: SubAmendInvoiceInformation},
    { key: 'vc-inquiry-first-layer', component: inquiryFirstLayer },
    { key: 'vc-inquiry-last-layer', component: inquiryLastLayer },
    { key: 'vc-inquiry-table', component: inquiryTable },
    { key: 'vc-update-other-contact-info' , component: UpdateOtherContactInfo},
    { key: 'vc-notification-last-layer', component: notificationLastLayer },
    { key: 'vc-notification-table', component: notificationTable },
    { key: 'vc-group-size', component: vcGroupSize },
    { key: 'vc-group-invoice-information', component: groupInvoiceInformation },
    { key: 'vc-group-invoice-table', component: groupInvoiceTable },
    { key: 'vc-group-invoice-first-layer', component: groupInvoiceFirstLayer },
    { key: 'vc-group-invoice-second-layer', component: groupInvoiceSecondLayer },
    { key: 'vc-group-invoice-pagination', component: groupInvoicePagination },
    { key: 'vc-group-invoice-action-dropdown', component: groupInvoiceActionDrpDown },
    { key: 'vc-group-invoice-details' , component: groupInvoiceDetails},
    { key: 'vc-invoice-has-pending' , component: invoiceHasPending},
    { key: 'vc-edit-policy-group-layer' , component: EditPolicyGroupLayer},
    { key: 'vc-amend-group-table' , component: amendGroupTable},
    { key: 'vc-group-invoice-log' , component: groupInvoiceLog},
    { key: 'vc-group-sub-amend-invoice-information' , component: groupSubAmendInvoiceInformation},
    { key: 'vc-sub-amend-group-table' , component: subAmendGroupTable},
    { key: 'vc-group-policy-first-layer' , component: groupPolicyFirstLayer},
    { key: 'vc-group-policy-second-layer' , component: groupPolicySecondLayer},
    { key: 'vc-group-policy-table' , component: groupPolicyTable},
    { key: 'vc-group-policy-pagination' , component: groupPolicyPagination},
    { key: 'vc-group-policy-action-dropdown' , component: groupPolicyActionDrpDown},
    { key: 'vc-group-policy-credit-debit-layer' , component: groupPolicyCreditDebitLayer},
    { key: 'vc-report-filter' , component: vcReportFilters},
    { key: 'vc-report-export-reset' , component: vcExportReset},
    // general modal
    { key: 'vc-information-modal' , component: InformationModal},
    { key: 'vc-sub-group-amend-table' , component: subGroupAmendTable},
    { key: 'vc-edit-group-policy-send-email-layer' , component: EditGroupPolicySendEmailLayer},

    // Dashboard components
    { key: 'vc-dashboard-layer-1' , component: DashboardLayer1},
    { key: 'vc-dashboard-overview' , component: DashboardOverview},
    { key: 'vc-dashboard-sales-person-info' , component: DashboardSalesPersonInfo},
    { key: 'vc-dashboard-sales-overview' , component: DashboardSalesOverview},
    { key: 'vc-dashboard-underwriter-overview' , component: DashboardUnderwriter},
    { key: 'vc-dashboard-accounts-overview' , component: DashboardAccounts},
    { key: 'vc-dashboard-marketing-overview' , component: DashboardMarketing},
    { key: 'vc-dashboard-statistics' , component: DashboardStatistics},
    { key: 'vc-dashboard-others' , component: DashboardOthers},
    { key: 'vc-pie-chart' , component: PieChart},
    { key: 'vc-table-stats' , component: TableStat},
    
    // Report Components
    { key: 'vc-agent-sales-table' , component: agentSalesTable},
    { key: 'vc-agent-group-sales-table' , component: agentGroupSalesTable},
    { key: 'vc-policies-assigned-vs-completed-report-table' , component: policiesAssignedVsCompletedTable},
    { key: 'vc-policies-group-assigned-vs-completed-report-table' , component: policiesGroupAssignedVsCompletedTable},
    { key: 'vc-approved-invoices-table' , component: approvedInvoicesTable},
    { key: 'vc-approved-group-invoices-table' , component: approvedGroupInvoicesTable},

    
    
  ];

  data.forEach(element => {
    app.component(element.key, element.component);
  })

  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  };
}
