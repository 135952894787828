import { store } from "@/store";
import { App } from "vue";
import { PolicyActionTypes } from '@/store/enums/Policy/Actions';
import { PolicyMutationTypes } from '@/store/enums/Policy/Mutations';
import { ElLoading } from 'element-plus'

class Policy {
    
    public static vueInstance: App;

    public static init(app: App<Element>) {
        Policy.vueInstance = app;
    }

    public static setFilter(payload) {
        store.commit(PolicyMutationTypes.SET_POLICY_FILTER, payload);
    }

    public static async policyLists(payload) {
        const loading = ElLoading.service({
            target: '.policies-list',
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.1)',
        })
        const data = await store.dispatch(PolicyActionTypes.POLICY_LISTS, payload);
        if(loading)
            loading.close()
        return data
    }

    public static async getPolicy(payload) {
        return await store.dispatch(PolicyActionTypes.GET_POLICY, payload);
    }

    public static async updatePolicy(payload) {
        return await store.dispatch(PolicyActionTypes.UPDATE_POLICY, payload);
    }

    public static async submitPolicy(payload) {
        return await store.dispatch(PolicyActionTypes.SUBMIT_POLICY, payload);
    }

    public static async getEditPolicySetting() {
        return await store.dispatch(PolicyActionTypes.GET_EDIT_POLICY_SETTING);
    }

    public static async transferSale(payload) {
        return await store.dispatch(PolicyActionTypes.TRANSFER_SALE, payload);
    }

    public static async changeUnderwriter(payload) {
        return await store.dispatch(PolicyActionTypes.CHANGE_UNDERWRITER, payload);
    }

    public static async getPurchasedPolicies(payload) {
        return await store.dispatch(PolicyActionTypes.GET_PURCHASED_POLICIES, payload);
    }

    public static async getPoliciesLog(payload) {
        return await store.dispatch(PolicyActionTypes.GET_POLICY_LOG, payload);
    }

    public static async sendEmailToCustomer(payload) {
        return await store.dispatch(PolicyActionTypes.SEND_CUSTOMER_EMAIL, payload);
    }

    public static async sendEmailToBroker(payload) {
        return await store.dispatch(PolicyActionTypes.SEND_BROKER_EMAIL, payload);
    }

    public static async getChildPolicies(payload) {
        return await store.dispatch(PolicyActionTypes.GET_CHILD_POLICIES, payload);
    }

    public static async getEmailActivity(payload) {
        return await store.dispatch(PolicyActionTypes.GET_EMAIL_ACTIVITY, payload);
    }

    public static async groupPolicyLists(payload) {
        const loading = ElLoading.service({
            target: '.policies-list',
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.1)',
        })
        const data = await store.dispatch(PolicyActionTypes.GROUP_POLICY_LISTS, payload);
        if(loading)
            loading.close()
        return data
    }

    // group
    public static async getGroupPolicy(payload) {
        return await store.dispatch(PolicyActionTypes.GROUP_GET_POLICY, payload);
    }

    
    public static async updateGroupPolicy(payload) {
        return await store.dispatch(PolicyActionTypes.GROUP_UPDATE_POLICY, payload);
    }
    
    public static async submitGroupPolicy(payload) {
        return await store.dispatch(PolicyActionTypes.GROUP_SUBMIT_POLICY, payload)
    }

    public static async uploadTradeLicense(payload) {
        return await store.dispatch(PolicyActionTypes.GROUP_UPLOAD_TRADE_LICENSE, payload)
    }

    public static async getGroupChildPolicies(payload) {
        return await store.dispatch(PolicyActionTypes.GROUP_GET_CHILD_POLICIES, payload);
    }

    public static async sendGroupEmailToBroker(payload) {
        return await store.dispatch(PolicyActionTypes.SEND_GROUP_BROKER_EMAIL, payload);
    }

    public static async getGroupEmailActivity(payload) {
        return await store.dispatch(PolicyActionTypes.GET_GROUP_EMAIL_ACTIVITY, payload);
    }


    public static async groupTransferSale(payload) {
        return await store.dispatch(PolicyActionTypes.GROUP_TRANSFER_SALE, payload);
    }

    public static async groupChangeUnderwriter(payload) {
        return await store.dispatch(PolicyActionTypes.GROUP_CHANGE_UNDERWRITER, payload);
    }

    public static async groupLeadPolicyDetails(payload) {
        return await store.dispatch(PolicyActionTypes.GROUP_LEAD_POLICY_DETAILS, payload);
    }

    public static async removeScanData(payload) {
        return await store.dispatch(PolicyActionTypes.REMOVE_SCAN_DATA, payload);
    }

}

export default Policy;