import { ActionTree, ActionContext } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';
import { State } from './state';
import { Mutations } from './mutations';
import { DocumentMutationTypes } from '@/store/enums/Document/Mutations';
import { DocumentActionTypes } from '@/store/enums/Document/Actions';
import ApiService from "@/core/services/ApiService";
import { Links } from '@/store/enums/Links';
import router from '@/router/clean';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [DocumentActionTypes.DOCUMENT_LISTS](
    { commit }: AugmentedActionContext,
    payload: any
    ): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [DocumentActionTypes.DOCUMENT_LISTS] ({ commit }, payload) {
    if(payload.is_loaded) commit(DocumentMutationTypes.SET_DOCUMENT_LIST_LOADING, true);

    try {
      const data = await ApiService.post(Links.GET_DOCUMENT_LISTS, payload)
      commit(DocumentMutationTypes.SET_DOCUMENT_LISTS, data.data.data.documents)
      commit(DocumentMutationTypes.SET_DOCUMENT_LIST_LOADING, false);
      return data.data.data.documents
    } catch (err)  {
      commit(DocumentMutationTypes.SET_DOCUMENT_LIST_LOADING, false);
      return err
    }
  },

  async [DocumentActionTypes.DOCUMENT_ID_LOGS] ({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.GET_DOCUMENT_LOGS, payload)
      commit(DocumentMutationTypes.SET_DOCUMENT_LOGS, data.data.data.logs)
      return data
    } catch (err) {
      return err
    }
  },

  async [DocumentActionTypes.DOCUMENT_TYPES] ({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.GET_DOCUMENT_TYPES, payload)
      commit(DocumentMutationTypes.SET_DOCUMENT_TYPES, data.data.data.types)
      return data
    } catch (err) {
      return err
    }
  },

  async [DocumentActionTypes.GROUP_DOCUMENT_TYPES] ({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.GROUP_GET_DOCUMENT_TYPE, payload)
      commit(DocumentMutationTypes.SET_DOCUMENT_TYPES, data.data.data.types)
      return data
    } catch (err) {
      return err
    }
  },

  async [DocumentActionTypes.DOCUMENT_UPDATE_TYPE] ({commit}, payload) {
    try {
      const data = await ApiService.post(Links.POST_UPDATE_DOCUMENT, payload)
      commit(DocumentMutationTypes.SET_DOCUMENT_VALUE, data.data.data.document)
      return data
    } catch (err) {
      return err
    }
  },

  async [DocumentActionTypes.DOCUMENT_DELETE_TYPE] ({}, payload) {
    try {
      const data = await ApiService.post(Links.POST_DELETE_DOCUMENT, payload)
      return data
    } catch (err) {
      return err
    }
  },

  async [DocumentActionTypes.DOWNLOAD_ZIP_DOCUMENTS] ({}, payload) {
    try {
      const data = await ApiService.post(Links.DOWNLOAD_ZIP_DOCUMENTS, payload)
      // console.log(data.data.data)
      if(data.data.data.file) {
        window.open(
          data.data.data.file,
          '_blank' 
        );
      }
      return data
    } catch (err) {
      return err
    }
  },

  async [DocumentActionTypes.AMEND_DOCUMENT_LISTS] ({ commit }, payload) {

    try {
      const data = await ApiService.post(Links.LIST_AMEND_DOCUMENTS, payload)
      commit(DocumentMutationTypes.SET_AMEND_DOCUMENT_LISTS, data.data.data.documents)
    } catch (err)  {
      return err
    }
  },

  async [DocumentActionTypes.UPDATE_AMEND_DOCUMENTS] ({}, payload) {
    try {
      const data = await ApiService.post(Links.UPDATE_AMEND_DOCUMENTS, payload)
      
      return data
    } catch (err) {
      return err
    }
  },

};

