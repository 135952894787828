import { GetterTree } from 'vuex';
import { common,constant } from '@/store/stateless'
import moment from 'moment';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';
import { store } from "@/store";


import { State } from './state';

export type Getters = {
    getLeadCount(state: State): any | null;
}

export const getters: GetterTree<State, RootState> & Getters = {
    getLeadCount: (state) => state.customerListCounts,
    getOnlyGroup: (state) => {
        const { leads } = state.detailsCustomer

        const withGroup = leads.filter(x => x.insured_type_id == 4)
        const withoutGroup = leads.filter(x => x.insured_type_id != 4)
        let onlyGroup = false
        if(withGroup.length > 0 && withoutGroup.length == 0) onlyGroup = true

        return onlyGroup
    },
    getLeadsDetails: (state) => (view: boolean) => {
        // console.log(state.detailsCustomer)
        const { leads, customer, customer_id, agent, additional } = state.detailsCustomerLead
        // console.log('leads',leads);
        if(!leads)
            return {};
        const data = leads
        // .sort((a, b) => {
        //     return toDate(a['latest_task'] ? a['latest_task']['due_date_format'] : '-') - toDate(b['latest_task'] ? b['latest_task']['due_date_format'] : '-')
        // });
        return data.map((x: any) => {
            const name = x.insurance_type === 1
                ? `${x.insured_type_text ? x.insured_type_text : 'QL'} | ${x.insured_type_id  == 0 ? 'QL' : 'B'} | ${x.lead_information.member_name}${!view ? ' | ' + x.lead_information.relationship : ''}`
                : `${x.insured_type_text} | ${x.insurance_type === 2 ? 'E' :( x.insurance_type === 3 ? 'B2' : 'NA')} | ${customer.name}${(!view && x.insured_type_id != 4) ? ' | Sponsor' : ''}`

            const empCategories = x.insured_type_id == 4 ? employee_categories(x.employee_categories) : {categories:[]};

            let show_make_invoice =  x.invoices.length > 0 || x.group_invoices.length > 0 || x.insurance_type == 0 || (x.insured_type_id == 4 && empCategories?.categories.length <= 1) ? false : true

            if(x.latest_policy_purchase && !x.latest_policy_purchase.is_active && x.latest_policy_purchase?.status == 4)
                show_make_invoice = true

            if(x.renewal_make_invoice){
                // if lead came for renewal
                show_make_invoice = true;
            }
            return {
                show_closed_task: x.show_closed_task,
                lead_status: x.status_text,
                lead_status_id: x.status,
                old_status: x.old_status,
                followup_count: x.followup_count,
                insured_type_id: x.insured_type_id,
                insured_city: x.insured_city,
                lead_id: x.id,
                name,
                customer_id,
                agent,
                encrypted_map: x.encrypted_map ?? null,
                policy_expiry: x.latest_policy_purchase && x.latest_policy_purchase.is_active == 1 && x.latest_policy_purchase.policy_end_date_format ? x.latest_policy_purchase?.policy_end_date_format : '-',
                task_due: ![4,6,8,16].includes(x.status) ? (x.latest_task ? x.latest_task.due_date_format : '-') : '-',
                highlight: x.latest_task ? x.latest_task.is_highlight_task : false,
                insured_person: x.insured_type_id == 4  ? [] : insured_type_values(x),
                employee_categories: empCategories,
                lead: lead_values(x),
                customer_detail: customer_detail_values(x, customer, additional),
                interested_policy: x.interested_policy_name ?? '',
                tab_disabled: false,
                show_make_invoice: ![14, 16].includes(x.status) ? show_make_invoice : false,
                // show_edit_lead_details: [6,7,8].includes(x.status) || x.invoices.length > 0 ? false : true,
                // show_edit_lead_details: [6,8,14, 16].includes(x.status)  ? false : true,
                show_edit_lead_details: [14, 16].includes(x.status)  ? false : true,
                expected_premium: x.health_lead_map?.expected_premium ?? null,
                is_investor: x.health_lead_map?.is_investor == 1 ? true : false,
                declaration_issue: declaration_issue(x),
                latest_policy_purchase: latest_policy_purchase(x),
                check_pending_invoice: x.has_pending_invoice,
                check_pending_invoice_main: x.has_pending_invoice_main,
                upload_docs_pending: x.upload_docs_pending,
                disable_task_button: x.has_pending_invoice
            }
        }).sort((a, b) => {
            return toDate(a.task_due) - toDate(b.task_due)
        })[0]
    },

    getLeads: (state) => (view: boolean) => {
        // console.log(state.detailsCustomer)
        const { leads, customer, customer_id, agent, additional } = state.detailsCustomer
        // console.log('leads',leads);
        const data = leads.sort((a, b) => {
            return toDate(a['latest_task'] ? a['latest_task']['due_date_format'] : '-') - toDate(b['latest_task'] ? b['latest_task']['due_date_format'] : '-')
        });
        return data.map((x: any) => {
            const name = x.insurance_type === 1
                ? `${x.insured_type_text ? x.insured_type_text : 'QL'} | ${x.insured_type_id  == 0 ? 'QL' : 'B'} | ${x.lead_information.member_name}${!view ? ' | ' + x.lead_information.relationship : ''}`
                : `${x.insured_type_text} | ${x.insurance_type === 2 ? 'E' :( x.insurance_type === 3 ? 'B2' : 'NA')} | ${customer.name}${(!view && x.insured_type_id != 4) ? ' | Sponsor' : ''}`

            // const empCategories = x.insured_type_id == 4 ? employee_categories(x.employee_categories) : {categories:[]};

            // let show_make_invoice =  x.invoices.length > 0 || x.group_invoices.length > 0 || x.insurance_type == 0 || (x.insured_type_id == 4 && empCategories?.categories.length <= 1) ? false : true

            // if(x.latest_policy_purchase && !x.latest_policy_purchase.is_active && x.latest_policy_purchase?.status == 4)
            //     show_make_invoice = true

            // if(x.renewal_make_invoice){
            //     // if lead came for renewal
            //     show_make_invoice = true;
            // }

            return {
                // show_closed_task: x.show_closed_task,
                lead_status: x.status_text,
                lead_status_id: x.status,
                old_status: x.old_status,
                // followup_count: x.followup_count,
                insured_type_id: x.insured_type_id,
                insured_city: x.insured_city,
                lead_id: x.id,
                name,
                customer_id,
                agent,
                // encrypted_map: x.encrypted_map ?? null,
                policy_expiry: x.latest_policy_purchase && x.latest_policy_purchase.is_active == 1 && x.latest_policy_purchase.policy_end_date_format ? x.latest_policy_purchase?.policy_end_date_format : '-',
                task_due: ![4,6,8,16].includes(x.status) ? (x.latest_task ? x.latest_task.due_date_format : '-') : '-',
                highlight: x.latest_task ? x.latest_task.is_highlight_task : false,
                // insured_person: x.insured_type_id == 4  ? [] : insured_type_values(x),
                // employee_categories: empCategories,
                lead: lead_values(x),
                // customer_detail: customer_detail_values(x, customer, additional),
                // interested_policy: x.interested_policy_name ?? '',
                // tab_disabled: false,
                // show_make_invoice: ![14, 16].includes(x.status) ? show_make_invoice : false,
                // show_edit_lead_details: [6,7,8].includes(x.status) || x.invoices.length > 0 ? false : true,
                // show_edit_lead_details: [6,8,14, 16].includes(x.status)  ? false : true,
                // show_edit_lead_details: [14, 16].includes(x.status)  ? false : true,
                // expected_premium: x.health_lead_map?.expected_premium ?? null,
                // is_investor: x.health_lead_map?.is_investor == 1 ? true : false,
                // declaration_issue: declaration_issue(x),
                // latest_policy_purchase: latest_policy_purchase(x),
                // check_pending_invoice: x.has_pending_invoice,
                // upload_docs_pending: x.upload_docs_pending
            }
        }).sort((a, b) => {
            return toDate(a.task_due) - toDate(b.task_due)
        })
    },

    getLeadInformation: (state) => (view: boolean, lead_id) => {
        // console.log(lead_id);
        const { leads } = state.detailsCustomerLead
        const matchLead = leads.filter((x: any) => {
            return x.id === lead_id;
        })

        if (matchLead[0]) {
            return matchLead[0].lead_informations.map((x: any) => {

                return {
                    id: x.id,
                    member_name: x.member_name,
                    relationship: x.relationship,
                    gender: x.gender,
                    dob: x.dob,
                    nationality_id: x.nationality_id,
                    is_salary: x.is_salary,
                    declaration_answers: x.declaration_answers,
                    is_married: x.is_married,
                    tab_disabled: false,
                }
            });
        }
    },

    getLeadCounts: (state) => {
        if (state.detailsCustomer) {
            return state.detailsCustomer.leads.length
        }
        return 0
    },

    getCustomer: (state) => {
        if (state.detailsCustomer && Object.keys(state.detailsCustomer).length > 0 && state.detailsCustomer.customer) {
            const { customer, agent_details, customer_status_id, customer_status_text, open_task_count, hot_enquiry, additional, is_show_transfer, is_renewal, leads } = state.detailsCustomer
            // console.log('phone_number_without_code',customer.phone_number_without_code)

            return {
                customer_id: customer.id,
                name: customer.name,
                email: customer.email,
                phone_country_code: customer.phone_country_code,
                phone_number: customer.phone_number_without_code,
                complete_number: customer.phone_number,
                other_contact_info: customer.other_contact_info ?? '',
                customer_status_id,
                customer_status_text,
                agent_name: agent_details ? `SA: ${agent_details.name}` : 'Not Assigned',
                open_task_count,
                hot_enquiry: hot_enquiry === 1 ? true : false,
                dob: additional?.dob,
                gender: additional?.gender,
                is_married: additional?.is_married == 1 ? true : false,
                nationality_id: additional?.nationality_id,
                is_salary: additional?.is_salary == 1 ? true : false,
                is_show_transfer : is_show_transfer ?? false,
                is_renewal: is_renewal === 1 ? true : false,
                has_group_leads: (leads && leads.filter(x => x.insured_type_id == 4).length > 0) ? true : false
            }
        }

        return {}
    },

    getTaskDetails: (state) => {
        if (Object.keys(state.task_details).length > 0) {
            const { tasks } = state.task_details
            if (tasks.length > 0) {
                return tasks.map(x => {
                    return {
                        current_data: `${x.agent_details ? x.agent_details.name + ' | ' : ''}${x.disposition_text} | <span class="text-blue">${x.status_text}</span>`,
                        lost_lead_reason: x.lost_lead_reason_text,
                        show_lost_lead_reason: x.disposition_id === 8 ? true : false,
                        show_close_task: x.status === 1 ? true : false,
                        lead_status: x.lead_status_text,
                        task_note: x.task_notes,
                        class_text: x.class_text,
                        due_at: x.due_date_format,
                        by: `${x.created_by ? x.created_by.name + ' at ' : ''}${x.created_at}`,
                        closed_at: x.closed_at_format,
                        customer_id: x.customer_id,
                        agent: x.agent,
                        lead_id: x.lead_id,
                        lead_status_id: x.lead_status_id,
                        disable_task_button: x.has_pending_invoice
                    }
                })
            } else {
                return []
            }
        }
        return {}
    },

    getLeadActions: () => (lead) => {
        // console.log('status',lead.upload_docs_pending)
        const show_invoice = (lead.show_make_invoice && lead.insured_type_id != 0 && !lead.upload_docs_pending)  ? true : false;
        const show_generate_quote = (lead.insured_type_id != 0 && ![4,9,10,2].includes(lead.lead_status_id))  ? true : false;
        const group_health = lead.insured_type_id == 4 ? true : false; 
        const closedLead = [14,16].includes(lead.lead_status_id)  ? true : false;
        return [
            {
                label: 'View/Edit Lead Details',
                icon: 'icon-svg icon-edit',
                show: lead.show_edit_lead_details && !closedLead,
                condition: 1,
                route: 1,
                click: 1
            },
            {
                label: 'Generate Quote PDF',
                icon: 'far fa-file-pdf',
                show:  show_generate_quote && !group_health && !closedLead,
                condition: 1,
                route: 1,
                click: 2
            },
            {
                label: 'Upload Documents',
                icon: 'icon-svg icon-upload',
                show:  show_generate_quote && !group_health && !closedLead,
                condition: 1,
                route: 1,
                click: 3
            },
            {
                label: 'View Declarations',
                icon: 'icon-svg icon-declaration',
                show: lead.insured_type_id != 0 && !group_health,
                condition: 1,
                route: 1,
                click: 6,
                hasIssue: lead.declaration_issue
            },
            {
                label: 'Compare Quotes',
                icon: 'icon-svg icon-compare-quotes',
                show: ![0,6].includes(lead.insured_type_id) && !group_health && !closedLead,
                condition: 1,
                route: 1,
                click: 4
            },
            {
                label: 'Make Invoice',
                icon: 'fas fa-coins',
                show: show_invoice && !closedLead,
                condition: 1,
                route: 1,
                click: 5
            },
        ]
    },

    getLeadsAndTasks: (state) => {
        if (Object.keys(state.customerLists).length > 0) {
            const { data } = state.customerLists
            if (data && data.length > 0) {
                return data.map(x => {
                    return {
                        name: x.customer.name,
                        email_mobile: `${x.customer.email} <br />${x.customer.phone_number}`,
                        email: x.customer.email,
                        phone_number: x.customer.phone_number,
                        phone_number_without_code: x.customer.phone_number_without_code,
                        customer_status_text: x.customer_status_text,
                        customer_status_id: x.customer_status_id,
                        is_renewal: x.is_renewal === 0 ? '-' : 'Renewal',
                        hot_enquiry: x.hot_enquiry === 0 ? false : true,
                        open_task_count: x.open_task_count,
                        task_due_date: x.task_due_date,
                        sales_agent: x.agent ? x.agent.name : 'Not Assigned',
                        customer_id: x.customer_id,
                        current_visited_date_format: x.current_visited_date_format,
                        last_visited_date_format: x.last_visited_date_format,
                        created_at: x.created_at,
                        is_show_transfer: x.is_show_transfer ?? false
                    }
                })
            } else {
                return []
            }
        }
    },

    getTabDisabled: (state) => {
        const { leads } = state.detailsCustomer

        if(leads.length < 2) {
            return leads.find((x: any) => [2, 9, 10].includes(x.status)) ? true : false
        }
        return false
    },

    getEmailLists: (state) => {
        if (state.emails.length > 0) {
            return state.emails.map(x => {
                return {
                    subject: x.subject,
                    sent_by: x.sent_by_skye_user ? x.sent_by_skye_user.name : '-',
                    created_at: x.created_at,
                    delivered: x.delivered === 1 ? true : false,
                    clicked: x.clicked === 1 ? true : false,
                    email_body: x.email_body
                }
            })
        }
    },

    getSMSLists: (state) => {
        if (state.sms.length > 0) {
            return state.sms.map(x => {
                return {
                    sms: x.sms,
                    created_at: x.created_at,
                    status: x.status == 1 ? 'Delivered' : '-'
                }
            })
        }
    },

    getCustomerLogActivity: (state) => {
        if (state.customer_activity.length > 0) {
            return state.customer_activity.map(x => {
                return {
                    title: x.activity_desc,
                    current_data: x.activity_description ? x.activity_description.new : '',
                    previous_data: x.activity_description ? x.activity_description.old : '',
                    by: `${x.action_by ? x.action_by.name : ''} ${x.created_at}`
                }
            })
        }
    },

    getCustomerLogStatus: (state) => {
        if (state.customer_status.length > 0) {
            return state.customer_status.map(x => {
                return {
                    current_status: x.new_status_text,
                    previous_status: x.old_status_text === 0 ? '' : x.old_status_text,
                    by: `${x.action_by ? x.action_by.name : ''} ${x.created_at ? x.created_at : ''}`
                }
            })
        }
    },



    getLeadLogActivity: (state) => {
        if (state.lead_activity_log.length > 0) {
            return state.lead_activity_log.map(x => {
                return {
                    title: x.activity_desc,
                    current_data: x.activity_description ? x.activity_description.new : '',
                    previous_data: x.activity_description ? x.activity_description.old : '',
                    by: `${x.action_by ? x.action_by.name : ''} ${x.created_at}`
                }
            })
        }
    },

    getLeadLogStatus: (state) => {
        if (state.lead_status_log.length > 0) {
            return state.lead_status_log.map(x => {
                return {
                    current_status: x.new_status_text,
                    previous_status: x.old_status_text === 0 ? '' : x.old_status_text,
                    by: `${x.action_by ? x.action_by.name : ''} ${x.created_at ? x.created_at : ''}`
                }
            })
        }
    },

    getCustomerLogViewActivity: (state) => {
        if (state.customer_view_activity.length > 0) {
            return state.customer_view_activity.map(x => {
                return {
                    description: x.activity_desc,
                    by: `${x.action_by ? x.action_by.name : ''} ${x.created_at}`
                }
            })
        }
    },

    getCustomerLogs: (state) => {
        return {
            activity_log: activity_log(state.customer_activity),
            status_log: status_log(state.customer_status),
            view_activity_log: view_activity_log(state.customer_view_activity)
        }
    },

    getLeadActivityLogs: (state) => {
        return {
            activity_log: activity_log(state.lead_activity_log),
            status_log: status_log(state.lead_status_log)
        }
    },

    getCustomerLeadDetails: (state) => (lead) => {
        // console.log('insured_city',lead.employee_categories.categories)
        const details = [
            {
                title: `Lead ID:`,
                showTitle: true,
                subTitle: '',
                showSubTitle: true,
                inTable: true,
                interestedIn: false,
                key: 'lead',
                items: [
                    {
                        label: 'Insured For',
                        value: 'insured_for',
                        cn: '',
                        cnh: 'ps-3'
                    },
                    {
                        label: 'Lead Status',
                        value: 'lead_status',
                        cn: 'fw-bolder fw-6 badge',
                        cnh: '',
                        isMixin: true
                    },
                    {
                        label: 'Task Status',
                        value: 'task_status',
                        cn: '',
                        cnh: 'ps-3'
                    },
                    {
                        label: 'Due At',
                        value: 'due_at',
                        cn: '',
                        cnh: 'ps-3',
                    },
                    {
                        label: 'Request Timeline',
                        value: 'request_time_line',
                        cn: '',
                        cnh: 'ps-3',
                        isTimeLine: true
                    },
                    {
                        label: 'Lead Source',
                        value: 'lead_source',
                        cn: '',
                        cnh: 'ps-3',
                    },
                ]
            },
            {
                title:  lead.insured_type_id == 4 ? 'Company/Customer Details' : 'Sponsor/Customer Details',
                showTitle: true,
                subTitle: '',
                showSubTitle: false,
                inTable: false,
                interestedIn: false,
                key: 'customer_detail',
                items: [
                    {
                        label: 'Name:',
                        value: 'name',
                        cnd: lead.insured_type_id == 4 ? 'customer-first-column' : 'customer-first-column',
                    },
                    {
                        label: 'Date of Birth:',
                        value: 'dob',
                        notShow: lead.insured_type_id == 4 ? true : false,
                        cnd: 'customer-second-column'
                    },
                    {
                        label: (lead.insured_city == 0 || lead.insured_city == 2) ? 'Salary/Month > AED 4000:' : 'Salary/Month > AED 15000',
                        value: 'salary',
                        notShow: lead.insured_type_id == 4 ? true : false,
                        cnd: 'customer-third-column'

                    },
                    {
                        label: 'Gender:',
                        value: 'gender',
                        notShow: lead.insured_type_id == 4 ? true : false,
                        cnd: 'customer-first-column'

                    },
                    {
                        label: 'Nationality:',
                        value: 'nationality',
                        notShow: lead.insured_type_id == 4 ? true : false,
                        cnd: 'customer-second-column'

                    },
                    {
                        label: 'Visa Registration Emirates:',
                        value: 'emirates',
                        notShow: lead.insured_type_id == 4 ? true : false,
                        cnd: 'customer-third-column'
                    },
                    {
                        label: 'Contact Person:',
                        value: 'contact_person',
                        notShow: lead.insured_type_id == 4 ? false : true,
                        cnd: 'customer-second-column'
                    },
                    
                    {
                        label: 'Expected Policy Start Date:',
                        value: 'expected_policy_start_date',
                        cnd: 'customer-third-column'
                    },
                    {
                        label: 'Group size:',
                        value: 'group_size',
                        notShow: lead.insured_type_id == 4 ? false : true,
                        cnd: 'customer-first-column'
                    },
                ]
            },
            {
                title: 'Details of Insured Person/s',
                showTitle: true,
                subTitle: '',
                showSubTitle: false,
                inTable: false,
                interestedIn: true,
                key: 'insured_person',
                notShow: lead.insured_type_id == 4 ? true : false,
                items: [
                    {
                        label: 'Relationship:',
                        value: 'relationship',
                        cnd: 'customer-first-column'
                    },
                    {
                        label: 'Date of Birth:',
                        value: 'dob',
                        cnd: 'customer-second-column'
                    },
                    {
                        label: (lead.insured_city == 0 || lead.insured_city == 2) ? 'Salary/Month > AED 4000:' : 'Salary/Month > AED 15000',
                        value: 'salary',
                        notShow: lead.insured_type_id == 1 || lead.insured_type_id == 2 ? false : true,
                        cnd: 'customer-third-column'

                    },
                    {
                        label: 'Gender:',
                        value: 'gender',
                        cnd: lead.insured_type_id == 1 || lead.insured_type_id == 2 ? 'customer-first-column' : 'customer-third-column'
                    },
                    {
                        label: 'Nationality:',
                        value: 'nationality',
                        cnd: lead.insured_type_id == 1 || lead.insured_type_id == 2 ? 'customer-second-column' : 'customer-first-column'
                    },
                    
                    {
                        label: 'Is Married?:',
                        value: 'is_married',
                        cnd: lead.insured_type_id == 1 || lead.insured_type_id == 2 ? 'customer-third-column' : 'customer-second-column'
                    },
                    
                    {
                        label: 'Visa Registration Emirates:',
                        value: 'emirates',
                        notShow: lead.insured_type_id == 1 || lead.insured_type_id == 2 ? false : true,
                        cnd: 'customer-first-column'

                    },
                    {
                        label: 'Expected Policy Start Date:',
                        value: 'expected_policy_start_date',
                        notShow: lead.insured_type_id == 1 ? false : true,
                        cnd: 'customer-second-column'
                    },
                ]
            },

            {
                title: 'Details of Insured Person/s',
                showTitle: true,
                subTitle: '',
                showSubTitle: false,
                inTable: false,
                interestedIn: true,
                key: 'employee_categories',
                notShow: lead.insured_type_id == 4 && lead.employee_categories.categories.length > 0 ? false : true,
                items: [
                    {
                        label: 'Category',
                        value: 'name',
                        cn: '',
                        cnh: 'ps-3',
                        cnd: 'customer-first-column'
                    },
                    {
                        label: 'Visa Emirate',
                        value: 'visa',
                        cn: '',
                        cnh: 'ps-3',
                        cnd: 'customer-first-column'
                    },
                    {
                        label: 'Insurance Plan',
                        value: 'insurance_plan',
                        cn: '',
                        cnh: 'ps-3',
                        cnd: 'customer-third-column'
                    },

                    {
                        label: 'Members Count',
                        value: 'group_count',
                        cn: '',
                        cnh: 'ps-3',
                        cnd: 'customer-first-column'
                    },

                    {
                        label: 'Members > 60',
                        value: 'members_above_60',
                        cn: '',
                        cnh: 'ps-3',
                        cnd: 'customer-first-column'
                    },

                    {
                        label: '',
                        value: 'documents',
                        cn: '',
                        cnh: 'ps-3',
                        cnd: 'customer-first-column',
                        isViewDocument: true
                    },

                ]
            },
            {
                title: 'Policy Details',
                showTitle: true,
                subTitle: '',
                showSubTitle: true,
                inTable: false,
                key: 'latest_policy_purchase',
                notShow: false,
                isPolicy: true,
                interestedIn: false,

                items: [
                    {
                        label: 'Policy Expiry Date:',
                        value: 'policy_end_date_format',
                        cnd: 'customer-first-column'
                    },
                    {
                        label: 'Quote No.:',
                        value: 'merchant_reference',
                        cnd: 'customer-second-column'
                    },
                    {
                        label: 'Policy Name:',
                        value: 'policy_name',
                        cnd: 'customer-third-column'
                    },

                    {
                        label: 'Policy Type:',
                        value: 'type',
                        cnd: 'customer-first-column'
                    },
                    {
                        label: 'Policy Premium:',
                        value: 'premium',
                        cnd: 'customer-second-column'
                    },
                    {
                        label: 'Policy Number:',
                        value: 'policy_no',
                        cnd: 'customer-third-column'
                    },
                    {
                        label: 'Transaction Type:',
                        value: 'transaction_type',
                        cnd: 'customer-first-column'
                    },
                    {
                        label: 'Policy SA:',
                        value: 'agent',
                        cnd: 'customer-second-column'
                    },
                    {
                        label: 'Underwriter:',
                        value: 'underwriter',
                        cnd: 'customer-third-column'
                    },
                    {
                        label: 'Policy Issued On:',
                        value: 'issued_on',
                        cnd: 'customer-first-column',
                        isViewDocument: lead.insured_type_id == 4 ? false : true,
                    },
                    // {
                    //     label: 'Status:',
                    //     value: 'status'
                    // },
                ]
            },
        ]

        return details
    },

    getGroupInvoiceCategories: (state) => {
        if(state.health.categories.length > 0) {
            const x = state.health.categories;
            return x.map((y:any) => {
                // console.log('category', y);
                const basmah =  y.visa_emirates == 2 ? store.state.etc.basmah : 0;
                return {
                    id: y.id,
                    name: y.name,
                    insurance_provider_id: y.insurance_provider_id,
                    insurance_plan_id: y.insurance_plan_id,
                    group_count: y.group_count,
                    members_above_60: y.members_above_60,
                    counts: `${y.group_count} (${y.members_above_60})`,
                    visa_emirates: y.visa_emirates,
                    category_name: y.category_name,
                    visa_emirates_text: y.visa_emirates_text,
                    insurance_plan: y.insurance_plan,
                    amount: y.amount,
                    amount_vat: 0,
                    basmah: common.roundUp(basmah * (y.group_count ? y.group_count : 0)),
                    // invoice_based_categories: y.invoice_based_categories ? y.invoice_based_categories : [],
                    icp:0,
                    non_taxable_fee:0,
                    icp_disabled:constant.dubai_emirate != y.visa_emirates ? false : true,
                }
            })
        }

        return [];
    },



};

function latest_policy_purchase(lead) {

    // console.log('lead.latest_policy_purchase' , lead.latest_policy_purchase)
    let myLead = lead.latest_policy_purchase
    if(lead.insured_type_id == 4) myLead = lead.latest_group_policy_purchase

    if (myLead) {
        const purchase = myLead;
        const group_health = lead.insured_type_id == 4 ? true : false; 
        const policy_name = lead.employee_categories.map(x => x.policy.policy_name)
        // const policy_no = myLead.main_invoice.categories.map(x => x.policy_number)
        let groupPol = <any>[]
        if(myLead && myLead.main_invoice && myLead.main_invoice.categories) {
            groupPol = myLead.main_invoice.categories.map(x => x.policy_number)
            if(groupPol.every(element => element === null)) {
                groupPol = '-'
            } 
        }
        
        return {
            show:true,
            policy_id : purchase.id,
            status: purchase.status_text,
            is_active: purchase.is_active,
            policy_end_date_format: purchase && purchase.policy_end_date_format ? purchase.policy_end_date_format : '-',
            merchant_reference: purchase.main_invoice?.merchant_reference,
            policy_name: !group_health ? purchase.policy?.policy_name : policy_name,
            type : (!group_health && purchase?.policy.insurance_type == 1) ? 'Basic' : 'Enhanced',
            premium : common.formatCurrency(purchase.main_invoice?.sales_price),
            policy_no: !group_health ? purchase.policy_number : groupPol,
            transaction_type: purchase.transaction_type_text,
            agent: purchase.policy_sales_agent?.name,
            underwriter: purchase.policy_underwriter?.name ?? 'NA',
            issued_on: purchase.created_at,

        }
    }

    return {
        show:false
    }

}

function customer_detail_values(lead, customer, additional) {
    return {
        name: customer.name,
        dob: additional?.dob_format,
        nationality: additional?.nationality,
        group_size: lead.health_lead_map?.group_size_text,
        contact_person: lead.health_lead_map?.contact_person,
        gender: common.makeFirstCharUpperCase(additional?.gender),
        salary: lead.health_lead_map?.is_salary == 1 ? 'Yes' : 'No' ,
        emirates: lead.insured_city_text,
        expected_policy_start_date: lead.expected_insurance_start_date_format,
        insurance_type: lead.insurance_type,
        show: lead.insured_type_id !== 0 && lead.insured_type_id !== 1 && lead.status !== 2
    }
}

function insured_type_values(lead) {
    return {
        dob: lead.lead_information.dob_format,
        nationality: lead.lead_information.nationality,
        gender: common.makeFirstCharUpperCase(lead.lead_information.gender),
        salary: lead.lead_information.is_salary === 1 ? 'Yes' : 'No',
        // is_married: lead.lead_information.gender == 'Male' ? 'NA' : lead.lead_information.is_married === 1 ? 'Yes' : 'No',
        emirates: lead.insured_city_text,
        expected_policy_start_date: lead.expected_insurance_start_date_format,
        insurance_type: lead.insurance_type,
        members: member_values(lead.lead_informations, lead.insured_city_text,lead),
        show: lead.insured_type_id !== 0 && lead.status !== 2,
    }
}

function employee_categories(categories) {

    const last = {
        isTotal:true,
        name:'Total',
        visa:'',
        insurance_plan:'',
        group_count:0,
        members_above_60:0,
        documents: []
    }
    const items = categories.map((x) => {
        last.group_count += Number(x.group_count)
        last.members_above_60 += Number(x.members_above_60)
        return {
            name: x.category_name,
            visa: x.visa_emirates_text,
            group_count: x.group_count,
            members_above_60: x.members_above_60,
            documents: x.documents,
            lead_employee_category_id: x.id,
            // insurance_provider: x.insurance_provider.provider_name,
            // insurance_plan: `${x.insurance_provider.provider_name} - ${ x.policy.policy_name}`,
            insurance_plan: x.insurance_plan,
        }
    })
    items.push(last)
    return {
        show: true,
        categories: items
    }
}

function lead_values(lead) {
    return {
        lead_id: lead.id,
        agent: lead.agent,
        customer_id: lead.customer_id,
        lead_status_id: lead.status,
        insured_for: lead.insured_type_text ? lead.insured_type_text : 'QL',
        lead_status: lead.status_text,
        health_lead_map_id: lead.health_lead_map_id,
        insurance_type: lead.insurance_type,
        task_status: lead.latest_task ? lead.latest_task.disposition_text : '-',
        due_at: lead.latest_task ? lead.latest_task.due_date_format : '-',
        request_time_line: lead.created_at_format,
        time_lines: timelines(lead),
        lead_source: common.makeFirstCharUpperCase(lead?.health_lead_map?.lead_source),
        duplicate_source: lead?.health_lead_map?.duplicate_source,
        // lead_source: common.makeFirstCharUpperCase(lead.lead_source),
        main_invoice_approved_at: lead.main_invoice ? lead.main_invoice.invoice_approved_at : null,
        renewal_date: lead.renewals ? lead.renewals.due_date_format : null,
        llr_date: lead.llr_date,
        show: true
    }
}

function member_values(members, insured_city_text ,lead) {
    return members.map((x) => {
        // console.log(x.gender)
        return {
            name: x.member_name,
            dob: x.dob_format,
            nationality: x.nationality,
            gender: common.makeFirstCharUpperCase(x.gender),
            salary: x.is_salary === 1 ? 'Yes' : 'No',
            is_married: x.gender.toLowerCase() == 'male' ? null :( x.is_married === 1 ? 'Yes' : 'No'),
            insurance_type: x.insured_type_id,
            emirates: x.insured_city_text,
            relationship: x.relationship,
            is_temp: x.is_temp,
            expected_policy_start_date: lead.expected_insurance_start_date_format,

        }
    })
}

function timelines(lead) {
    return {
        current_visited_date: lead.current_visited_date_format,
        last_visited_date: lead.previous_visited_date_format,
        created_at: lead.created_at_format,
    }
}

function activity_log(logs) {
    return logs.map(x => {
        return {
            title: x.activity_desc,
            current_data: x.activity_description ? x.activity_description.new : '',
            previous_data: x.activity_description ? x.activity_description.old : '',
            by: `${x.action_by ? x.action_by.name : ''} ${x.created_at}`
        }
    })
}

function status_log(logs) {
    return logs.map(x => {
        return {
            current_status: x.activity_description ? x.activity_description.new : x.new_status_text ? x.new_status_text : '',
            previous_status: x.activity_description ? x.activity_description.old : x.old_status_text !== 0 ? x.old_status_text : '',
            by: `${x.action_by ? x.action_by.name : ''} ${x.created_at}`
        }
    })
}

function view_activity_log(logs) {
    return logs.map(x => {
        return {
            description: x.activity_desc,
            by: `${x.action_by ? x.action_by.name : ''} ${x.created_at}`
        }
    })
}

function toDate(value: any) {
    let data = value
    const today = new Date()
    if (value === '-')
        data = `01/01/${today.getFullYear() + 5} 00:00`

    return moment(data, 'DD/MM/YYYY HH:mm').valueOf()
}


function declaration_issue(lead) {
    if(lead && lead.lead_informations && lead.lead_informations.length > 0 ) {
        const info = lead.lead_informations.filter((x) => {
            if(x.declaration_answers && x.declaration_answers.length > 0){
                const count = x.declaration_answers.filter( y => y.answer == 'YES');
                if(count.length > 0)
                    return true;
                
                return false;
            }
        });
        return info.length > 0 ? true : false;
    }

    return false;
}