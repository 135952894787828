export enum Links {
    /**
     * @description Fetching data
     * @type GET Request
     */
    VERIFY_TOKEN = '/skye/check-token',
    GET_NOTIFICATIONS = '/skye/get-global-notification',
    GET_ALL_ROLES = '/skye/user-roles',
    GET_ROUTES = '/skye/master/front-routes',
    GET_COUNTRY_CODE = '/skye/master/country-codes',
    GET_AGENT_LISTS = '/skye/health-dom-agents',
    GET_ALL_AGENT_LISTS = '/skye/health-all-agents',
    GET_UNDERWRITERS_LISTS = '/skye/health-underwriters',
    GET_COUNTRIES = '/health/countries',
    GET_RELATIONSHIPS = '/health/relationships',
    GET_EMIRATES = '/health/emirates',
    GET_BASMAH = '/health/get-basmah',
    GET_VAT = '/health/get-vat',
    GET_PLANS = '/health/plans',
    GET_APPS = '/health/applications',
    GET_INSURED_TYPES = '/health/insured-types',
    GET_LEAD_SOURCES = '/skye/master/lead-sources',
    GET_CUSTOMER_ALREADY_EXIST = '/skye/check-number-exist',
    // GET_CUSTOMER_ALREADY_EXIST = '/skye/health/customer/check-number-exist',
    GET_INSURANCE_PROVIDERS = '/health/providers',
    // GET_INSURANCE_PLANS = '/health/policies',
    GET_INSURANCE_PLANS = '/skye/health/master/insurance-plans',
    GET_LEAD_DETAILS_BY_MAP_ID = '/skye/health/customer/get-customer-details',
    GET_LEAD_DETAILS_BY_LEAD_ID = '/skye/health/customer/get-customer-details-by-lead-id',
    GET_CUSTOMER_DETAILS_BY_ID = '/skye/health/customer/get-customer-details-by-id',
    GET_EMPLOYEE_CATEGORIES = '/skye/health/employee-categories',
    GET_ALL_LEAD_TASKS = '/skye/health/task/task-lists',
    GET_ALL_LEAD_REASONS = '/skye/health/task/lead-reasons',
    GET_ALL_USERS = '/skye/users-list',
    GET_USERS_BY_APP_AND_ROLE = '/skye/user-lists',
    GET_USER_BY_ID = '/skye/user/show',
    GET_ALL_EMAIL_LEAD = '/skye/health/email/lists',
    GET_ALL_SMS_LEAD = '/skye/health/sms/lists',
    GET_ALL_NOTE_LEAD = '/skye/health/lead/notes',
    GET_ALL_CUSTOMER_ACTIVITY_LOGS = '/skye/health/customer/list-activity-logs',
    GET_ALL_CUSTOMER_STATUS_LOGS = '/skye/health/customer/list-status-logs',
    GET_ALL_LEAD_ACTIVITY_LOGS = '/skye/health/lead/list-activity-logs',
    GET_ALL_LEAD_STATUS_LOGS = '/skye/health/lead/list-status-logs',
    // documents
    GET_DOCUMENT_LISTS = '/skye/health/documents/list-document',
    GET_DOCUMENT_TYPES = '/skye/health/documents/get-document-types',
    GET_DOCUMENT_LOGS = '/skye/health/documents/list-document-logs',
    LIST_AMEND_DOCUMENTS = '/skye/health/documents/list-amend-document',
    UPDATE_AMEND_DOCUMENTS = '/skye/health/documents/update-amend-document',

    GET_DECLARATIONS = '/skye/health/lead/get-declaration-answers',
    GET_QUOTES = '/skye/health/quote/get-quotes',
    GET_GENERATE_QUOTES = '/skye/health/quote/get-generate-quotes',
    GENERATE_QUOTES_PDF = '/skye/health/quote/generate-quotes-pdf',
    GET_VIEW_QUOTES = '/skye/health/quote/get-view-quotes',
    SEND_MANUAL_QUOTE = '/skye/health/quote/send-manual-quote',
    /**
     * @description Send data to api
     * @type POST, PUT, DELETE Request
    */
    POST_LOGIN = '/skye/login',
    POST_LOGOUT = '/skye/logout',
    POST_SWITCH_ROLE = '/skye/change-role',
    POST_SWITCH_APP = '/skye/switch-app',
    POST_SWITCH_APP_RELOAD = '/skye/switch-reload',
    GET_NEW_AUTH_CHECK = '/skye/token-check',

    /**
     * @description Customer
    */

    POST_VIEW_ALL_EMAIL_LEAD_SAVE = '/skye/health/email/save',
    POST_ADD_NOTE_LEAD = '/skye/health/lead/notes/create',
    POST_VIEW_ALL_TASKS_BY_LEAD_ID = '/skye/health/task',
    POST_QUICK_LEAD = '/skye/health/lead/add-quick-lead',
    POST_CUSTOMER_LIST = '/skye/health/customer/leads-and-tasks',
    POST_LEAD_FILTER_COUNT = '/skye/health/customer/leads-filtered-task-due-date-count',
    POST_UPDATE_CUSTOMER_DETAILS = '/skye/health/customer/update-customer-details',
    POST_UPDATE_CUSTOMER_CONTACT_INFO = '/skye/health/customer/update-customer-other-contact-info',
    POST_IMPORT_CUSTOMER_LIST = '/skye/health/customer/list-import',
    POST_TRANSFER_AGENT = '/skye/health/lead/transfer',
    POST_LIST_USER_TYPES = '/skye/master/list-user-types',
    POST_CUSTOMER_LIST_COUNT = '/skye/health/lead/list-count',
    POST_CAR_TASK_DUE_DATE_COUNT = '/skye/health/task/open-due-count',
    POST_HEALTH = '/skye/health/lead/health-lead',
    POST_HEALTH_UPDATE = '/skye/health/lead/health-update-lead',
    POST_HEALTH_MERGE_QUICK_LEAD = '/skye/health/lead/merge-quick-lead',
    POST_HEALTH_DECLARATION = '/skye/health/lead/update-declaration-answers',
    POST_DETAIL_CUSTOMER = '/skye/health/customer',
    POST_DETAIL_CUSTOMER_LEAD = '/skye/health/customer/health-customer-details',
    POST_CUSTOMER_LEAD_DELETE = '/skye/health/lead/health-delete-lead',
    POST_ADD_LEAD_TASK = '/skye/health/task/add-lead-task',
    POST_CREATE_USER = '/skye/user-create',
    POST_UPDATE_USER = '/skye/user/update',
    GET_MEMBERS_BY_MAP = '/skye/health/lead/members-using-map-id',
    // documents
    POST_DELETE_DOCUMENT = '/skye/health/documents/delete-document',
    POST_UPDATE_DOCUMENT = '/skye/health/documents/update-document',

    // Invoice
    MAKE_INVOICE = '/skye/health/invoice/make-invoice',
    MAKE_INVOICE_EVENT = '/skye/make-invoice-event',
    ADD_AMEND_INVOICE = '/skye/health/invoice/add-amend-invoice',
    SUB_AMEND_INVOICE = '/skye/health/invoice/sub-amend-invoice',
    UPDATE_INVOICE = '/skye/health/invoice/update-invoice',
    GET_INVOICE = '/skye/health/invoice/get-invoice',
    POST_APPROVAL_INVOICE_LIST = '/skye/health/invoice/list-approval-invoice',
    POST_APPROVAL_INVOICE_LIST_COUNT = '/skye/health/invoice/list-approval-invoice-count',
    SEND_PAYMENT_LINK_EMAIL = '/skye/health/email/send-invoice-payment-link',
    SEND_INVOICE_GENERATION_ISSUE_EMAIL  = '/skye/health/email/send-invoice-generation-issue',
    INTERESTED_POLICY = '/skye/health/lead/interested-policy',
    UPLOAD_INVOICE_DOCUMENT = '/skye/health/invoice/add-invoice-document',
    GET_INVOICE_DETAILS = '/skye/health/invoice/get-receipt-details',
    
    MAKE_GROUP_INVOICE = '/skye/health/invoice/make-group-invoice',
    GROUP_INVOICE_LIST = '/skye/health/invoice/list-group-invoice',
    GROUP_INVOICE_LIST_COUNT = '/skye/health/invoice/list-group-invoice-count',
    UPDATE_GROUP_INVOICE = '/skye/health/invoice/update-group-invoice',
    UPLOAD_GROUP_INVOICE_DOCUMENT = '/skye/health/invoice/add-group-invoice-document',
    GET_GROUP_INVOICE = '/skye/health/invoice/get-group-invoice',
    ADD_GROUP_AMEND_INVOICE = '/skye/health/invoice/add-group-amend-invoice',
    SUB_GROUP_AMEND_INVOICE = '/skye/health/invoice/sub-group-amend-invoice',
    GET_GROUP_INVOICE_LOG = '/skye/health/invoice/get-group-invoice-log',
    VALIDATE_DUPLICATES = '/skye/health/invoice/validate-duplicates',
    GROUP_CATEGORY_ADDITION_INVOICE = '/skye/health/invoice/group-category-addition-invoice',
    GET_GROUP_INVOICE_DETAILS = '/skye/health/invoice/get-group-receipt-details',
    // Policy
    POST_POLICY_LIST = '/skye/health/policy/list-policy',
    POST_POLICY_LIST_COUNT = '/skye/health/policy/list-policy-count',
    GET_POLICY = '/skye/health/policy/get-policy',
    UPDATE_POLICY = '/skye/health/policy/update-policy',
    SUBMIT_POLICY = '/skye/health/policy/submit-policy',
    TRANSFER_SALE = '/skye/health/policy/transfer-sale',
    CHANGE_UNDERWRITER = '/skye/health/policy/transfer-underwriter',
    GET_PURCHASED_POLICIES = '/skye/health/policy/get-purchased-policies',
    GET_POLICY_LOG = '/skye/health/policy/get-policies-log',
    POLICY_SEND_CUSTOMER_EMAIL =  '/skye/health/policy/send-customer-email',
    POLICY_SEND_BROKER_EMAIL =  '/skye/health/policy/send-broker-email',
    DOWNLOAD_ZIP_DOCUMENTS = '/skye/health/policy/generate-zip',
    GET_CHILD_POLICIES = '/skye/health/policy/get-child-policies',
    GET_EMAIL_ACTIVITY = '/skye/health/policy/list-email-activiy',
    REMOVE_SCAN_DATA = '/skye/health/policy/remove-scan-data',

     // Policy
     GROUP_POST_POLICY_LIST = '/skye/health/policy/group/list-policy',
     GROUP_POST_POLICY_LIST_COUNT = '/skye/health/policy/group/list-policy-count',
     GROUP_GET_POLICY = '/skye/health/policy/group/get-policy',
     GROUP_UPDATE_POLICY = '/skye/health/policy/group/update-policy',
     GROUP_UPLOAD_TRADE_LICENSE = '/skye/health/policy/group/upload-trade-license',
     GROUP_SUBMIT_POLICY = '/skye/health/policy/group/submit-policy',
     GROUP_TRANSFER_SALE = '/skye/health/policy/group/transfer-sale',
     GROUP_CHANGE_UNDERWRITER = '/skye/health/policy/group/transfer-underwriter',
     LEAD_POLICY_DETAILS = '/skye/health/policy/group/get-policies-based-on-lead-customer-id',
     GROUP_GET_PURCHASED_POLICIES = '/skye/health/policy/group/get-purchased-policies',
     GROUP_GET_POLICY_LOG = '/skye/health/policy/group/get-policies-log',
     GROUP_POLICY_SEND_CUSTOMER_EMAIL =  '/skye/health/policy/group/send-customer-email',
     GROUP_POLICY_SEND_BROKER_EMAIL =  '/skye/health/policy/group/send-broker-email',
     GROUP_DOWNLOAD_ZIP_DOCUMENTS = '/skye/health/policy/group/generate-zip',
     GROUP_GET_CHILD_POLICIES = '/skye/health/policy/group/get-child-policies',
     GROUP_GET_EMAIL_ACTIVITY = '/skye/health/policy/group/list-email-activiy',
     GROUP_GET_DOCUMENT_TYPE = '/skye/health/policy/group/documents/get-document-types',

    // setting
    GET_EDIT_POLICY_SETTING = '/skye/settings/health-edit-policy-settings',

    GLOBAL_SEARCH = '/skye/health/global-search',

    GET_ALL_INQUIRY = '/skye/health/inquiry-list',

    // Notifications
    GET_RECENT_NOTIFICATIONS = '/skye/notifications/list-recent-notifications',
    UPDATE_NOTIFICATIONS_CLICK = '/skye/notifications/update-click-notification',
    READ_NOTIFICATION = '/skye/notifications/update-notification',
    GET_NOTIFICATION_COUNT = '/skye/notifications/count-unread-notification',
    GET_ALL_NOTIFICATIONS = '/skye/notifications/list-notifications',


    // Corporate
    GET_GROUP_SIZE = '/health/corporate/member-count-list',

    GET_HEALTH_SETTING = '/skye/health/master/settings/',
    
    // Dashboard
    GET_DASHBOARD_OVERVIEW = '/skye/health/dashboard/get-',
    GET_DASHBOARD_SALESPERSON_INFO = '/skye/health/dashboard/analytics/get-sales-person-info',
    GET_DASHBOARD_STATISTICS = '/skye/health/dashboard/analytics/get-sales-statistics',
    GET_DASHBOARD_OTHERS = '/skye/health/dashboard/analytics/get-sales-others',
    GET_DASHBOARD_LEADS = '/skye/health/dashboard/leads-',
    GET_DASHBOARD_TRANSACTIONS = '/skye/health/dashboard/list-transactions-',
    GET_DASHBOARD_GROUP_TRANSACTIONS =  '/skye/health/dashboard/list-group-transactions-',
    GET_DASHBOARD_UNDERWRITER =  '/skye/health/dashboard/policy-creator-stats',
    GET_DASHBOARD_ACCOUNTS =  '/skye/health/dashboard/accounts-dashboard',
    GET_DASHBOARD_MARKETING =  '/skye/health/dashboard/marketing-dashboard',
    GET_SALES_DASHBOARD = '/skye/health/summary/sales-achievements',

    // Reports
    GET_REPORT_DASHBOARD_DETAILS = '/skye/health/report/dashboard-details',
    GET_REPORT_DELETED_LEADS = '/skye/health/report/deleted-leads',
    GET_AGENT_SALES_REPORT = '/skye/health/report/agent-sales',
    GET_POLICIES_ASSIGNED_VS_COMPLETED_REPORT = '/skye/health/report/policies-assigned-vs-completed',
    GET_ADMIN_REPORT = '/skye/health/report/dashboard-reports',
    GET_APPROVED_INVOICES_REPORT = '/skye/health/report/approved-invoice',
    GET_CLOSED_LEADS_REPORT = '/skye/health/report/closed-leads',
    GET_CONVERTED_LEADS_REPORT = '/skye/health/report/converted-leads',
    GET_LEAD_BY_SOURCE_REPORT = '/skye/health/report/lead-by-source',
    GENERATE_PRODUCTION_REPORT = '/skye/health/report/generate-production-report',
    GET_PRODUCTION_REPORT =  '/skye/health/report/get-production-report',
    GET_ACCOUNT_MASTER_REPORT = '/skye/health/report/account-master',
    
    // Globals
    GET_POLICY_STATUSES = '/skye/health/master/policy-statuses',
    GET_REPORT_LEAD_BY_SOURCE_FILTER = '/skye/health/report/master/lead-by-source-filters',
    GET_REPORT_LEAD_SOURCE_FILTER = '/skye/health/report/master/lead-source-filter',

    // MARKETING CAMPAIGN
    GET_MARKETING_CAMPAIGN_FILTERS = '/skye/health/marketing-campaigns/spend/filters',
    GET_MARKETING_CAMPAIGN_AMOUNT_SPEND = '/skye/health/marketing-campaigns/spend/tracking',
    UPDATE_MARKETING_CAMPAIGN_AMOUNT_SPEND = '/skye/health/marketing-campaigns/spend/addUpdate',
    GET_MARKETING_REPORT = '/skye/health/report/campaign-report',

    AUTO_SUGGEST_QUOTE = '/skye/health/master/auto-suggest-quote',
}
