
import { defineComponent, computed, onMounted, ref } from "vue";
import { group_invoice_columns } from "@/core/utils/invoice.js";
import { store } from "@/store";
import { UserTypesMixin } from "@/core/plugins/vcMixins";
import { copyText, common } from "@/store/stateless";
import moment from "moment";
import Invoice from "@/core/services/Callable/Invoice";

export default defineComponent({
  props: {
    showAction: {
      type: Boolean,
      required: false,
      default: true,
    },
    isShowCategories: {
      type: Boolean,
      required: false,
      default: false,
    },
    allInvoices: {
      type: String,
      required: false,
      default: 'all_invoices'
    },
    declined: {
      type: Boolean,
      required: false,
      default: false,
    },
    approved: {
      type: Boolean,
      required: false,
      default: false,
    },
    allowUpdateInvoice:{
      type: Boolean,
      required: false,
      default: false,
    },
    hideChild: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  setup(_) {
    const invoiceStatus = [
      { id: 1, name: "Pending" },
      { id: 2, name: "Approve" },
      { id: 3, name: "Decline" },
    ];
    const invoiceOnlineStatus = [
      { id: 1, name: "Pending" },
      // { id: 2, name: "Approve" },
      { id: 3, name: "Decline" },
    ];

    const tableColumns = computed(() => {
      let columns = group_invoice_columns;
      if(!_.declined) {
        columns = columns.filter(x => x.value != 'declined_by');
      }

      if(!_.approved) {
        columns =  columns.filter(x => x.value != 'approved_by');
      }

      return columns;

    });


    const filter = computed(() => {
      return store.state.invoice.filter;
    });

    const loading = computed(() => {
      return store.state.invoice.loading;
    });

    const invoiceData = computed(() => {
      return store.getters.getGroupInvoiceList
    })

    const permissions = computed(() => {
      return store.getters.getUserPermissions;
    });

    const actionData = computed(() => {
      return store.state.invoice.invoiceApprovalActionModal;
    });


    onMounted(() => {
      Invoice.setGroupInvoice(null)
    })

    const formatDate = (date) => {
      return moment(date, "YYYY-MM-DD HH:ss").format("DD/MM/YYYY HH:ss");
    };

    const apporvePolicy = (item, event, type) => {
      const data = {
        show: true,
        invoice_id: item.id,
        status: event.target.value,
        item: item,
      };

      Invoice.setInvoiceApprovalActionModal(data);
    };

    const invoiceActioned = ref(false)
    const invoiceActionedMessage = ref('')


    const confirmStatusAction = async () => {
      actionData.value.loading = true;
      const payload = {
        invoice_id: actionData.value.invoice_id,
        status: actionData.value.status,
      };
      
      const result = await Invoice.updateGroupInvoice(payload);
      // console.log(result)
      if(result && !result.data.data.success){
        invoiceActioned.value = true;
        invoiceActionedMessage.value = result.data.message
      }
      else{
        await Invoice.groupInvoiceLists(filter.value);
      }
      closeModal(false);

    };

    const invoiceActionedContinue = async () => {
      invoiceActioned.value = false
      invoiceActionedMessage.value = ''
      await Invoice.groupInvoiceLists(filter.value);
    }

    const closeModal = (update = true) => {
      console.log(actionData.value);
      if(update)
        actionData.value.item.status = 1
      const data = {
        show: false,
        loading: false,
        invoice_id: 0,
        status: 0,
        item: null,
      };

      Invoice.setInvoiceApprovalActionModal(data);
    };

    return {
      invoiceData,
      common,
      loading,
      copyText,
      group_invoice_columns,
      formatDate,
      UserTypesMixin,
      invoiceStatus,
      invoiceOnlineStatus,
      // invoiceDescripancyStatus,
      filter,
      apporvePolicy,
      confirmStatusAction,
      closeModal,
      permissions,
      invoiceActioned,
      invoiceActionedMessage,
      invoiceActionedContinue,
      tableColumns
    };
  },
});
