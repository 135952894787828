import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 0,
  class: "d-flex"
}
const _hoisted_4 = { key: 3 }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_amount_v2 = _resolveComponent("vc-amount-v2")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_table, {
      data: _ctx.invoice.categories,
      style: {"width":"100%"}
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.group_columns, (group, i) => {
          return (_openBlock(), _createBlock(_component_el_table_column, {
            key: i,
            width: group.width,
            "header-align": "top"
          }, {
            header: _withCtx(() => [
              _createElementVNode("span", {
                class: "d-block fs-6 fw-bolder",
                innerHTML: group.label
              }, null, 8, _hoisted_2)
            ]),
            default: _withCtx((scope) => [
              (group.value == 'amount')
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_el_form_item, {
                      prop: `categories.` + scope.$index +`.amount`,
                      rules: { validator: _ctx.Validations.amount, trigger: ['blur', 'change','input'] }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_vc_amount_v2, {
                          "with-prop": false,
                          type: 'number',
                          ref: `categories.` + scope.$index + `.amount`,
                          placeholder: "amount",
                          min: "1",
                          size: "large",
                          style: {"width":"100%"},
                          modelValue: scope.row['amount'],
                          "onUpdate:modelValue": ($event: any) => (scope.row['amount'] = $event),
                          onPress: ($event: any) => (_ctx.updatePolicyPrice(scope.row))
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "onPress"])
                      ]),
                      _: 2
                    }, 1032, ["prop", "rules"]),
                    _createVNode(_component_vc_amount_v2, {
                      "with-prop": false,
                      disabled: "",
                      label: 'VAT',
                      modelValue: scope.row['amount_vat'],
                      "onUpdate:modelValue": ($event: any) => (scope.row['amount_vat'] = $event),
                      style: {"width":"33%"},
                      placeholder: "VAT",
                      size: "large",
                      isRequired: ""
                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                  ]))
                : (group.value == 'icp')
                  ? (_openBlock(), _createBlock(_component_el_form_item, {
                      key: 1,
                      prop: `categories.` + scope.$index +`.icp`,
                      rules: { validator: _ctx.Validations.icp, trigger: ['blur', 'change','input'] }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_vc_amount_v2, {
                          "with-prop": false,
                          type: 'number',
                          ref: `categories.` + scope.$index + `.icp`,
                          placeholder: "ICP",
                          min: "0",
                          size: "large",
                          style: {"width":"100%"},
                          modelValue: scope.row['icp'],
                          "onUpdate:modelValue": ($event: any) => (scope.row['icp'] = $event),
                          disabled: scope.row['icp_disabled'] || _ctx.allDisabled,
                          onPress: _ctx.updateIcp
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "onPress"])
                      ]),
                      _: 2
                    }, 1032, ["prop", "rules"]))
                  : (group.value == 'non_taxable_fee')
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 2,
                        prop: `categories.` + scope.$index +`.non_taxable_fee`,
                        rules: { validator: _ctx.Validations.non_taxable_fee, trigger: ['blur', 'change','input'] }
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_vc_amount_v2, {
                            "with-prop": false,
                            type: 'number',
                            ref: `categories.` + scope.$index + `.non_taxable_fee`,
                            placeholder: "ICP",
                            min: "0",
                            size: "large",
                            style: {"width":"100%"},
                            modelValue: scope.row['non_taxable_fee'],
                            "onUpdate:modelValue": ($event: any) => (scope.row['non_taxable_fee'] = $event),
                            disabled: _ctx.allDisabled,
                            onPress: _ctx.updateNonTaxableFee
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "onPress"])
                        ]),
                        _: 2
                      }, 1032, ["prop", "rules"]))
                    : (group.isBasmah)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.common.formatCurrency(scope.row[group.value])), 1))
                      : (_openBlock(), _createElementBlock("span", {
                          key: 4,
                          innerHTML: scope.row[group.value]
                        }, null, 8, _hoisted_5))
            ]),
            _: 2
          }, 1032, ["width"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["data"])
  ]))
}