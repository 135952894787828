
import { defineComponent, ref } from 'vue'

export default defineComponent({
    props: {
        customer_id: {
            type: Number,
            required: true
        },
        agent: {
            type: Number,
            required: true
        },
        lead_id: {
            type: Number,
            required: true
        },
        lead_status_id: {
            type: Number,
            required: true
        },
        disableButton: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    setup() {
        const task_form = ref()

        function resetTask() {
            task_form.value.reset(0)
        }

        return {
            task_form, resetTask
        }
    }
   
})
