
import { defineComponent, computed, h, getCurrentInstance } from 'vue'
import { ElDivider } from 'element-plus';
import { store } from '@/store';
import { constant, rule } from '@/store/stateless';
import Policy from '@/core/services/Callable/Policy';
import Invoice from '@/core/services/Callable/Invoice';
import moment from 'moment';
import Validations from '@/core/services/etc/Validations';

export default defineComponent({
    // name: 'policy-edit',

    props: {
        isGroup: {
            type: Boolean,
            required: false,
            default: false
        },
        isEnableAllFields: {
            type: Boolean,
            required: false,
            default: false
        },
        isDisableAllFields: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    setup(_, {emit}) {

        const policy = computed(() => {
            return store.state.policy.policy
        })

        const previous_start_days = computed(() => {
            return store.state.policy.previous_start_days
        })

        const upcoming_start_days = computed(() => {
            return store.state.policy.upcoming_start_days
        })

        const minStartDate = computed(() => {
            const today = new Date()
            const currentDate = new Date()
            currentDate.setDate(currentDate.getDate() - previous_start_days.value)
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth() + 1;
            const day = currentDate.getDate();
            return year + '-' + month + '-' + day;

            // return editPolicyObj.value.status !== 4 ? moment(currentDate).format('YYYY-MM-DD') : moment(today).format('YYYY-MM-DD')
        })

        const maxStartDate = computed(() => {
            const today = new Date()
            const currentDate = new Date()
            currentDate.setDate(currentDate.getDate() + upcoming_start_days.value)
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth() + 1;
            const day = currentDate.getDate();
            return year + '-' + month + '-' + day;

            // return editPolicyObj.value.status !== 4 ? moment(currentDate).format('YYYY-MM-DD') : moment(today).format('YYYY-MM-DD')
        })

        function addMonths(date, months) {
            var d = date.getDate();
            date.setMonth(date.getMonth() + +months);
            if (date.getDate() != d) {
                date.setDate(0);
            }
            date.setDate(date.getDate() - 1);

            return date;
        }
        const updatePolicyStartDate = () => {
            var currentDate = new Date(
                addMonths(
                    new Date(policy.value.policy_start_date),
                    12
                ).toString()
            );

            const year = currentDate.getFullYear();
            const month = currentDate.getMonth() + 1;
            const day = currentDate.getDate();
            policy.value.policy_end_date = year + '-' + month + '-' + day;
        }

        const entities = [
            {
                value: 1,
                label: 'Corporate'
            },
            {
                value: 2,
                label: 'Individual'
            }
        ];

        const inst = getCurrentInstance()
        const focus = () => {
            console.log('iqwn focus')

            const toFocus: any = inst?.refs[rule.customerDetails]

            console.log(toFocus)
            if (toFocus !== undefined) {
            console.log('in focus')
                toFocus.focus()
                // window.scrollTo(0, common.getY('top'))
            }
        }

        const uploadFile = (event) => {
            const payload = new FormData()
            payload.append("customer_policy_purchse_id", policy.value.policy_purchase_id)
            payload.append("file", event.target.files[0])

            policy.value.trade_license = event.target.files[0]

            Policy.uploadTradeLicense(payload)
            
        };

        const invoiceLogModal = async () => {
          await Invoice.getGroupInvoiceLog({
            invoice_id: policy.value?.main_invoice?.id,
            lead_id: policy.value.lead_id
          });
      }

      function setPassport() {
        emit('reValidateFields', 'passport')
      }


        return {
            setPassport,
            invoiceLogModal,
            uploadFile,
            policy,
            minStartDate,
            maxStartDate,
            entities,
            updatePolicyStartDate,
            constant,
            focus
        }
    },
})
