const tDocuments = [
    {
        label: 'Sr. #',
        value: 'index',
        width: '80'
    },
    {
        label: 'Preview',
        value: 'link',
        isPreview: true,
        width: '120'
    },
    {
        label: 'Document Type',
        value: 'document_type',
        width: '350'
    },
    {
        label: 'File Name',
        value: 'file_name'
    },
    // {
    //     label: 'Quotes Ref. No.',
    //     value: 'quotes_ref_no',
    //     width: '150'
    // },
    {
        label: 'Uploaded By',
        value: 'uploaded_by'
    },
    {
        label: 'Action',
        value: 'action',
        width: '250',
        align: 'center'
    },
]

const logs = [
    {
        label: 'Sr. #',
        value: 'index'
    },
    {
        label: 'Action',
        value: 'action'
    },
    {
        label: 'By',
        value: 'by'
    },
    {
        label: 'At',
        value: 'at'
    },
]


export {
    tDocuments,
    logs
}