import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, defineComponent, toRef, watchEffect, ref, watch, nextTick } from "vue";
import moment from "moment";
import { Calendar } from '@element-plus/icons-vue'


export default _defineComponent({
  props: {
    modelValue: {
      type: String,
      required: true,
      default: "",
    },
    min: {
        type: String,
        required: false,
        default: "1900-01-01",
    },
    max: {
        type: String,
        required: false,
        default: "",
    },
    defaultCalendar: {
        type: String,
        required: false,
        default: moment().format('YYYY-MM-DD')
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
},
  emits: ['update:modelValue', 'change'],
  setup(__props, { expose, emit }) {

const props = __props
/* eslint-disable */




 
    const language = computed(() => {
      return window.localStorage.getItem("language");
    });

    const firstDate = ref("");

    watch(firstDate, (value) => {
      const result = value
        .replace(/^(\d\d)(\d)$/g, "$1/$2")
        .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
        .replace(/[^\d/]/g, "");
      nextTick(() => (firstDate.value = result));
      
    });

    
    watchEffect(() => {
      if(props.modelValue) {
        firstDate.value = moment(props.modelValue, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        );
      } else {
        firstDate.value = ''
      }
    })

    const updateValue = () => {
      if (firstDate.value.toString().length == 10) {
        let myDate = moment(firstDate.value.toString(), "DD/MM/YYYY").format("YYYY-MM-DD");
        emit("update:modelValue", myDate);
      }
    };

    const inputRef = ref()
    const focus = () => {
      setTimeout(() => {
        inputRef.value.focus()
      }, 250);
    }

    
    const changeValue = () => {
      
      let myDate = moment(firstDate.value, "DD/MM/YYYY").format("YYYY-MM-DD");
      const currentDate =
        props.max != ""
          ? props.max
          : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10);
      const minDate = moment(props.min, "YYYY-MM-DD").format("DD/MM/YYYY");
      const setDate = moment(currentDate, "YYYY-MM-DD").format("DD/MM/YYYY");
      if (firstDate.value.length != 10) {
        firstDate.value = "";
        emit("update:modelValue", "");
      }

      if (props.modelValue == "Invalid Date") {
        firstDate.value = "";
        emit("update:modelValue", "");
      }

      if (myDate == "NaN-NaN-NaN") {
        firstDate.value = "";
        emit("update:modelValue", "");
      }

      if (firstDate.value.length == 10 && myDate != "Invalid date") {
        if (
          props.max != "" &&
          myDate > moment(currentDate).format("YYYY-MM-DD")
        ) {
          firstDate.value = "";
          emit("update:modelValue", "");
        } else if (
          props.min != "" &&
          myDate < moment(props.min).format("YYYY-MM-DD")
        ) {
          firstDate.value = "";
          emit("update:modelValue", "");
        } else {
          emit("update:modelValue", myDate);
        }
      } else {
        firstDate.value = "";
        emit("update:modelValue", "");
      }

      emit("change", myDate)
      
    };


    const calRef = ref()
    const openCalendar = () => {
        if(!props.disabled) {
          calRef.value.handleOpen()
          focus()
        }
    }

    const disabledDate = (time: Date) => {
        if(props.min || props.max) {
            const min = moment(props.min, 'YYYY-MM-DD').valueOf()
            const max = moment(props.max, 'YYYY-MM-DD').valueOf()
            if(props.min && !props.max) return time.getTime() < min
            if(!props.min && props.max) return time.getTime() > max
            if(props.min && props.max) return time.getTime() < min || time.getTime() > max
        }
    }

    expose({ focus })


return (_ctx: any,_cache: any) => {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createBlock(_component_el_input, {
    type: "tel",
    placeholder: "DD/MM/YYYY",
    modelValue: firstDate.value,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (firstDate.value = $event)),
    onInput: updateValue,
    onChange: changeValue,
    maxlength: "10",
    disabled: __props.disabled,
    pattern: "[0-9]*",
    ref: inputRef,
    size: "large"
  }, {
    suffix: _withCtx(() => [
      _createVNode(_component_el_date_picker, {
        style: {"visibility":"hidden","width":"1px"},
        modelValue: firstDate.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (firstDate.value = $event)),
        type: "date",
        placeholder: "Pick a Date",
        "default-value": __props.defaultCalendar,
        format: "YYYY/MM/DD",
        "value-format": "DD/MM/YYYY",
        "disabled-date": disabledDate,
        ref: calRef,
        onChange: changeValue
      }, null, 8, ["modelValue", "default-value"]),
      _createVNode(_component_el_icon, {
        class: "el-input__icon pointer",
        onClick: openCalendar
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(Calendar), { onClick: openCalendar })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "disabled"]))
}
}

})