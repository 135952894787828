import { GetterTree } from 'vuex';
import { common } from '@/store/stateless'
import moment from 'moment';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';


import { State } from './state';


export type Getters = {
}

export const getters: GetterTree<State, RootState> & Getters = {
    getInsurancePayment : (state) => (invoice_payment_type:number) => {
        const insurance_payment = {
            3 : 1, //Direct -> Payment Link customer
            4 : 2, // Cheque -> Direct Deposit to insurance company
            5 : 3 // Bank Transfer -> Direct transfer to insurance company   
        }

        return insurance_payment[invoice_payment_type] ?? 0;
    },
    getPolicyActivityLog : (state) => {

        return state.policy_logs.map(x => {
            console.log(x.activity_description);
            let newLog = '';
            let oldLog = '';
            let policyStatus = '';
            const newd = x.activity_description.new;
            

            const oldd = x.activity_description.old;

            if([50,52].includes(x.activity_id))
            {
                if(newd){
                    
                    newLog = newd?.name
                }
                if(oldd){
                    oldLog = oldd?.name
                }
            }
            else if([82].includes(x.activity_id)){
                if(newd) 
                {
                    policyStatus = newd.policy_status ?? ''
                    newLog = ''; 
                    if(newd.insured_person)
                        newLog += ` ${newd.insured_person ?? '-'} |`; 

                    if(newd.emirates_id)
                        newLog += ` ${newd.emirates_id ?? '-'} `;

                }
                if(oldd)
                {
                    oldLog = ''; 
                    
                    if(oldd.insured_person)
                        oldLog += ` ${oldd.insured_person ?? '-'} |`; 
                    
                    if(oldd.emirates_id)
                        oldLog += ` ${oldd.emirates_id ?? '-'} `;
                   
                }
            }
            else{

                if(newd) 
                {
                    policyStatus = newd.policy_status
                    newLog = `${newd.underwriter ?? '-'} |`; 
                    if(newd.category)
                        newLog += ` ${newd.category ?? '-'} |`; 

                    if(newd.policy_number)
                        newLog += ` ${newd.policy_number ?? '-'} |`;
                        
                    if(newd.insurance_type)
                        newLog += ` ${newd.insurance_type ?? '-'} |`; 

                    newLog +=`  ${newd.policy_start_date ?? '-'} | ${newd.credit_notes ?? '-'} | ${newd.debit_notes ?? '-'} | ${newd.policy_sales_agent ?? '-'} `;
                }
                if(oldd)
                {
                    oldLog = `${oldd.underwriter ?? '-'} |`;
                    
                    if(oldd.category)
                        oldLog += ` ${oldd.category ?? '-'} |`; 
                    
                    if(oldd.policy_number)
                        oldLog += ` ${oldd.policy_number ?? '-'} |`;
                        
                    if(oldd.insurance_type)
                        oldLog += ` ${oldd.insurance_type ?? '-'} |`; 

                    oldLog += `  ${oldd.policy_start_date ?? '-'} | ${oldd.credit_notes ?? '-'} | ${oldd.debit_notes ?? '-'} | ${oldd.policy_sales_agent ?? '-'} `;
                }
            }

            return {
                title: x.activity_desc,
                policy_status: policyStatus,
                current_data: newLog,
                previous_data: oldLog,
                by: `${x.action_by ? x.action_by.name : ''} ${x.created_at}`
            }
        })
    },

    getPolicyList: (state) => {
        const data = state.policyLists
        
        if (data) {
            // console.log('invoice',data?.data)
            return data.data.map((x) => {
                const custom = { ... x};
                custom.agent_details =  `${x?.policy_underwriter?.name ?? "NA"} <br />
                ${x?.policy_sales_agent?.name} `;

                custom.invoice_created_details =  `${x?.main_invoice?.created_by?.name ?? ""} <br />
                ${x?.main_invoice?.created_at} `;

                custom.insurance_type_text = x.policy?.insurance_type_text

                custom.policy_name = x.policy?.policy_name
                // custom.policy_name = 'Customer Policy name Making LArges'

                custom.merchant_reference = x?.main_invoice?.merchant_reference
                custom.sales_price = `${common.formatCurrency(x.main_invoice.sales_price)} <br /> ${common.formatCurrency(x.main_invoice.sales_price_vat)}`

                if(x.policy_start_date_format)
                    custom.start_end_date =  `${x.policy_start_date_format} <br/> ${x.policy_end_date_format}`
                else
                    custom.start_end_date = ''
                // console.log(x.main_invoice.policy_price);

                return custom;
            });

        }
        return [] ;
    },

    // getListPolicyGroup: (state) => {
    //     const data = state.policyLists.data

    //     if(data) {
    //         return data.map(value => {
    //             return value.lead.employee_categories.map(x => {
    //                 let start_date = x.invoice && x.invoice.policy_start_date ? x.invoice.policy_start_date : '-'
    //                 let end_date = x.invoice && x.invoice.policy_end_date ? x.invoice.policy_end_date : '-'
    //                 return {
    //                     sub_quote_ref_no: x.invoice && x.invoice.merchant_reference ? x.invoice.merchant_reference : '-',
    //                     category: x.category_name,
    //                     visa_issued: x.visa_emirates_text,
    //                     insurance_plan: x.insurance_plan,
    //                     policy_no: x.invoice && x.invoice.policy_number ? x.invoice.policy_number : '-',
    //                     start_end_date: `${common.setDateOnly(start_date) } <br /> ${common.setDateOnly(end_date)}`,
    //                     members: `Member#: ${x.group_count} | M > 60: ${x.members_above_60}`,
    //                     created_at: `${value.created_at ? value.created_at : '-'} <br /> ${value.issue_date ? value.issue_date : '-'}`,
    //                     agent_details: `${value.policy_underwriter ? value.policy_underwriter.name : 'Not assigned'} <br /> ${value.policy_sales_agent ? value.policy_sales_agent.name : 'Not Assigned'}`,
    //                     insurance_payment_type: `${value.insurance_payment_text ? value.insurance_payment_text : '-'}`,
    //                     health_lead_documents: x.documents,
    //                     policy_document: x.documents.find(x => x.type == 21),
    //                     status: value.status
    //                 }
    //             })
    //         }).flat()
    //     }
    //     return []
    // },

    getGroupLeadPolicies: (state) => {
        if(state.groupLeadPolicies != undefined) {
            const all_policies =  state.groupLeadPolicies.map(x => {
                return x.categories.map(y => {
                    let start_date = y.policy_start_date ? y.policy_start_date : '-'
                    let end_date = y.policy_end_date ? y.policy_end_date : '-'
                    return {
                        sub_quote_ref_no: y.merchant_reference,
                        category: y.category_name,
                        visa_issued: y.visa_emirates_text,
                        insurance_plan: y.insurance_plan,
                        policy_no: y.policy_number ? y.policy_number : '-',
                        start_end_date: `${common.setDateOnly(start_date) } <br /> ${common.setDateOnly(end_date)}`,
                        members: `Member#: ${y.group_count} | M > 60: ${y.members_above_60}`,
                        created_at: `${x.policy_purchase ? x.policy_purchase.created_at : '-'} <br /> ${x.policy_purchase && x.policy_purchase.issue_date ? x.policy_purchase.issue_date : '-'}`,
                        agent_details: `${x.policy_purchase && x.policy_purchase.policy_underwriter ? x.policy_purchase.policy_underwriter.name : 'Not assigned'} <br /> ${x.policy_purchase && x.policy_purchase.policy_sales_agent ? x.policy_purchase.policy_sales_agent.name : 'Not assigned'}`,
                        insurance_payment_type: `${x.policy_purchase && x.policy_purchase.insurance_payment_text ? x.policy_purchase.insurance_payment_text : '-'}`,
                        health_lead_documents: y.documents,
                        policy_document: y.documents.find(z => z.type == 21),
                        status: x.policy_purchase ? x.policy_purchase.status : 0,
                        is_active: x.policy_purchase ? x.policy_purchase.is_active : 0,
                        policy_id: x.policy_purchase_id,
                        lead_id: x.lead_id,
                        lead_employee_category_id: y.lead_employee_category_id
                    }
                })
            }).flat()

            const active = all_policies.filter(x => x.is_active == 1)
            const inactive = all_policies.filter(x => x.is_active == 0)
            return active.concat(inactive)
        }

        return []
    },

    getGroupPolicyList: (state) => {
        const data = state.groupPolicyLists
        console.log('groupPolicyLists',data)
        if (data) {
            // console.log('invoice',data?.data)
            return data.data.map((x) => {
                const custom = { ... x};
                custom.agent_details =  `${x?.policy_underwriter?.name ?? "NA"} <br />
                ${x?.policy_sales_agent?.name} `;

                custom.invoice_created_details =  `${x?.main_invoice?.created_by?.name ?? ""} <br />
                ${x?.main_invoice?.created_at} `;

                custom.insurance_type_text = x.policy?.insurance_type_text

                custom.policy_name = x.policy?.policy_name
                // custom.policy_name = 'Customer Policy name Making LArges'

                custom.merchant_reference = x?.main_invoice?.merchant_reference
                custom.sales_price = `${common.formatCurrency(x.main_invoice.sales_price)} <br /> ${common.formatCurrency(x.main_invoice.sales_price_vat)}`

                if(x.policy_start_date_format)
                    custom.start_end_date =  `${x.policy_start_date_format} <br/> ${x.policy_end_date_format}`
                else
                    custom.start_end_date = ''
                
                custom.categories = x.invoices.map(x => [0,3].includes(x.amend_type) ? x.categories : []).flat()
                // console.log('categories',categories);
                // console.log(x.main_invoice.policy_price);

                return custom;
            });

        }
        return [] ;
    },
}