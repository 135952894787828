
import { defineComponent, computed, ref, reactive } from 'vue'
import Invoice from '@/core/services/Callable/Invoice';
import { store } from "@/store";
import { notification, constant } from "@/store/stateless"
import router from '@/router/clean';
import Validations from '@/core/services/etc/Validations';
import moment from "moment";

interface IUploadForm {
  invoice_id: any;
  invoice_doc: any;
}
export default defineComponent({
  props: {
    invoice: {
      type: Object,
      required: false,
    },
    showAction: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  setup(_,context) {

    const filter = computed(() => {
            return store.state.invoice.filter
        })

    let uploadFormData = ref<IUploadForm>({
      invoice_id: null,
      invoice_doc: null,
    });

    const isUploadReceipt = ref(false)
    const disableSaveButton = ref(false)
    const error = ref('')
    const ruleFormNameRef = ref()
    const uploadInvoice = ref();

    const permissions = computed(() => {
      return store.getters.getUserPermissions
    })

    const closeModal = () => {
      ruleFormNameRef.value.resetFields()
      Validations.is_reference_no_exist = false
      uploadInvoice.value.reset();

      isUploadReceipt.value = false;
      uploadFormData.value.invoice_id = null;
      uploadFormData.value.invoice_doc = null;
    }

    const receipt = ref({
      invoice_id: 0,
      order_description: '',
      reference_no: '',
      payment_date: '',
      invoice_doc: ''
    })
    const uploadReceipt = async(invoiceId) => {
      const response = await Invoice.getGroupInvoiceDetails({invoice_id: invoiceId});
      if(response.status < 299) {
        receipt.value = {
          invoice_id: invoiceId,
          order_description: response.data.data.invoice.order_description,
          reference_no: response.data.data.invoice.reference_no,
          payment_date: response.data.data.invoice.payment_date,
          invoice_doc: ''
        }
        uploadFormData.value.invoice_id = invoiceId;
        isUploadReceipt.value = true;
      }
    }

    const handleFileUpload = (event) => {
      Validations.is_doc_limit = false
      error.value = ''
      uploadFormData.value.invoice_doc = event.target.files[0];
      receipt.value.invoice_doc = event.target.files[0];

      if((event.target.files[0].size  / 1024 / 1024) > 2)
        Validations.is_doc_limit = true
    };

    const uploadInvoiceDocument = async (formEl) => {

      
    //upload a doc

    let formData = new FormData();
    for(const key in receipt.value) {
        formData.set(key, receipt.value[key])
    }

    formEl.validate(async(valid, fields) => {
      
      if(valid) {
        disableSaveButton.value = true;
        const data = await Invoice.uploadInvoiceDoc(formData)
        if(data.status < 299) {
          disableSaveButton.value = false;
          uploadFormData.value.invoice_doc = null;
          notification.success("Invoice", "Your receipt has been uploaded successfully!");
          closeModal();
          formData = new FormData();
          disableSaveButton.value = false;
          Invoice.invoiceLists(filter.value)
        } else {
          disableSaveButton.value = false
          if(data.response.data.data.reference_no) {
            Validations.is_reference_no_exist = true
            formEl.validateField('reference_no')
          }
          disableSaveButton.value = false
        }
      }
    })



    };

    const invoiceDetails = async () => {
      const data = {
        show: true,
        invoice: _.invoice
      }
      // console.log('invoice',_.invoice?.categories);
      await Invoice.setGroupInvoice(_.invoice)
      await Invoice.setInvoiceDetailsModal(data);
    }
    
    const toPages = (name) => {
      const route = router.resolve({ name: name, params: { invoice_id: _.invoice?.id, lead_id: _.invoice?.lead_id } })
      if (route) {
        window.open(route.href, '_blank')
      }
    }

    const addAmendInvoice = () => {

      const route = router.resolve({ name: 'addGroupAmendInvoice', params: { invoice_id: _.invoice?.id, lead_id: _.invoice?.lead_id } })
      if (route) {
        window.open(route.href, '_blank')
      }
    }

    const subAmendInvoice = () => {

      const route = router.resolve({ name: 'subGroupAmendInvoice', params: { invoice_id: _.invoice?.id, lead_id: _.invoice?.lead_id } })
      if (route) {
        window.open(route.href, '_blank')
      }
    }

    const invoiceLogModal = async () => {
        const invoiceId = _.invoice && _.invoice.parent_invoice_id ? _.invoice.parent_invoice_id : _.invoice?.id; 
        await Invoice.getGroupInvoiceLog({lead_id: _.invoice?.lead_id , invoice_id: invoiceId});
    }

    const rules = reactive({
        invoice_doc: [{ validator: Validations.invoice_doc, trigger: ['blur', 'change'] }],
        order_description: [{ validator: Validations.order_description, trigger: ['blur', 'change'] }],
        reference_no: [{ validator: Validations.reference_no, trigger: ['blur', 'change'] }],
        payment_date: [{ validator: Validations.payment_date, trigger: ['blur', 'change'] }],
    })

    const resetError = () => {
      Validations.is_reference_no_exist = false
    }

    return {
      uploadInvoice,
      ruleFormNameRef,
      moment,
      resetError,
      rules,
      receipt,
      toPages,
      error,
      isUploadReceipt,
      disableSaveButton,
      uploadReceipt,
      handleFileUpload,
      closeModal,
      uploadInvoiceDocument,
      invoiceDetails,
      addAmendInvoice,
      constant,
      subAmendInvoice,
      store,
      permissions,
      invoiceLogModal
    }
  },
})
