import { Login, User,NewCheckAuth } from '@/core/types/Auth';


export type State = {
  login: Login | null;
  user: User;
  token: string | null;
  loading: Boolean;
  error: string | null;
  setNewAuthCheck:NewCheckAuth|null;
}

export const state: State = {
  login: {
    email: null,
    password: null
  },
  user: window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user') || '') : {} as User,
  token: window.localStorage.getItem('id_token') || null,
  loading: false,
  error: null,
  setNewAuthCheck:null
};
