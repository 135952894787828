
import { defineComponent, nextTick, ref, watch, onMounted } from 'vue'

export default defineComponent({
    name: 'custom-emirates-id-input',
    props: {
        label: {
            type: String,
            required: true,
            default: '',
        },
        subLabel: {
            type: String,
            required: false,
            default: '',
        },
        isRequired: {
            type: Boolean,
            required: false,
            default: false
        },
        prop: {
            type: String,
            required: true,
            default: 'User'
        },
        modelValue: {
            type: String,
            required: true,
            default: ''
        },
        size: {
            type: [String, Boolean],
            required: false,
            default: 'default'
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    setup(props, context) {

        const emiratesID = ref("")

        watch(emiratesID, (value) => {
            if (!props.disabled) {
                const result = value
                    .replace(/^(\d\d\d)(\d)$/g, "$1-$2")
                    .replace(/^(\d\d\d-\d\d\d\d)(\d)$/g, "$1-$2")
                    .replace(/^(\d\d\d-\d\d\d\d-\d\d\d\d\d\d\d)(\d)$/g, "$1-$2")
                    .replace(/[^\d-]/g, "");
                nextTick(() => (emiratesID.value = result));
            }

        });

        watch(() => props.modelValue, newValue => {
            // console.log('eawkdjf 2');
            const result = newValue
                    .replace(/^(\d\d\d)(\d)$/g, "$1-$2")
                    .replace(/^(\d\d\d-\d\d\d\d)(\d)$/g, "$1-$2")
                    .replace(/^(\d\d\d-\d\d\d\d-\d\d\d\d\d\d\d)(\d)$/g, "$1-$2")
                    .replace(/[^\d-]/g, "");
                nextTick(() => (emiratesID.value = result));
        })

        onMounted(() => {
            if (props.disabled || props.modelValue) {
                emiratesID.value = props.modelValue;
            }

        });

        const autoMask = () => {
            let all = ''
            if (emiratesID.value.length === 15) {
                const str = emiratesID.value
                const first = str.slice(0, 3)
                const second = str.slice(3, 7)
                const third = str.slice(7, 14)
                const fourth = str.slice(14, 15)
                all = `${first}-${second}-${third}-${fourth}`
            }
            const myArray = emiratesID.value.split('-')
            if (emiratesID.value.length === 18 && myArray.length === 4) {
                all = emiratesID.value
            }
            emiratesID.value = all
            context.emit("update:modelValue", all)
            context.emit('blur')
        }


        const updateValue = () => {
            context.emit("update:modelValue", emiratesID.value)
            context.emit('input')
        }

        // const focus = ref()

        // const isFocus = () => {
        //     focus.value.focus()
        // }


        const inputRef = ref()
        const focus = () => {
            setTimeout(() => {
                if(inputRef.value) inputRef.value.focus()
            }, 500);
        }
        return {
            // isFocus,
            focus,
            autoMask,
            emiratesID,
            updateValue,
            inputRef
        }
    },
})
