
import { defineComponent, computed, ref, getCurrentInstance, onMounted } from 'vue'
import Customers from '@/core/services/Callable/Customers';
import Etcs from '@/core/services/Callable/Etcs';
import { store } from "@/store";
import moment from 'moment';
import { customer, showHide, filter as filters, common } from '@/store/stateless';
import { GENERIC } from '@/core/types/Etc';

const __default__ = defineComponent({
    name: 'customer-first-layer',
    setup() {

        const currentInst = getCurrentInstance();
        const roles = currentInst?.appContext.config.globalProperties.roles;
        const transfer = ref()
        // const value2 = ref('')

        const shortcuts = [
            {
                text: 'Last week',
                value: () => {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                    return [start, end]
                },
            },
            {
                text: 'Last month',
                value: () => {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                    return [start, end]
                },
            },
            {
                text: 'Last 3 months',
                value: () => {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                    return [start, end]
                },
            },
        ]
        
        const agents = ref<Array<GENERIC>>([]);
        onMounted(async() => {
            if (store.getters.showByRole([14])) 
                await Etcs.getAgentLists();
            else
                await Etcs.getAgentLists('all');
            const agent = store.state.etc.agents;
            console.log({agent})
            // const exist = agent.some((e) => { return e.value == -1 });
            // if (!exist) {
                if (store.getters.showByRole([1, 9, 13, 14])) {
                    if(agent.length == 0) {
                        agent.unshift({ label: 'Mine', value: -1 });
                        agents.value = agent;
                    }
                    agent.unshift({ label: 'Select agent view for', value: "all" });
                    agents.value = agent;
                }
            // }
        })

        const customerLists = computed(() => {
            return store.state.customer.customerLists;
        })

        const filter = computed(() => {
            return store.state.customer.filter;
        })

        const checkedTransfer = computed(() => {
            return store.state.customer.checkedTransfer;
        })

        // events
        const changeAgent = async (event) => {
            filter.value.keyword = ''
            filter.value.task_due_dates = -1
            filter.value.agent = event;
            filter.value.page = 1;
            if (event != -1) {
                customer.colors = {
                    btnBgColor: "#fff",
                    btnColor: "#000",
                }
            } else {
                customer.colors = {
                    btnBgColor: "#fff",
                    btnColor: "#000",
                }
            }

            await Customers.customerLists(filter.value);
        }

        const getAgentId = async () => {
            filter.value.keyword = ''
            customer.colors = {
                btnBgColor: "#fff",
                btnColor: "#000",
                slcBgColor: "#222261",
                slcColor: "#fff"
            }
            if (filter.value.agent === "all") {
                customer.colors = {
                    btnBgColor: "#fff",
                    btnColor: "#000",
                    slcBgColor: "#fff",
                    slcColor: "#5E6278"
                }
            }
            await Customers.customerLists(filter.value);
        }

        const getDates = async (e) => {
            var start = moment(e[0]).format('YYYY-MM-DD');
            var end = moment(e[1]).format('YYYY-MM-DD');

            filter.value.task_due_dates = -1
            filter.value.start_date = start;
            filter.value.end_date = end;
            filter.value.page = 1;

            await Customers.customerLists(filter.value);
        }

        const refresh = async () => {
            // value2.value = '';
            let agent:any = 'all';
                if(store.getters.showByRole([1,14])) 
                    agent = 'all';
                else if(store.getters.showByRole([9,13])) 
                    agent = -1;
                else 
                    agent = store.getters.myId;
                    
            Customers.setFilter({
                agent: agent,
                // agent: 'all',
                customer_status_id: -1,
                end_date: "",
                index: 1,
                keyword: "",
                lead_status_id: -1,
                per_page: 50,
                page: 1,
                start_date: "",
                task_due_dates: -1,
                hot_enquiry: -1
            })
            customer.colors = {
                btnBgColor: "#fff",
                btnColor: "#000",
                slcBgColor: "#fff",
                slcColor: "#5E6278"
            }

            await Customers.customerLists(filter.value);
        }

        const openTransfer = () => {
            Customers.setTransferModal(true);
        }

        const role_id = computed(() => {
            const lSUser = window.localStorage.getItem('user')
            if(lSUser) {
                const user = JSON.parse(lSUser)
                return user.role_id
            }

            return 0
        })

        return {
            role_id,
            common,
            store,
            customer,
            filter,
            filters,
            agents,
            transfer,
            shortcuts,
            customerLists,
            checkedTransfer,
            changeAgent,
            getAgentId,
            getDates,
            refresh,
            openTransfer,
            showHide,
        }
    },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "390163b5": (_ctx.customer.colors.btnBgColor),
  "5eb2f6ba": (_ctx.customer.colors.btnColor),
  "686c7e87": (_ctx.customer.colors.btnBgColorHover),
  "0a4706a2": (_ctx.customer.colors.btnColorHover),
  "59118127": (_ctx.customer.colors.slcBgColor),
  "eaa06ea8": (_ctx.customer.colors.slcColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__