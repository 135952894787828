import { MutationTree } from 'vuex';

import { State } from './state';
import { CustomerMutationTypes } from '@/store/enums/Customer/Mutations';

export type Mutations<S = State> = {
    [CustomerMutationTypes.SET_QUICK_LEAD](state: S, payload: any | null): void;
    [CustomerMutationTypes.SET_QUICK_LEAD_DIALOG](state: S, payload: any): void;
    [CustomerMutationTypes.SET_IMPORT_DIALOG](state: S, payload: any): void;
    [CustomerMutationTypes.SET_CUSTOMER_TRANSFER_DIALOG](state: S, payload: any): void;
    [CustomerMutationTypes.SET_CUSTOMER_LOADING](state: S, payload: any): void;
    [CustomerMutationTypes.SET_CUSTOMER_LISTS](state: S, payload: any): void;
    [CustomerMutationTypes.SET_CUSTOMER_LIST_COUNTS](state: S, payload: any): void;
    [CustomerMutationTypes.SET_LEAD_FILTER_COUNT](state: S, payload: any): void;
    [CustomerMutationTypes.SET_CUSTOMER_FILTER](state: S, payload: any): void;
    [CustomerMutationTypes.SET_CUSTOMER_HEALTH](state: S, payload: any): void;
    [CustomerMutationTypes.SET_CUSTOMER_HEALTH_LEAD_ID](state: S, payload: any): void;
    [CustomerMutationTypes.SET_CUSTOMER_HEALTH_DECLARATIONS](state: S, payload: any): void;
    [CustomerMutationTypes.SET_CUSTOMER_LEAD_DECLARATIONS](state: S, payload: any): void;
}

export const mutations: MutationTree<State> & Mutations = {
      [CustomerMutationTypes.SET_QUICK_LEAD](state: State, payload: any) {
        state.quickLead = payload;
      },

      [CustomerMutationTypes.SET_IMPORT_DIALOG](state: State, payload: any) {
        state.importModal = payload;
      },

      [CustomerMutationTypes.SET_CUSTOMER_TRANSFER_DIALOG](state: State, payload: any) {
        state.transferModal = payload;
      },

      [CustomerMutationTypes.SET_QUICK_LEAD_DIALOG](state: State, payload: any) {
        state.quckLeadModal = payload;
      },

      [CustomerMutationTypes.SET_CUSTOMER_LOADING](state: State, payload: any) {
        state.loading = payload;
      },

      [CustomerMutationTypes.SET_CUSTOMER_LISTS](state: State, payload: any) {
        state.customerLists = payload;
      },

      [CustomerMutationTypes.SET_CUSTOMER_LIST_COUNTS](state: State, payload: any) {
        state.customerListCounts = payload;
      },

      [CustomerMutationTypes.SET_LEAD_FILTER_COUNT](state: State, payload: any) {
        state.leadFilterCounts = payload;
      },

      [CustomerMutationTypes.SET_CUSTOMER_FILTER](state: State, payload: any) {
        state.filter = payload;
      },

      [CustomerMutationTypes.SET_CUSTOMER_HEALTH](state: State, payload: any) {
        state.health = payload;
      },

      [CustomerMutationTypes.SET_CUSTOMER_HEALTH_DECLARATIONS](state: State, payload: any) {
        state.leadInformation = payload;
      },

      [CustomerMutationTypes.SET_CUSTOMER_LEAD_DECLARATIONS](state: State, payload: any) {
        state.declarations = payload;
      },

      [CustomerMutationTypes.SET_CUSTOMER_HEALTH_LEAD_ID](state: State, payload: any) {
        state.leadId = payload;
      },

      [CustomerMutationTypes.SET_CUSTOMER_TRANSFER_CHECKED](state: State, payload: any) {
        console.log('sdsf',payload)
        state.checkedTransfer = payload;
      },

      [CustomerMutationTypes.SET_DETAIL_CUSTOMER](state: State, payload: any) {
        state.detailsCustomer = payload;
      },

      [CustomerMutationTypes.SET_VIEW_TASKS](state: State, payload: any) {
        state.task_details = payload;
      },

      [CustomerMutationTypes.SET_DETAIL_CUSTOMER_LOADING](state: State, payload: any) {
        state.detailLoading = payload;
      },

      [CustomerMutationTypes.SET_VIEW_TASK_LOADING](state: State, payload: any) {
        state.task_loading = payload;
      },

      [CustomerMutationTypes.SET_VIEW_NOTES_LOADING](state: State, payload: any) {
        state.notes_loading = payload;
      },

      [CustomerMutationTypes.SET_VIEW_EMAIL_LOADING](state: State, payload: any) {
        state.email_loading = payload;
      },

      [CustomerMutationTypes.SET_VIEW_SMS_LOADING](state: State, payload: any) {
        state.sms_loading = payload;
      },

      [CustomerMutationTypes.SET_CUSTOMER_ACTIVITY_LOG_LOADING](state: State, payload: any) {
        state.activity_log_loading = payload;
      },

      [CustomerMutationTypes.SET_CUSTOMER_STATUS_LOG_LOADING](state: State, payload: any) {
        state.status_log_loading = payload;
      },

      [CustomerMutationTypes.SET_LEAD_ACTIVITY_LOG_LOADING](state: State, payload: any) {
        state.lead_activity_log_loading = payload;
      },

      [CustomerMutationTypes.SET_LEAD_STATUS_LOG_LOADING](state: State, payload: any) {
        state.lead_status_log_loading = payload;
      },

      [CustomerMutationTypes.SET_VIEW_NOTES](state: State, payload: any) {
        state.notes = payload;
      },

      [CustomerMutationTypes.SET_CUSTOMER_ACTIVITY_LOG](state: State, payload: any) {
        state.customer_activity = payload;
      },

      [CustomerMutationTypes.SET_CUSTOMER_VIEW_ACTIVITY_LOG](state: State, payload: any) {
        state.customer_view_activity = payload;
      },

      [CustomerMutationTypes.SET_CUSTOMER_STATUS_LOG](state: State, payload: any) {
        state.customer_status = payload;
      },

      [CustomerMutationTypes.SET_LEAD_ACTIVITY_LOG](state: State, payload: any) {
        state.lead_activity_log = payload;
      },

      [CustomerMutationTypes.SET_LEAD_STATUS_LOG](state: State, payload: any) {
        state.lead_status_log = payload;
      },

      [CustomerMutationTypes.SET_VIEW_EMAILS](state: State, payload: any) {
        state.emails = payload;
      },

      [CustomerMutationTypes.SET_VIEW_SMS](state: State, payload: any) {
        state.sms = payload;
      },

      [CustomerMutationTypes.SET_DETAIL_CUSTOMER_LEAD](state: State, payload: any) {
        state.detailsCustomerLead = payload;
      },
};
