import { ActionTree, ActionContext } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';
import { State } from './state';
import { Mutations } from './mutations';
import { Mutations as etcMutation } from '@/store/modules/Etc/mutations';
import { InvoiceMutationTypes } from '@/store/enums/Invoice/Mutations';
import { InvoiceActionTypes } from '@/store/enums/Invoice/Actions';
import ApiService from "@/core/services/ApiService";
import { Links } from '@/store/enums/Links';
import { notification } from '@/store/stateless'

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
  commit<K extends keyof etcMutation>(
    key: K,
    payload: Parameters<etcMutation[K]>[1],
  ): ReturnType<etcMutation[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {

}

export const actions: ActionTree<State, RootState> & Actions = {
  async [InvoiceActionTypes.MAKE_INVOICE]({ commit }, payload) {
    try {
      const request = {
        data: payload,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      commit(InvoiceMutationTypes.SET_INVOICE_LOADING, true);

      const data = await ApiService.post(Links.MAKE_INVOICE, payload)
      const invoice = data.data.data.invoice;
      invoice.isError = false

      commit(InvoiceMutationTypes.SET_INVOICE_MODAL, true);
      commit(InvoiceMutationTypes.SET_INVOICE_MODAL_VALUE, invoice);
      commit(InvoiceMutationTypes.SET_INVOICE_LOADING, false);

      return data;
    }
    catch (err: any) {
      // console.log(err.response)
      // console.log(err.response.data)

      if(err.response.data.data.has_pending_invoice) {
        const invoice = {
          ...err.response.data.data.invoice,
          isError: false
        }
        // commit(InvoiceMutationTypes.SET_INVOICE_MODAL, true);
        // commit(InvoiceMutationTypes.SET_INVOICE_MODAL_VALUE, invoice);
        commit(InvoiceMutationTypes.SET_INVOICE_LOADING, false);
        return err.response
      } else {
        if(err.response.status == 422){
          if(err.response?.data?.data?.reference_no) {
            // 
          } else if(err.response?.data?.data?.doc_error) {
            const obj = {
              customer_id:err.response.data.data.customer_id,
              isError: false,
              isDocError : true,
              msg:err.response?.data?.message
            }
            commit(InvoiceMutationTypes.SET_INVOICE_MODAL, true);
            commit(InvoiceMutationTypes.SET_INVOICE_MODAL_VALUE, obj);
          }
          else notification.warning('Invoice',err.response?.data?.message);
        }
        else {
         let has_duplicate_data = false
          let message = "here was an issue in invoice generation. Kindly contact the technical team to resolve the issue"

          if(err.response.data.data.has_invoice_created) {
            message = "Something went wrong, You are trying to create a duplicate invoice"
            has_duplicate_data = true
          }
          
          const obj = {
            customer_id:err.response.data.data.customer_id,
            isError : true,
            message,
            has_duplicate_data
          }
          commit(InvoiceMutationTypes.SET_INVOICE_MODAL, true);
          commit(InvoiceMutationTypes.SET_INVOICE_MODAL_VALUE, obj);
        }
        commit(InvoiceMutationTypes.SET_INVOICE_LOADING, false);
      }

      return err.response;
    }
  },
  async [InvoiceActionTypes.MAKE_INVOICE_EVENT]({ commit }, payload) {
      await ApiService.post(Links.MAKE_INVOICE_EVENT, payload)
  },

  async [InvoiceActionTypes.ADD_AMEND_INVOICE]({ commit }, payload) {
    try {
      const request = {
        data: payload,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      commit(InvoiceMutationTypes.SET_INVOICE_LOADING, true);

      const data = await ApiService.post(Links.ADD_AMEND_INVOICE, payload)
      const invoice = data.data.data.invoice;
      invoice.isError = false
      commit(InvoiceMutationTypes.SET_INVOICE_MODAL, true);
      commit(InvoiceMutationTypes.SET_INVOICE_MODAL_VALUE, invoice);
      commit(InvoiceMutationTypes.SET_INVOICE_LOADING, false);

      return data;
    }
    catch (err: any) {
      // console.log(err.response.data)
      commit(InvoiceMutationTypes.SET_INVOICE_LOADING, false);
      const obj = {
        customer_id:err.response.data.data.customer_id,
        isError : true
      }
      
      if(err.response?.data?.data?.reference_no) {
        // 
      }
      else {
        commit(InvoiceMutationTypes.SET_INVOICE_MODAL, true);
        commit(InvoiceMutationTypes.SET_INVOICE_MODAL_VALUE, obj);
      }
      

      return err;
    }
  },

  async [InvoiceActionTypes.SUB_AMEND_INVOICE]({ commit }, payload) {
    try {
      const request = {
        data: payload,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      commit(InvoiceMutationTypes.SET_INVOICE_LOADING, true);

      const data = await ApiService.post(Links.SUB_AMEND_INVOICE, payload)
      const invoice = data.data.data.invoice;
      invoice.isError = false
      commit(InvoiceMutationTypes.SET_INVOICE_MODAL, true);
      commit(InvoiceMutationTypes.SET_INVOICE_MODAL_VALUE, invoice);
      commit(InvoiceMutationTypes.SET_INVOICE_LOADING, false);

      return data;
    }
    catch (err: any) {
      // console.log(err.response.data)
      const obj = {
        customer_id:err.response.data.data.customer_id,
        isError : true
      }
    
      commit(InvoiceMutationTypes.SET_INVOICE_MODAL, true);
      commit(InvoiceMutationTypes.SET_INVOICE_MODAL_VALUE, obj);
      commit(InvoiceMutationTypes.SET_INVOICE_LOADING, false);

      return err;
    }
  },

  async [InvoiceActionTypes.INVOICE_LISTS]({ commit }, payload) {
    try {
      const data = await Promise.all([
        await ApiService.post(Links.POST_APPROVAL_INVOICE_LIST + `?page=${payload.page}`, payload),
        await ApiService.post(Links.POST_APPROVAL_INVOICE_LIST_COUNT, payload),
      ])
      commit(InvoiceMutationTypes.SET_INVOICE_LISTS, data[0].data.data)
      commit(InvoiceMutationTypes.SET_INVOICE_LIST_COUNTS, data[1].data.data)
    } catch (err: any) {
      return err;
    }
  },

  async [InvoiceActionTypes.UPDATE_INVOICE]({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.UPDATE_INVOICE, payload)
      return data;
    }
    catch (err: any) {
      return err;
    }
  },

  async [InvoiceActionTypes.SEND_PAYMENT_LINK_EMAIL]({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.SEND_PAYMENT_LINK_EMAIL, payload)
      return data;
    }
    catch (err: any) {
      return err;
    }
  },

  async [InvoiceActionTypes.SEND_INVOICE_GENERATION_ISSUE_EMAIL]({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.SEND_INVOICE_GENERATION_ISSUE_EMAIL, payload)
      return data;
    }
    catch (err: any) {
      return err;
    }
  },

  async [InvoiceActionTypes.INTERESTED_POLICY]({ commit }, payload) {
    try {
      console.log('payload', payload)
      const data = await ApiService.post(Links.INTERESTED_POLICY, payload)
      const policy = data.data.data.policy;

      await commit(InvoiceMutationTypes.SET_INTERESTED_POLICY_MODAL_VALUE, policy);
      commit(InvoiceMutationTypes.SET_INTERESTED_POLICY_MODAL, true);

    } catch (err: any) {
      return err;
    }
  },

  async [InvoiceActionTypes.UPLOAD_INVOICE_DOCUMENT]({ commit }, payload) {
    try {
      const request = {
        data: payload,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      const data = await ApiService.post(Links.UPLOAD_INVOICE_DOCUMENT, payload)
      return data;
    }
    catch (err: any) {

      return err;
    }
  },

  async [InvoiceActionTypes.GET_INVOICE_DETAILS]({}, payload) {
    try {
      const data = await ApiService.post(Links.GET_INVOICE_DETAILS, payload);
      return data
    } catch (error) {
      return error
    }
  },

  async [InvoiceActionTypes.GET_GROUP_INVOICE_DETAILS]({}, payload) {
    try {
      const data = await ApiService.post(Links.GET_GROUP_INVOICE_DETAILS, payload);
      return data
    } catch (error) {
      return error
    }
  },

  async [InvoiceActionTypes.GET_INVOICE]({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.GET_INVOICE, payload)
      await commit(InvoiceMutationTypes.SET_INVOICE_DATA, data.data.data);

      return data.data.data;
    }
    catch (err: any) {
      return err;
    }
  },


  async [InvoiceActionTypes.MAKE_GROUP_INVOICE]({ commit }, payload) {
    try {
      const request = {
        data: payload,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      commit(InvoiceMutationTypes.SET_INVOICE_LOADING, true);

      const data = await ApiService.post(Links.MAKE_GROUP_INVOICE, payload)
      const invoice = data.data.data.invoice;
      invoice.isError = false

      commit(InvoiceMutationTypes.SET_INVOICE_MODAL, true);
      commit(InvoiceMutationTypes.SET_INVOICE_MODAL_VALUE, invoice);
      commit(InvoiceMutationTypes.SET_INVOICE_LOADING, false);

      return data;
    }
    catch (err: any) {
      // console.log(err.response)
      // console.log(err.response.data)
      
      if(err.response.data.data.has_pending_invoice) {
        const invoice = {
          ...err.response.data.data.invoice,
          isError: false
        }
        // commit(InvoiceMutationTypes.SET_INVOICE_MODAL, true);
        // commit(InvoiceMutationTypes.SET_INVOICE_MODAL_VALUE, invoice);
        commit(InvoiceMutationTypes.SET_INVOICE_LOADING, false);
        return err.response

      } else {
        if(err.response.status == 422){
          if(err.response?.data?.data?.reference_no) {
            // 
          }
          else notification.warning('Invoice',err.response?.data?.message);
        }
        else {
          let has_duplicate_data = false
          let message = "here was an issue in invoice generation. Kindly contact the technical team to resolve the issue"
      
          if(err.response.data.data.has_invoice_created) {
            message = "Something went wrong, You are trying to create a duplicate invoice"
            has_duplicate_data = true
          }
          
          const obj = {
            customer_id:err.response.data.data.customer_id,
            isError : true,
            message,
            has_duplicate_data
          }
          commit(InvoiceMutationTypes.SET_INVOICE_MODAL, true);
          commit(InvoiceMutationTypes.SET_INVOICE_MODAL_VALUE, obj);
        }
        commit(InvoiceMutationTypes.SET_INVOICE_LOADING, false);
      }

      return err.response;
    }
  },
  

  async [InvoiceActionTypes.GROUP_INVOICE_LISTS]({ commit }, payload) {
    try {
      const data = await Promise.all([
        await ApiService.post(Links.GROUP_INVOICE_LIST + `?page=${payload.page}`, payload),
        await ApiService.post(Links.GROUP_INVOICE_LIST_COUNT, payload),
      ])
      commit(InvoiceMutationTypes.SET_GROUP_INVOICE_LIST, data[0].data.data)
      commit(InvoiceMutationTypes.SET_GROUP_INVOICE_LIST_COUNT, data[1].data.data)
    } catch (err: any) {
      return err;
    }
  },

  async [InvoiceActionTypes.UPDATE_GROUP_INVOICE]({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.UPDATE_GROUP_INVOICE, payload)
      return data;
    }
    catch (err: any) {
      return err;
    }
  },


  async [InvoiceActionTypes.UPLOAD_GROUP_INVOICE_DOCUMENT]({ commit }, payload) {
    try {
      const request = {
        data: payload,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      const data = await ApiService.post(Links.UPLOAD_GROUP_INVOICE_DOCUMENT, payload)
      return data;
    }
    catch (err: any) {

      return err;
    }
  },

  async [InvoiceActionTypes.GET_GROUP_INVOICE]({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.GET_GROUP_INVOICE, payload)
      await commit(InvoiceMutationTypes.SET_INVOICE_DATA, data.data.data);

      return data.data.data;
    }
    catch (err: any) {
      return err;
    }
  },


  async [InvoiceActionTypes.ADD_GROUP_AMEND_INVOICE]({ commit }, payload) {
    try {
      const request = {
        data: payload,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      commit(InvoiceMutationTypes.SET_INVOICE_LOADING, true);

      const data = await ApiService.post(Links.ADD_GROUP_AMEND_INVOICE, payload)
      const invoice = data.data.data.invoice;
      invoice.isError = false
      commit(InvoiceMutationTypes.SET_INVOICE_MODAL, true);
      commit(InvoiceMutationTypes.SET_INVOICE_MODAL_VALUE, invoice);
      commit(InvoiceMutationTypes.SET_INVOICE_LOADING, false);

      return data;
    }
    catch (err: any) {
      // console.log(err.response.data)
      commit(InvoiceMutationTypes.SET_INVOICE_LOADING, false);
      const obj = {
        customer_id:err.response.data.data.customer_id,
        isError : true
      }

      if(err.response?.data?.data?.reference_no) {
        // 
      }
      else {
        commit(InvoiceMutationTypes.SET_INVOICE_MODAL, true);
        commit(InvoiceMutationTypes.SET_INVOICE_MODAL_VALUE, obj);
      }

      return err;
    }
  },

  async [InvoiceActionTypes.SUB_GROUP_AMEND_INVOICE]({ commit }, payload) {
    try {
      const request = {
        data: payload,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      commit(InvoiceMutationTypes.SET_INVOICE_LOADING, true);

      const data = await ApiService.post(Links.SUB_GROUP_AMEND_INVOICE, payload)
      const invoice = data.data.data.invoice;
      invoice.isError = false
      commit(InvoiceMutationTypes.SET_INVOICE_MODAL, true);
      commit(InvoiceMutationTypes.SET_INVOICE_MODAL_VALUE, invoice);
      commit(InvoiceMutationTypes.SET_INVOICE_LOADING, false);

      return data;
    }
    catch (err: any) {
      // console.log(err.response.data)
      const obj = {
        customer_id:err.response.data.data.customer_id,
        isError : true
      }
      commit(InvoiceMutationTypes.SET_INVOICE_MODAL, true);
      commit(InvoiceMutationTypes.SET_INVOICE_MODAL_VALUE, obj);
      commit(InvoiceMutationTypes.SET_INVOICE_LOADING, false);

      return err;
    }
  },

  async [InvoiceActionTypes.GET_GROUP_INVOICE_LOG]({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.GET_GROUP_INVOICE_LOG, payload)

      const value = {
        show:true,
        log:data.data.data
      }
      await commit(InvoiceMutationTypes.SET_GROUP_INVOICE_LOG, value);

      return data.data.data;
    }
    catch (err: any) {
      return err;
    }
  },

  async [InvoiceActionTypes.VALIDATE_DUPLICATES]({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.VALIDATE_DUPLICATES, payload)
      return data.data.data;
    }
    catch (err: any) {
      return err;
    }
  },

  async [InvoiceActionTypes.GROUP_CATEGORY_ADDITION_INVOICE]({ commit }, payload) {
    try {
      const request = {
        data: payload,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      commit(InvoiceMutationTypes.SET_INVOICE_LOADING, true);

      const data = await ApiService.post(Links.GROUP_CATEGORY_ADDITION_INVOICE, payload)
      const invoice = data.data.data.invoice;
      invoice.isError = false
      commit(InvoiceMutationTypes.SET_INVOICE_MODAL, true);
      commit(InvoiceMutationTypes.SET_INVOICE_MODAL_VALUE, invoice);
      commit(InvoiceMutationTypes.SET_INVOICE_LOADING, false);

      return data;
    }
    catch (err: any) {
      // console.log(err.response.data)
      commit(InvoiceMutationTypes.SET_INVOICE_LOADING, false);
      const obj = {
        customer_id:err.response.data.data.customer_id,
        isError : true
      }
      if(err.response?.data?.data?.reference_no) {
        // 
      }
      else {
        commit(InvoiceMutationTypes.SET_INVOICE_MODAL, true);
        commit(InvoiceMutationTypes.SET_INVOICE_MODAL_VALUE, obj);
      }

      return err;
    }
  },
};

