
import { defineComponent, computed, onMounted, ref, watch } from 'vue'
import Customers from '@/core/services/Callable/Customers';
import { store } from '@/store';
import { useRoute } from 'vue-router';

export default defineComponent({
    setup(_, context) {
        const route = useRoute()

        const leads = computed(() => store.getters.getLeads(false));
        const selectedLead = ref(0);
        const openLeadLoading = ref(false);
        // const openLead = ref({});
        const targetElementExists = ref(false);

        const updateTargetElementExists = () => {
            targetElementExists.value = !!document.getElementById(`accordion-datas-${selectedLead.value}`);
        };

        onMounted(() => {
            // console.log('on mounted',openLead.value)
            // const listRef: any = document.getElementById('accordian_0');
            // if (leads.value.length > 0 && listRef) {
            //     listRef.click();
            // }

            if(leads.value.length > 0) {
                let index = 0

                if(route.query && route.query.lead_id) {
                    index = leads.value.findIndex(x => x.lead_id == route.query.lead_id)
                }

                clickFirstElement(index)
            }
        });

        
        function clickFirstElement(index = 0) {
            const listRef:any = document.getElementById(`accordian_${index}`)
            listRef.click()
        }


        // const openLead = computed(() => store.getters.getLeadsDetails(false));
        const openLead = ref({});

        watch(openLead, (newIndex) => {
            // console.log('watch',openLead.value)
            // openLead.value = leads.value[newIndex];
            // updateTargetElementExists();
        });

        const tabAction = () => {
            console.log('documents');
            context.emit('tab', 'documents');
        };

        const clickId = ref(0)

        const callApi = ref(false)

        const fetchOpenLead = async (lead,sectionId) => {
            if(callApi.value)
                return ;

            callApi.value = true;
            // console.log('calling', sectionId);
            selectedLead.value = sectionId;

            setTimeout(() => {
                const offset = sectionId == 0 ? 280 : 130; // Define the offset distance
                const sectionElement = document.getElementById('accordian_'+sectionId);
                if (sectionElement) {
                    const elementPosition = sectionElement.getBoundingClientRect().top;
                    const offsetPosition = elementPosition + window.scrollY - offset;
                    window.scrollTo({
                    top: offsetPosition
                    });
                }
            }, 400);

            // console.log('openLead.value', openLead.value);

            if(clickId.value != lead.lead_id) {
                const params = {
                    lead_id : lead.lead_id,
                    customer_id : lead.customer_id,

                }
                openLeadLoading.value = true
                const data = await Customers.openLeadDetails(params)
                openLead.value = store.getters.getLeadsDetails(false)
                openLeadLoading.value = false
                clickId.value = lead.lead_id
            }else {
                clickId.value = 0
            }

            // console.log('openLead',store.getters.getLeadsDetails(false))
            callApi.value = false;

        };
        const task_details = ref()
        async function recallData(data) {
            const {lead_id, customer_id} = leads.value.find(x => x.lead_id == data.lead_id)
            const first = leads.value[0]

                if(first.lead_id != lead_id) {
                    if(selectedLead.value != 0) {
                        clickFirstElement(0)
                    } else {
                        const params = {
                            lead_id: first.lead_id,
                            customer_id: first.customer_id
                        }
                        await await Customers.openLeadDetails(params)
                        openLead.value = store.getters.getLeadsDetails(false)
                    }
                } else {
                    const params = {
                        lead_id,
                        customer_id

                    }
                    if(clickId.value == data.lead_id) {
                        await await Customers.openLeadDetails(params)
                        openLead.value = store.getters.getLeadsDetails(false)
                    }
                }
        }

        function resetTask() {
            task_details.value.resetTask()
        }

        return { task_details, resetTask, recallData, leads, store, tabAction, openLead, fetchOpenLead, selectedLead, targetElementExists, openLeadLoading };
    },
});
