<template>
    <div class="d-flex justify-content-end search-block lead-filter-buttons">
        <div
            v-if="showTextFilter"
            class="search-field-block search-block-tooltip-report popper-custom"
            >

            <div class="d-flex align-items-center position-relative" >
                <span class="svg-icon svg-icon-1 position-absolute ms-6">
                <inline-svg src="media/icons/duotune/general/gen021.svg" />
                </span>
                
                    <Popper
                    hover="false"
                    placement="bottom"
                    arrow
                    disableClickAway
                    >
                    <el-autocomplete
                    v-if="autoSuggest"
                    v-model="filter.search"
                    clearable
                    :fetch-suggestions="quoteAutoSuggest"
                    :placeholder="textFilterPlaceHolder"
                    size="large"
                />
                        <input
                        v-else
                            type="text"
                            v-model="filter.search"
                            @keydown.enter.prevent="HandleSearch"
                            class="form-control ps-15"
                            :placeholder="textFilterPlaceHolder"
                        />
                        <template #content>
                            <div
                            class="card card-flush"
                            style="
                                box-shadow: 0px 10px 31px 10px rgba(82 63 105, 0.5);
                            "
                            >
                            <div class="card-body py-5">
                                {{ textFilterPopUp }}
                            </div>
                            </div>
                        </template>
                    </Popper>
            </div>

            
            
        </div>
        <div class="export-btn d-flex align-items-center">
          
                <button
                    v-if="showTextFilter"
                    type="button"
                    class="btn btn-light-info cursor-pointer"
                    @click="HandleSearch"
                    >
                    Search
                </button>

            <span class="" v-if="showExport"><a :href="exportUrl" target="_blank" :style="{'visibility': exportUrl.length > 0 ? '' : 'hidden'}">Click to
            download</a></span>
            <button  v-if="showExport" :data-kt-indicator="loadingExport ? 'on' : null" @click="clickEvent('export')"
            class="btn btn-info btn-danger" type="button">
                <span v-if="!loadingExport" class="indicator-label">
                    <img src="@/assets/images/export-xls.svg" alt="Export" />
                </span>
                <span v-if="loadingExport" class="indicator-progress">
                    Please wait...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            </button>
        </div>

        <div class="search-buttons-block d-flex align-items-center" v-if="showReset">
            <div class="bd-highlight">
                <button
                type="button"
                class="btn btn-light-info"
                @click="clickEvent('reset')"
                >
                Reset
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
 import { defineEmits, ref, watchEffect } from 'vue'
import { store } from '@/store'
import Etcs from "@/core/services/Callable/Etcs";

 const props = defineProps({
    showTextFilter: {
        type: Boolean,
        required: false,
        default: false
    },
    showExport: {
        type: Boolean,
        required: false,
        default: true
    },
    showReset: {
        type: Boolean,
        required: false,
        default: true
    },
    modelValue: {
        type: String,
        required: false
    },
    textFilterPlaceHolder: {
        type: String,
        required: false,
        default: 'Quote Ref. No'
    },
    textFilterPopUp: {
        type: String,
        required: false,
        default: 'Search using Quote Ref. No'
    },
    loadingExport: {
        type: Boolean,
        required: false,
        default: false
    },
    exportUrl: {
        type: String,
        required: false,
        default: ''
    },
    autoSuggest: {
        type: Boolean,
        required: false,
        default: false
    },
    leadType:{
        type: Number,
        required: false
    },
 })

 const filter = ref({
    search: ''
 })

 watchEffect(() => {
    console.log('autoSuggest',props.autoSuggest)
    console.log('prop',props.modelValue)
    filter.value.search = props.modelValue
})

 const emit = defineEmits([
    'update:modelValue',
    'search',
    'export',
    'reset'
 ])

 function clickEvent(type) {
    console.log('Before reset:', filter.value.search)
    filter.value.search = ''
    console.log('After reset:', filter.value.search)

    // Clear the input field manually
    const inputElement = document.querySelector('.el-autocomplete .el-input__inner')
    if (inputElement) {
        inputElement.value = ''
        inputElement.dispatchEvent(new Event('input'))
    }
    
    emit(type, type)
 }

 function HandleSearch() {
    emit('update:modelValue', filter.value.search)
    emit('search', filter.value.search)
 }



    const quoteAutoSuggestAPI = async () => {
        if (filter.value.search.length > 2) {

            console.log(filter.value.search)
            const type = props.leadType == 1 ? 'individual' : 'group';
            const data = await Etcs.autoSuggestQuote({search: filter.value.search, type : type});
            // console.log('data', data.data.data)
            return  data.data.data
        }
    };

    const quoteAutoSuggest = async (queryString, cb) => {
        // console.log(queryString)
        filter.value.search = queryString
        const result = await quoteAutoSuggestAPI();
        cb(result);
    };

</script>

<style>
.search-block .search-field-block  {
    width: 250px !important
}
.search-block .svg-icon {
    z-index: 99;
}
.search-block .el-autocomplete .el-input__inner {
    padding-left: 3.5rem;
    color: #5e6278 !important;
}
</style>