// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum AuthActionTypes {
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    VERIFY_TOKEN = 'VERIFY_TOKEN',
    SWITCH_ROLE = 'SWITCH_ROLE',
    SWITCH_APP = 'SWITCH_APP',
    SWITCH_APP_RELOAD = 'SWITCH_APP_RELOAD',
    GET_NEW_AUTH_CHECK='GET_NEW_AUTH_CHECK'
  }
  