import { store } from "@/store";
import { App } from "vue";
import { CustomerActionTypes } from '@/store/enums/Customer/Actions';
import { CustomerMutationTypes } from '@/store/enums/Customer/Mutations';
import { ElLoading } from 'element-plus'
import { customer } from "@/store/stateless";

class Customers {
    
    public static vueInstance: App;

    public static init(app: App<Element>) {
        Customers.vueInstance = app;
    }

    public static async quickLead(payload) {
        return await store.dispatch(CustomerActionTypes.QUICK_LEAD, payload);
    }

    public static setQuckLeadModal(payload) {
        store.commit(CustomerMutationTypes.SET_QUICK_LEAD_DIALOG, payload);
    }

    public static setImportModal(payload) {
        store.commit(CustomerMutationTypes.SET_IMPORT_DIALOG, payload);
    }

    public static setTransferModal(payload) {
        store.commit(CustomerMutationTypes.SET_CUSTOMER_TRANSFER_DIALOG, payload);
    }

    public static setFilter(payload) {
        store.commit(CustomerMutationTypes.SET_CUSTOMER_FILTER, payload);
    }

    public static setHealth(payload) {
        // console.log(payload)
        store.commit(CustomerMutationTypes.SET_CUSTOMER_HEALTH, payload);
    }

    public static async customerLists(payload) {
        // customer.checkedTransfer = [];
        const loading = ElLoading.service({
            target: '.leads-tasks-filter-v2',
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.1)',
        })
        const data = await store.dispatch(CustomerActionTypes.CUSTOMER_LISTS, payload);
        loading.close()
        return data
    }
    public static async customerListsCount(payload) {
        const data = await store.dispatch(CustomerActionTypes.CUSTOMER_LISTS_COUNT, payload);
        return data
    }

    public static async health(payload) {
        return await store.dispatch(CustomerActionTypes.HEALTH, payload);
    }

    public static async declarations(payload) {
        return await store.dispatch(CustomerActionTypes.DECLARATIONS, payload);
    }

    public static async getDeclarations(payload) {
        return await store.dispatch(CustomerActionTypes.GET_DECLARATIONS, payload);
    }

    public static resetQuickLead() {
        store.commit(CustomerMutationTypes.SET_QUICK_LEAD, {
            email: "",
            is_health: 1,
            lead_source: "skye",
            name: "",
            phone_country_code: "+971",
            phone_number: "",
            salesAgents: "",
        })
    }

    public static checkedTransfer(payload) {
        store.commit(CustomerMutationTypes.SET_CUSTOMER_TRANSFER_CHECKED, payload)
    }

    public static async import(payload) {
        return await store.dispatch(CustomerActionTypes.IMPORT_CUSTOMER_LISTS, payload);
    }

    public static async transferAgent(payload) {
        return await store.dispatch(CustomerActionTypes.TRANSFER_AGENT, payload)
    }

    public static async openLeadDetails(payload) {
        // only accordian header data
        return await store.dispatch(CustomerActionTypes.DETAIL_CUSTOMER_LEAD, payload);
    }

    public static async details(payload) {
        return await store.dispatch(CustomerActionTypes.DETAIL_CUSTOMER, payload);
    }

    public static async getLeadsByMapId(payload) {
        return await store.dispatch(CustomerActionTypes.DETAIL_CUSTOMER_BY_MAP_ID, payload)
    }

    public static async getLeadsByLeadId(payload) {
        return await store.dispatch(CustomerActionTypes.DETAIL_CUSTOMER_BY_LEAD_ID, payload)
    }

    public static async deleteLead(payload) {
        return await store.dispatch(CustomerActionTypes.CUSTOMER_DELETE_LEAD, payload)
    }

    public static async updateLead(payload) {
        return await store.dispatch(CustomerActionTypes.UPDATE_CUSTOMER_LEADS, payload);
    }

    public static async addLeadTask(payload) {
        return await store.dispatch(CustomerActionTypes.ADD_LEAD_TASK, payload);
    }

    public static async updateCustomerDetails(payload) {
        return await store.dispatch(CustomerActionTypes.UPDATE_CUSTOMER_DETAILS, payload);
    }

    public static async updateCustomerOtherContactInfo(payload) {
        return await store.dispatch(CustomerActionTypes.UPDATE_CUSTOMER_CONTACT_INFO, payload);
    }

    public static async getCustomerById(payload) {
        return await store.dispatch(CustomerActionTypes.CUSTOMER_DETAILS, payload);
    }

    public static async getAllTaskByLeadAndCustomerId(payload) {
        return await store.dispatch(CustomerActionTypes.VIEW_TASKS, payload);
    }

    public static clearTaskDetails() {
        return store.commit(CustomerMutationTypes.SET_VIEW_TASKS, {})
    }

    public static async getCustomerNotesByLeadId(payload) {
        return await store.dispatch(CustomerActionTypes.NOTES, payload)
    }

    public static async addLeadNote(payload) {
        return await store.dispatch(CustomerActionTypes.ADD_NOTE, payload)
    }

    public static async getCustomerEmail(payload) {
        return await store.dispatch(CustomerActionTypes.EMAILS, payload)
    }

    public static async addCustomerEmail(payload) {
        return await store.dispatch(CustomerActionTypes.ADD_EMAIL, payload)
    }

    public static async getCustomerActivityLog(payload) {
        return await store.dispatch(CustomerActionTypes.CUSTOMER_ACTIVITY_LOGS, payload)
    }

    public static async getCustomerStatusLog(payload) {
        return await store.dispatch(CustomerActionTypes.CUSTOMER_STATUS_LOGS, payload)
    }

    public static async getLeadActivityLog(payload) {
        return await store.dispatch(CustomerActionTypes.LEAD_ACTIVITY_LOGS, payload)
    }

    public static async getLeadStatusLog(payload) {
        return await store.dispatch(CustomerActionTypes.LEAD_STATUS_LOGS, payload)
    }

    public static async mergeLead(payload) {
        return await store.dispatch(CustomerActionTypes.MERGE_LEAD, payload)
    }

    public static resetCustomerLog() {
        store.commit(CustomerMutationTypes.SET_CUSTOMER_ACTIVITY_LOG, [])
        store.commit(CustomerMutationTypes.SET_CUSTOMER_VIEW_ACTIVITY_LOG, [])
        store.commit(CustomerMutationTypes.SET_CUSTOMER_STATUS_LOG, [])
    }

    public static resetLeadActivity() {
        store.commit(CustomerMutationTypes.SET_LEAD_ACTIVITY_LOG, [])
        store.commit(CustomerMutationTypes.SET_LEAD_STATUS_LOG, [])
    }

    public static resetNotes() {
        store.commit(CustomerMutationTypes.SET_VIEW_NOTES, [])
        store.commit(CustomerMutationTypes.SET_VIEW_NOTES_LOADING, true)
    }

    public static async getMembersUsingMap(payload) {
        return await store.dispatch(CustomerActionTypes.GET_MEMBERS, payload)
    }

    public static async getCustomerSMS(payload) {
        return await store.dispatch(CustomerActionTypes.SMS, payload)
    }
}

export default Customers;