
import { defineComponent, ref, computed } from 'vue'
import { documents } from '@/core/utils'
import { store } from '@/store';
import Documents from '@/core/services/Callable/Documents';

export default defineComponent({
    props: {
        isView: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup(_) {

        const policy_documents = computed(() => {
            return store.state.policy.policy_documents
        })

        const documents = computed(() => {
            return store.getters.getAllDocuments(2, _.isView)
        })

        const downloadZip = () => {
            let policyDocArray = Array < any > ();
            let tempPolicyDocArray = Array < any > ();

            documents.value?.map((doc) => {
                // console.log(doc)
                if (doc?.is_temp == 1 && (doc?.document_type == 'verification' || (doc.document_type_master &&  doc.document_type_master.document_type == 'verification'))) {
                    tempPolicyDocArray.push(doc.document_id);
                }
                else if (doc?.is_temp == 0 && (doc?.document_type == 'verification' || (doc.document_type_master &&  doc.document_type_master.document_type == 'verification'))) {
                    policyDocArray.push(doc.document_id);
                }
            })


            const payload = {
                policy: policyDocArray,
                temp: tempPolicyDocArray,
            }
            // console.log(payload);
            Documents.downloadZip(payload);
        };

        return {
            documents,
            store,
            policy_documents,
            downloadZip
        }
    },
})
