// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum CustomerActionTypes {
    QUICK_LEAD = 'QUICK_LEAD',
    CUSTOMER_LISTS = 'CUSTOMER_LISTS',
    CUSTOMER_LISTS_DATA = 'CUSTOMER_LISTS_DATA',
    CUSTOMER_LISTS_COUNT = 'CUSTOMER_LISTS_COUNT',
    IMPORT_CUSTOMER_LISTS = 'IMPORT_CUSTOMER_LISTS',
    TRANSFER_AGENT = 'TRANSFER_AGENT',
    DETAIL_CUSTOMER = 'DETAIL_CUSTOMER',
    DETAIL_CUSTOMER_BY_MAP_ID = 'DETAIL_CUSTOMER_BY_MAP_ID',
    DETAIL_CUSTOMER_BY_LEAD_ID = 'DETAIL_CUSTOMER_BY_LEAD_ID',
    UPDATE_CUSTOMER_LEADS = 'UPDATE_CUSTOMER_LEADS',
    ADD_LEAD_TASK = 'ADD_LEAD_TASK',
    UPDATE_CUSTOMER_DETAILS = 'UPDATE_CUSTOMER_DETAILS',
    UPDATE_CUSTOMER_CONTACT_INFO = 'UPDATE_CUSTOMER_CONTACT_INFO',
    CUSTOMER_DELETE_LEAD = 'CUSTOMER_DELETE_LEAD',
    CUSTOMER_DETAILS = 'CUSTOMER_DETAILS',
    VIEW_TASKS = 'VIEW_TASKS',
    NOTES = 'NOTES',
    EMAILS = 'EMAILS',
    ADD_NOTE = 'ADD_NOTE',
    ADD_EMAIL = 'ADD_EMAIL',
    CUSTOMER_ACTIVITY_LOGS = 'CUSTOMER_ACTIVITY_LOGS',
    CUSTOMER_STATUS_LOGS = 'CUSTOMER_STATUS_LOGS',
    LEAD_ACTIVITY_LOGS = 'LEAD_ACTIVITY_LOGS',
    LEAD_STATUS_LOGS = 'LEAD_STATUS_LOGS',
    MERGE_LEAD = 'MERGE_LEAD',
    HEALTH = 'HEALTH',
    DECLARATIONS = 'DECLARATIONS',
    GET_DECLARATIONS = 'GET_DECLARATIONS',
    GET_MEMBERS = 'GET_MEMBERS',
    CHECK_PHONE_EXIST = 'CHECK_PHONE_EXIST',
    SMS = 'SMS',
    DETAIL_CUSTOMER_LEAD = "DETAIL_CUSTOMER_LEAD"
  }
  