// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum ReportActionTypes {
  GET_REPORT_DASHBOARD_DETAILS = "GET_REPORT_DASHBOARD_DETAILS",
  GET_REPORT_LEAD_BY_SOURCE_FILTER = "GET_REPORT_LEAD_BY_SOURCE_FILTER",
  GET_REPORT_LEAD_SOURCE_FILTER = "GET_REPORT_LEAD_SOURCE_FILTER",
  GET_REQUESTED_URL = "GET_REQUESTED_URL",
  GET_AGENT_SALES_REPORT = "GET_AGENT_SALES_REPORT",
  GET_REPORT_DELETED_LEADS = "GET_REPORT_DELETED_LEADS",
  GET_POLICIES_ASSIGNED_VS_COMPLETED_REPORT = "GET_POLICIES_ASSIGNED_VS_COMPLETED_REPORT",
  GET_ADMIN_REPORT = "GET_ADMIN_REPORT",
  GET_APPROVED_INVOICES_REPORT = "GET_APPROVED_INVOICES_REPORT",
  GET_CLOSED_LEADS_REPORT = "GET_CLOSED_LEADS_REPORT",
  GET_CONVERTED_LEADS_REPORT = "GET_CONVERTED_LEADS_REPORT",
  GET_LEAD_BY_SOURCE_REPORT = "GET_LEAD_BY_SOURCE_REPORT",
  GENERATE_PRODUCTION_REPORT = "GENERATE_PRODUCTION_REPORT",
  GET_PRODUCTION_REPORT = "GET_PRODUCTION_REPORT",
  GET_ACCOUNT_MASTER_REPORT = "GET_ACCOUNT_MASTER_REPORT",
  GET_MARKETING_CAMPAIGN_FILTERS = "GET_MARKETING_CAMPAIGN_FILTERS",
  GET_MARKETING_CAMPAIGN_AMOUNT_SPEND = "GET_MARKETING_CAMPAIGN_AMOUNT_SPEND",
  UPDATE_MARKETING_CAMPAIGN_AMOUNT_SPEND = "UPDATE_MARKETING_CAMPAIGN_AMOUNT_SPEND",
  GET_MARKETING_REPORT = "GET_MARKETING_REPORT"
}
  