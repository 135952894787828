
import { defineComponent, computed, ref, watchEffect } from 'vue'
import Customers from '@/core/services/Callable/Customers';
import { store } from "@/store";
import { customer, notification } from '@/store/stateless';

export default defineComponent({
  setup() {

    const filter = computed(() => {
      return store.state.customer.filter
    })

    const taskCounts = computed(() => {
      return store.state.etc.taskCount
    })

    const leadFilterCounts = computed(() => {
      return store.state.customer.leadFilterCounts
    })

    const customerLists = computed(() => {
      return store.state.customer.customerLists
    })

    const lists = computed(() => {
            return store.getters.getLeadsAndTasks
        })

    const getTaskListFor = async (e) => {
      filter.value.task_due_dates = e;
      filter.value.page = 1;
      await Customers.customerLists(filter.value)
    }

    const isSelectAll = ref(false)
    const onSelectAllCustomer = (event) => {
      customer.transferAll = false;
      // console.log(isSelectAll.value);
      if (isSelectAll.value) {
        customer.checkedTransfer = lists.value?.map(function (customer) {
          return customer.customer_id;
        });

      }
      else {
        customer.checkedTransfer = [];
      }

      // console.log('transer',customer.checkedTransfer)
    }

    watchEffect(() => {

      if(customer.checkedTransfer.length != 0 && customer.checkedTransfer.length === lists.value.length){
        isSelectAll.value = true
      }
      else {
        isSelectAll.value = false
      }
      // if(customer.transferAll){
      //   isSelectAll.value = false
      // }
    })

    return {
      store,
      filter,
      taskCounts,
      customerLists,
      getTaskListFor,
      isSelectAll,
      onSelectAllCustomer,
      leadFilterCounts
    }
  },
})
