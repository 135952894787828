import { ActionTree, ActionContext } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';
import { State } from './state';
import { Mutations } from './mutations';
import { Mutations as etcMutation } from '@/store/modules/Etc/mutations';
import { PolicyMutationTypes } from '@/store/enums/Policy/Mutations';
import { PolicyActionTypes } from '@/store/enums/Policy/Actions';
import ApiService from "@/core/services/ApiService";
import { Links } from '@/store/enums/Links';
import { notification } from '@/store/stateless'
import { store } from "@/store";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
  commit<K extends keyof etcMutation>(
    key: K,
    payload: Parameters<etcMutation[K]>[1],
  ): ReturnType<etcMutation[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {

}

export const actions: ActionTree<State, RootState> & Actions = {
  

  async [PolicyActionTypes.POLICY_LISTS]({ commit }, payload) {
    try {
      const data =  await ApiService.post(Links.POST_POLICY_LIST + `?page=${payload.page}`, payload)
      // console.log(data);
      commit(PolicyMutationTypes.SET_POLICY_LISTS, data.data.data)
      if(payload.status != 4)
        store.dispatch(PolicyActionTypes.POLICY_LISTS_COUNT, payload);
    } catch (err: any) {
      return err;
    }
  },

  async [PolicyActionTypes.POLICY_LISTS_COUNT]({ commit }, payload) {
    try {
      const data =  await ApiService.post(Links.POST_POLICY_LIST_COUNT, payload)
      // console.log(data);
      // commit(PolicyMutationTypes.SET_POLICY_LISTS, data[0].data.data)
      commit(PolicyMutationTypes.SET_POLICY_LIST_COUNTS, data.data.data)
    } catch (err: any) {
      return err;
    }
  },

  async [PolicyActionTypes.GET_POLICY]({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.GET_POLICY, payload)
      const info = data.data.data;
      const policy = {
        policy_purchase_id: info.id,
        status: info.status,
        is_group: info.is_group,
        lead_id: info.lead_id,
        lead: info.lead,
        customer_id: info.customer_id,
        customer: info.customer,
        insured_person:info.insured_person,
        emirates_id:info.emirates_id,
        policy_holder:info.policy_holder,
        policy_holder_emirates_id:info.policy_holder_emirates_id,
        policy_name:info.policy?.policy_name,
        policy_number:info.policy_number,
        policy_start_date:info.policy_start_date,
        policy_end_date:info.policy_end_date,
        passport: info.passport,
        insurance_payment: get_insurance_payment(info.insurance_payment, info.main_invoice.payment_type),
        entity_type:info.entity_type,
        main_invoice:info.main_invoice,
        policy_sales_agent:info.policy_sales_agent,
        credit_debit_details:getCreditDebitDetails(info.invoices,info),
        group_categories:info.is_group == 1 ? getGroupDetails(info.main_invoice,info) : [],
        amend_documents: info.health_lead_amend_documents,
        is_amended:info.is_amended
      }
      commit(PolicyMutationTypes.SET_POLICY, policy)
      return data;
    }
    catch (err: any) {
      return err;
    }
  },

  async [PolicyActionTypes.GROUP_GET_POLICY]({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.GROUP_GET_POLICY, payload)
      const info = data.data.data;
      const policy = {
        policy_purchase_id: info.id,
        status: info.status,
        is_group: info.is_group,
        lead_id: info.lead_id,
        lead: info.lead,
        customer_id: info.customer_id,
        customer: info.customer,
        insured_person:info.insured_person,
        emirates_id:info.emirates_id,
        trade_license_issue_date: info.trade_license_issue_date,
        trade_license_expiry_date: info.trade_license_expiry_date,
        policy_holder:info.policy_holder,
        policy_holder_emirates_id:info.policy_holder_emirates_id,
        policy_name:info.policy?.policy_name,
        policy_number:info.policy_number,
        policy_start_date:info.policy_start_date,
        policy_end_date:info.policy_end_date,
        insurance_payment: get_insurance_payment(info.insurance_payment, info.main_invoice.payment_type),
        entity_type:info.entity_type,
        main_invoice: getAllCategories(info),
        trade_license: info.trade_license,
        trade_license_path: info.trade_license_path,
        policy_sales_agent:info.policy_sales_agent,
        credit_debit_details:getCreditDebitDetails(info.invoices,info),
        group_categories:info.is_group == 1 ? getGroupDetails(info.main_invoice,info) : [],
        amend_documents: info.health_lead_amend_documents,
        is_amended:info.is_amended
      }
      commit(PolicyMutationTypes.SET_POLICY, policy)
      return data;
    }
    catch (err: any) {
      return err;
    }
  },

  async [PolicyActionTypes.UPDATE_POLICY]({ commit }, payload) {
    try {
        const data = await ApiService.post(Links.UPDATE_POLICY, payload)
        return data;
    } catch (err: any) {
      return err;
    }
  },

  async [PolicyActionTypes.SUBMIT_POLICY]({ commit }, payload) {
    try {
        const data = await ApiService.post(Links.SUBMIT_POLICY, payload)
        return data;
    } catch (err: any) {
      return err;
    }
  },

  async [PolicyActionTypes.GET_EDIT_POLICY_SETTING]({ commit }) {
    try {
        const response = await ApiService.post_get(Links.GET_EDIT_POLICY_SETTING)
      if(response) {
        const settingValue = parseInt(response.data.data.setting.value)
        const upcomingValue = parseInt(response.data.data.upcoming.value)
        const debit_amount_per = parseInt(response.data.data.debit_amount_per.value)
        commit(PolicyMutationTypes.SET_POLICY_START_DATE_DAYS_RESTRICT, settingValue)
        commit(PolicyMutationTypes.SET_POLICY_START_DATE_DAYS_RESTRICT_UPCOMING, upcomingValue)
        commit(PolicyMutationTypes.SET_POLICY_ACCEPTED_DEBIT_AMOUNT_PER, debit_amount_per)
      }
    } catch (err: any) {
      return err;
    }
  },


  async [PolicyActionTypes.TRANSFER_SALE]({ commit }, payload) {
    try {
        const data = await ApiService.post(Links.TRANSFER_SALE, payload)
        return data;
    } catch (err: any) {
      return err;
    }
  },

  async [PolicyActionTypes.CHANGE_UNDERWRITER]({ commit }, payload) {
    try {
        const data = await ApiService.post(Links.CHANGE_UNDERWRITER, payload)
        return data;
    } catch (err: any) {
      return err;
    }
  },

  async [PolicyActionTypes.GET_PURCHASED_POLICIES]({ commit }, payload) {
    try {
        const data = await ApiService.post(Links.GET_PURCHASED_POLICIES, payload)

        console.log(data)
        commit(PolicyMutationTypes.SET_PURCHASED_POLICIES, data.data.data)

        return data;
    } catch (err: any) {
      return err;
    }
  },

  async [PolicyActionTypes.GET_POLICY_LOG]({ commit }, payload) {
    try {
        const data = await ApiService.post(Links.GET_POLICY_LOG, payload)
        console.log(data)
        commit(PolicyMutationTypes.SET_POLICY_LOG, data.data.data)

        return data;
    } catch (err: any) {
      return err;
    }
  },

  async [PolicyActionTypes.SEND_CUSTOMER_EMAIL]({ commit }, payload) {
    try {
        const data = await ApiService.post(Links.POLICY_SEND_CUSTOMER_EMAIL, payload)
        console.log(data)
        return data;
    } catch (err: any) {
      return err;
    }
  },

  async [PolicyActionTypes.SEND_BROKER_EMAIL]({ commit }, payload) {
    try {
        const data = await ApiService.post(Links.POLICY_SEND_BROKER_EMAIL, payload)
        console.log(data)
        return data;
    } catch (err: any) {
      return err;
    }
  },

  async [PolicyActionTypes.GET_CHILD_POLICIES]({ commit }, payload) {
    try {
        const data = await ApiService.post(Links.GET_CHILD_POLICIES, payload)
        console.log(data)
        return data;
    } catch (err: any) {
      return err;
    }
  },


  async [PolicyActionTypes.GET_EMAIL_ACTIVITY]({ commit }, payload) {
    try {
        const data = await ApiService.post(Links.GET_EMAIL_ACTIVITY, payload)
        commit(PolicyMutationTypes.SET_POLICY_EMAIL_ACTIVITY, data.data.data.activites)
        console.log(data.data.data.activites)
        return data;
    } catch (err: any) {
      return err;
    }
  },

  async [PolicyActionTypes.GROUP_POLICY_LISTS]({ commit }, payload) {
    try {
        const data = await ApiService.post(Links.GROUP_POST_POLICY_LIST + `?page=${payload.page}`, payload)
        commit(PolicyMutationTypes.SET_GROUP_POLICY_LISTS, data.data.data)
        if(payload.status != 4)
          store.dispatch(PolicyActionTypes.GROUP_POST_POLICY_LIST_COUNT, payload);
    } catch (err: any) {
      return err;
    }
  },

  async [PolicyActionTypes.GROUP_POST_POLICY_LIST_COUNT]({ commit }, payload) {
    try {
        const data = await ApiService.post(Links.GROUP_POST_POLICY_LIST_COUNT, payload)
        commit(PolicyMutationTypes.SET_GROUP_POLICY_LIST_COUNTS, data.data.data)
    } catch (err: any) {
      return err;
    }
  },

  
  async [PolicyActionTypes.GROUP_SUBMIT_POLICY]({ commit }, payload) {
    try {
        const data = await ApiService.post(Links.GROUP_SUBMIT_POLICY, payload)
        return data;
    } catch (err: any) {
      return err;
    }
  },

  
  async [PolicyActionTypes.GROUP_UPDATE_POLICY]({ commit }, payload) {
    try {
        const data = await ApiService.post(Links.GROUP_UPDATE_POLICY, payload)
        return data;
    } catch (err: any) {
      return err;
    }
  },

  async [PolicyActionTypes.GROUP_UPLOAD_TRADE_LICENSE]({commit}, payload) {
    try {
      const data = await ApiService.post(Links.GROUP_UPLOAD_TRADE_LICENSE, payload)
      return data
    } catch (err: any) {
      return err
    }
  },

  
  async [PolicyActionTypes.GROUP_GET_CHILD_POLICIES]({ commit }, payload) {
    try {
        const data = await ApiService.post(Links.GROUP_GET_CHILD_POLICIES, payload)
        return data;
    } catch (err: any) {
      return err;
    }
  },

  async [PolicyActionTypes.SEND_GROUP_BROKER_EMAIL]({ commit }, payload) {
    try {
        const data = await ApiService.post(Links.GROUP_POLICY_SEND_BROKER_EMAIL, payload)
        console.log(data)
        return data;
    } catch (err: any) {
      return err;
    }
  },

  async [PolicyActionTypes.GET_GROUP_EMAIL_ACTIVITY]({ commit }, payload) {
    try {
        const data = await ApiService.post(Links.GROUP_GET_EMAIL_ACTIVITY, payload)
        commit(PolicyMutationTypes.SET_POLICY_EMAIL_ACTIVITY, data.data.data.activites)
        console.log(data.data.data.activites)
        return data;
    } catch (err: any) {
      return err;
    }
  },
  

  async [PolicyActionTypes.GROUP_TRANSFER_SALE]({ commit }, payload) {
    try {
        const data = await ApiService.post(Links.GROUP_TRANSFER_SALE, payload)
        return data;
    } catch (err: any) {
      return err;
    }
  },

  async [PolicyActionTypes.GROUP_CHANGE_UNDERWRITER]({ commit }, payload) {
    try {
        const data = await ApiService.post(Links.GROUP_CHANGE_UNDERWRITER, payload)
        return data;
    } catch (err: any) {
      return err;
    }
  },

  async [PolicyActionTypes.GROUP_LEAD_POLICY_DETAILS]({ commit }, payload) {
    try {
        const data = await ApiService.post(Links.LEAD_POLICY_DETAILS, payload)
        commit(PolicyMutationTypes.SET_GROUP_LEAD_POLICY_DETAILS, data.data.data)
        return data;
    } catch (err: any) {
      return err;
    }
  },

  async [PolicyActionTypes.REMOVE_SCAN_DATA]({ commit }, payload) {
    try {
        const data = await ApiService.post(Links.REMOVE_SCAN_DATA, payload)
        return data;
    } catch (err: any) {
      return err;
    }
  },

};


const getCreditDebitDetails = (credit_debit_details, info) => {
  return credit_debit_details.map((x:any) => {

        let debit_amount =  x.debit_amount;
        if(x.amend_type != 2){
          debit_amount = x.debit_amount == 0 ? x.sales_price : x.debit_amount;
        }

        return {
          id: x.id,
          is_amend: x.is_amend,
          payment_date: x.payment_date,
          tax_invoice_date: x.tax_invoice_date ?? x.payment_date,
          merchant_reference: x.merchant_reference,
          sales_price: x.sales_price,
          basmah: x.basmah,
          credit_amount: x.credit_amount,
          credit_notes_no: x.credit_notes_no,
          debit_amount: debit_amount,
          debit_notes_no: x.debit_notes_no,
          endorsement_no: x.endorsement_no,
          amend_type: x.amend_type,
          non_taxable_fee: x.non_taxable_fee,
          amendment_start_date: x.amendment_start_date,
          amendment_end_date:  x.amendment_end_date ?? info.policy_end_date ,
          is_invoice_completed: x.is_policy_saved === 1 ? true : false
        }
  })
}


const getGroupDetails = (main_invoice, info) => {
  if(main_invoice && main_invoice.categories){
    const data = main_invoice.categories.map((x:any) => {
        // x.policy_number = '';
        return x;
    })

    console.log('gData',data);
    return data;
  }

  return [];
}

const getAllCategories = (info) => {
  const main_invoice = info.main_invoice
  const additional = info.add_categories.map(x => x.categories).flat()
 
  additional.map(x => {
    main_invoice.categories.push(x)
  })
  console.log(main_invoice.categories)
  return main_invoice
}

const get_insurance_payment = (payment, payment_type) => {
  if(payment) return payment
  else 
    switch(payment_type) {
      case 3: return 1; //Direct -> Payment Link customer
      case 4: return 2; // Cheque -> Direct Deposit to insurance company
      case 5: return 3; // Bank Transfer -> Direct transfer to insurance company 
      default: return 0;
    }
    
 
}