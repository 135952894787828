import { reactive, ref } from "vue";
import moment from "moment";
import { store } from "@/store";

const common = reactive({
    getYearList() {
        const year = new Date().getFullYear()
        return Array.from({ length: year - 2002 },(value, index) => year - index);
    },

    getY(value) {
        const top = document.querySelector(`#${value}`);
        return top.getBoundingClientRect().y
    },
    getToday(){
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const day = currentDate.getDate() + 1;
        const month = currentDate.getMonth() + 1;

        return `${year}-${month}-${day}`
    },
    minDate(){
        return moment().subtract(17,'y').format('YYYY-MM-DD');
    },

    exceptFutureDates() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const day = currentDate.getDate() > 9 ? currentDate.getDate() : `0${currentDate.getDate()}`;
        const month = (currentDate.getMonth() + 1) > 9 ? (currentDate.getMonth() + 1) : `0${(currentDate.getMonth() + 1)}`;

        return `${year}-${month}-${day}`
    },

    makeFirstCharUpperCase(value) {
        if(!value)
            return '';
        let str   = value.toLowerCase()
        return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
    },

    stringReplace(item, key, value) {
        return item.replace(key, value)
    },

    disableToday() {
        const myDate = new Date()
        return moment(myDate).format('YYYY-MM-DD')
    },

    leadStatus(lead_status_id) {
        // 1 NEW
        // 2 QUICK LEAD
        // 3 PENDING
        // 4 DEAL
        // 5 LEAD RENEWAL
        // 6 LOST LEAD
        // 7 LOST LEAD RENEWAL
        // 8 LEAD RENEWAL LOST
        // 9 DEAL TRANSFERRED
        // 10 SYS QUICK LEAD
        // 11 FALSE LEAD x
        // 12 CANCELLED
        // 13 INTRATRANSACT
        // 14 CLOSED
        switch(lead_status_id) {
            case 1:
                return 'color: #000; background-color: #FFDDDD !important'; break;
            case 2:
                return 'color: #000; background-color: #E79DCE !important'; break;
            case 3:
                return 'color: #000; background-color: #FFB822 !important'; break;
            case 4:
                return 'color: #fff !important; background-color: #34BFA3 !important'; break;
            case 5:
                return 'color: #000; background-color: #9AA4EA !important'; break;
            case 6:
                return 'color: #fff !important; background-color: #F890A1 !important'; break;
            case 7:
                return 'color: #fff !important; background-color: #2162F6 !important'; break;
            case 8:
                return 'color: #fff !important; background-color: #F4516C !important'; break;
            case 9:
            case 12:
            case 13:
            case 14:
                return 'color: #000; background-color: #DDDDDD !important'; break;
            case 16:
                return 'color: #000; background-color: #DDDDDD !important'; break;
            case 10:
                return 'color: #000; background-color: #BFBFBF !important'; break;
            case 16:
                return 'color: #fff !important; background-color: #5867dd !important'; break;
            default: return 'color: #FFF; background-color: #5867DD !important'; break;
    
        }
    },

    userType(e) {
        // 1 New - Not Contacted
        // 2 Return - Not Contacted
        // 3 Return - Follow Up
        // 4 Customer
        // 5 Lost Potential Customer
        // 6 Customer - Not Contacted
        // 7 Customer - Follow Up
        // 8 Partial Customer
        // 9 Lost Potential Customer - Not Contacted 
        // 10 Lost Potential Customer - Follow Up 
        // 11 Lost Customer
        // 12 New - Follow Up
        // 13 Partial Customer - Not Contacted 
        // 14 Partial Customer - Follow Up 
        // 15 Lost Customer - Not Contacted 
        // 16 Lost Customer - Follow Up 
        switch(e) {
            case 1: 
            case 6: 
                return 'color: #000; background-color: #FFDDDD !important'; break;
            case 2: 
                return 'color: #000; background-color: #E79DCE !important'; break;
            case 12: 
            case 7: 
                return 'color: #000; background-color: #FFD881 !important'; break;
            case 3: 
                return 'color: #000; background-color: #FFB822 !important'; break;
            case 4: 
                return 'color: #fff; background-color: #34BFA3 !important'; break;
            case 11: 
            case 15: 
            case 16: 
                return 'color: #fff; background-color: #F4516C !important'; break;
            case 5: 
            case 9: 
            case 10: 
                return 'color: #fff; background-color: #F890A1 !important'; break; 
            case 8: 
            case 13: 
            case 14: 
                return 'color: #000; background-color: #80DCCA !important'; break; 
            default: return 'color: #000; background-color: #5867DD !important'; break;
        }
    },

    getTextWidth(text, font) {
        const canvas = document.createElement("canvas");
        if(canvas) {
            const context = canvas.getContext("2d");

            context.font = font || getComputedStyle(document.body).font;

            return context.measureText(text).width;
        }
    },

    isMobile() {
        if(screen.width <= 760) 
        return true

        return false
    },

    setDateOnly(e) {
        if(e && e != '-') {
            const MF = moment(e, 'DD/MM/YYYY').isValid()
            if(!MF) return moment(e).zone("+0400").format("DD/MM/YYYY");
            else return e
        }

        return e
        
    },

    setDateTimeOnly(e) {
        // console.log('e',e)

        if(e && e != '-' && e != '' ) {
            const MF = moment(e, 'DD/MM/YYYY HH:mm').isValid()
            const IMF = moment(e, 'DD/MM/YYYY HH:mm:ss').isValid()

            if(!MF) return moment(e).zone("+0400").format("DD/MM/YYYY HH:mm");
            else if(!IMF) return moment(e).zone("+0400").format("DD/MM/YYYY HH:mm");
            else return e
        }

        return e
        
    },

    groupSelectedOptions(value) {
        const health = store.state.customer.health
        if(health.categories) {
            const selectedOptions = []
            for(const cat of health.categories)
            {
                selectedOptions.push(cat.name)
                // selectProvider(cat)
            }

            if(selectedOptions.includes(value))
                return true;

            return false;
        }

        return false;
    },

    isNumber(evt){
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            evt.preventDefault();;
        } else {
            return true;
        }
    },

    roundUp(num) {
        var p = Math.pow(10, 2);
        return Math.round(num * p) / p;
    },

    formatCurrency(e) {
        if(e == 0 || e== '0') 
            return 0;
        if(e == '')
            return '';
        
        var format = /[ `!@#$%^&*()_+\=\[\]{};':"\\|,<>\/?~]/;
        if(format.test(e))
            return e
        
        const formatter = new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2
            });
    
            return formatter.format(e);
    },

    formatNumber(e) {
        if(isNaN(e))
            return 0;
            
        const formatter = new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 0
            });
    
            return formatter.format(e);
    },

    useFixFigure(value) {
        if(value) {
            let figures = common.roundUp(parseFloat(value.toString().replace(',', '')))
            if(isNaN(figures)) figures = 0
            return figures
        }
    },

    subDays(date,days){
        return moment(date,'YYYY-MM-DD').subtract(days,'days').format('YYYY-MM-DD');
    },

    getConvertedDeals(lead_id){
        const leads = store.getters.getLeads(false);
        const lead = leads.filter(x => x.lead_id == lead_id);
        if(lead){
            const myLead = lead[0]

            // For the Lead Renewal Case
            if(myLead && ( myLead.lead_status_id == 5 || (myLead.lead_status_id == 3 && myLead.old_status == 5) )) {
                const deals = leads.filter((x) => {
                    if(x.lead_status_id == 4 && x.insured_type_id != 4){
                        const dealDate = moment(x.lead.main_invoice_approved_at, 'DD/MM/YYYY HH:mm').unix(); 
                        const minDate = moment(myLead.policy_expiry, 'DD/MM/YYYY HH:mm').subtract(store.state.etc.settings.hl_renewal_policy_days, "days").unix()
                        const maxDate = moment(myLead.policy_expiry, 'DD/MM/YYYY HH:mm').add(store.state.etc.settings.hl_q_lead_converted_days, "days").unix()
                        
                        if(minDate <= dealDate && dealDate <= maxDate){
                            return true;
                        }
                    }
                }); // individual deals

                return deals;
            }
            else if (myLead && ( myLead.lead_status_id == 7 || (myLead.lead_status_id == 3 && myLead.old_status == 7) )){
                // For LLR Case
            // console.log('getConverted',myLead)

                const deals = leads.filter((x) => {
                    if(x.lead_status_id == 4 && x.insured_type_id != 4){
                        const dealDate = moment(x.lead.main_invoice_approved_at, 'DD/MM/YYYY HH:mm').unix(); 
                        const minDate = moment(myLead.lead.llr_date, 'YYYY-MM-DD').unix()

                        const total = store.state.etc.settings.hl_renewal_policy_days + store.state.etc.settings.hl_q_lead_converted_days 
                        const maxDate = moment(myLead.lead.llr_date, 'YYYY-MM-DD').add(total, "days").unix()
                        
                        // console.log(minDate,dealDate,maxDate);

                        if(minDate <= dealDate && dealDate <= maxDate){
                            return true;
                        }
                    }
                }); // individual deals

                return deals;
            }

        }
        return [];
    }
    
});

const activeTab = ref(-1)
const leadEmployeeCategoryId = ref(0)
const informationModal = ref({
    open: false,
    title: '',
    message: ''
})

export {
    common,
    leadEmployeeCategoryId,
    activeTab,
    informationModal
};