import { reactive, h } from 'vue'
import { ElDivider } from "element-plus";

export const customer = reactive({
    transferAll: false,
    checkedTransfer: [],

    colors: {
        btnBgColor: "#F8F5FF",
        btnColor: "#222261",
        slcBgColor: "#fff",
        slcColor: "#5E6278",
        btnBgColorHover: "#233F8F",
        btnColorHover: "#F5F8FA",
    },

    yesAndNo: [
        {
            label: "Yes",
            value: true
        },
        {
            label: "No",
            value: false
        }
    ]
})

export const details = reactive({
    size: 30,
    spacer: h(ElDivider, { direction: "vertical" }),
})

export const etc = reactive({
    in_array(value, arr) {
        var status = false;
    
        for (var i = 0; i < arr.length; i++) {
            var name = arr[i];
            if (name == value) {
            status = true;
            break;
            }
        }
    
        return status;
    }
})

export const statusModal = reactive({
    showCategory: '',
    isNotValidBrowser: false
})

export const registration = reactive({
    country: 'UAE',
    currency: 'AED'
})