
import { defineComponent, ref, computed, getCurrentInstance } from "vue";
import { store } from "@/store";
import { common } from "@/store/stateless";
import { details } from "@/core/utils";
import Customers from '@/core/services/Callable/Customers';

export default defineComponent({
  props: {
    lead_id: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  setup(_) {
    const open = ref<Boolean>(false);

    const activeTab = ref<Number>(0);
    const selectedlead = ref();

    const leads = computed(() => {
      return store.getters.getLeads(true);
    });

    const activeValue = ref(0);

    const changeValue = () => {
      fetchData(activeValue.value);
    };

    const fetchData = async (value: number) => {
      activeTab.value = value;
      const lead = leads.value.find((x) => x.lead_id === value);

      const params = {
                lead_id : lead.lead_id,
                customer_id : lead.customer_id,

      }
      const data = await Customers.openLeadDetails(params)
      // console.log('selectedlead',data);
      selectedlead.value = store.getters.getLeadsDetails(false)
    };

    const inst = getCurrentInstance();
    const modal = (value: Boolean) => {
      open.value = value;
      const myLead = leads.value.find((x) => x.tab_disabled !== true);
      let lead_id = myLead.lead_id;
      if (_.lead_id > 0) {
        lead_id = _.lead_id;
      }
      activeValue.value = lead_id;
      changeValue();

      setTimeout(() => {
        const listRef: any = inst?.refs[`lead_${lead_id}`];
        // listRef.click();
      }, 500);
    };

    return {
      selectedlead,
      activeTab,
      details,
      common,
      leads,
      open,
      modal,
      fetchData,
      store,
      activeValue,
      changeValue,
    };
  },
});
