
import { defineComponent, computed, ref, getCurrentInstance, onMounted, watch } from 'vue'
import Policy from '@/core/services/Callable/Policy';
import Etcs from '@/core/services/Callable/Etcs';
import { store } from "@/store";
import moment from 'moment';
import { customer, showHide, filter as filters, common } from '@/store/stateless';
import { Search } from '@element-plus/icons-vue';
import router from '@/router';

const __default__ = defineComponent({
    name: 'policies-first-layer',
    setup() {

        const permissions = computed(() => {
            return store.getters.getUserPermissions
        })
        const currentInst = getCurrentInstance();
        const roles = currentInst?.appContext.config.globalProperties.roles;
        const transfer = ref()
        // const value2 = ref('')

        const shortcuts = [
            {
                text: 'Last week',
                value: () => {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                    return [start, end]
                },
            },
            {
                text: 'Last month',
                value: () => {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                    return [start, end]
                },
            },
            {
                text: 'Last 3 months',
                value: () => {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                    return [start, end]
                },
            },
        ]

        const agents = ref<any>([]);
        const underwriters = ref<any>([]);
        const dates = ref<any>([])

        const policyLists = computed(() => {
            return store.state.policy.policyLists;
        })

        const filter = computed(() => {
            return store.state.policy.filter;
        })
        
        watch(filter, (newFilter) => {
            dates.value = [newFilter.start_date, newFilter.end_date];
        }, { deep: true });

        onMounted(async () => {
            dates.value = [filter.value.start_date,filter.value.end_date]
            await Etcs.getAgentLists();

            const agent = store.state.etc.agents;
            agent.unshift({ label: 'Select Policy Sales Agent', value: "all" });
            agents.value = agent;

            await Etcs.getUnderwriterLists();

            const underwriter = store.state.etc.underwriters;
            underwriter.unshift({ label: 'Select Underwriter', value: "all" });
            underwriters.value = underwriter;
        })

        

        const getDates = async (e) => {
            var start = moment(e[0]).format('YYYY-MM-DD');
            var end = moment(e[1]).format('YYYY-MM-DD');

            filter.value.task_due_dates = 'custom'
            filter.value.start_date = start;
            filter.value.end_date = end;
            getPolicyListFor(filter.value);
        }

        const getPolicyListFor = async (e) => {
            filter.value.page = 1;
            await Policy.policyLists(filter.value)
        }
        const resetFilter = () => {
            filter.value.task_due_dates = 'all'
            filter.value.policy_sales_agent = "all";
            filter.value.underwriter = "all";
            // filter.value.per_page = 50;
            filter.value.keyword = "";
            
            if(filter.value.status == 4){
                filter.value.start_date = moment().subtract(3, 'months').format('YYYY-MM-DD');
                filter.value.end_date = moment().format('YYYY-MM-DD');
            }
            else {
                filter.value.start_date = "";
                filter.value.end_date = "";
            }
            filter.value.payment_type = -1;
            dates.value = [filter.value.start_date,filter.value.end_date]

            getPolicyListFor(filter.value);
        };

        const searchFilter = () => {
            filter.value.page = 1
            filter.value.task_due_dates = 'all'

            // router.replace({ query: {  page: filter.value.page } })
            getPolicyListFor(filter.value);
        };



        return {
            dates,
            agents,
            underwriters,
            common,
            store,
            customer,
            filter,
            filters,
            transfer,
            shortcuts,
            policyLists,
            getDates,
            showHide,
            Search,
            resetFilter,
            searchFilter,
            permissions
        }
    },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "34ca70b2": (_ctx.customer.colors.btnBgColor),
  "3dd8af2c": (_ctx.customer.colors.btnColor),
  "402dc1d5": (_ctx.customer.colors.btnBgColorHover),
  "cf067520": (_ctx.customer.colors.btnColorHover),
  "05aae519": (_ctx.customer.colors.slcBgColor),
  "69d5811e": (_ctx.customer.colors.slcColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__