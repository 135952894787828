
import { defineComponent, computed, getCurrentInstance, onMounted } from 'vue'
import Customers from '@/core/services/Callable/Customers';
import { rule, common, constant } from '@/store/stateless';
import Etcs from '@/core/services/Callable/Etcs';
import { store } from "@/store";
import router from '@/router/clean';

export default defineComponent({
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        makeInvoiceDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        disabledInsuranceFor: {
            type: Boolean,
            required: false,
            default: false
        },
        disabledInsuranceType: {
            type: Boolean,
            required: false,
            default: false
        },
        disabledInsuranceForself: {
            type: Boolean,
            required: false,
            default: false
        },
        editScreen: {
            type: Boolean,
            required: false,
            default: false
        },
        allowInsuredTypeUpdate:{
            type: Boolean,
            required: false,
            default: false
        },
        ql:{
            type: Boolean,
            required: false,
            default: false
        },
        disabledGQInsuranceFor:{
            type: Boolean,
            required: false,
            default: false
        },
        isllrNa: {
            type:Boolean,
            required:false,
            default:false
        },
        hideEmployeeType: {
            type:Boolean,
            required:false,
            default:false
        },
        isExpectedStartDateDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
    },

    setup(_, context) {

        const health = computed(() => {
            return store.state.customer.health;
        })

        const selectedInsurance = () => {
            if(_.allowInsuredTypeUpdate){
                updateInsuredType()
            }
            else
                setDefaultValue()
        }

        const updateInsuredType = () => {
            // in edit screen converting myself to Family
            if(health.value.insurance_type  == 0)
                health.value.insurance_type = health.value?.insured_type_id === 2 ? 1 : 0
            if(!_.ql){
                // in case of Ql Ignore only applicable in case of old myself to other
                health.value.deleted_members = health.value.members;
                health.value.members = [];
                health.value?.members?.unshift({
                    id: null,
                    insured_type_id: null,
                    insured_city: null,
                    member_name: null,
                    relationship: null,
                    gender: null,
                    dob: null,
                    nationality_id: null,
                    is_salary: null,
                    declaration_answers: [],
                    is_married: false
                })
            }
        }

        const setDefaultValue = () => {
            console.log('jjsd',health.value?.insured_type_id )
            
            let relationship = <any>null
            if(health.value?.insured_type_id == 7) relationship = "Referral"
            Customers.setHealth({
                id: health.value?.id,
                name: health.value?.name,
                email: health.value?.email,
                phone_number: health.value?.phone_number,
                phone_country_code: health.value?.phone_country_code,
                insured_city: health.value?.insured_city,
                insured_type_id: health.value?.insured_type_id,
                insured_type_id_prev: health.value?.insured_type_id_prev,
                insurance_type: health.value?.insured_type_id === 2 ? 1 : 0,
                is_salary: health.value?.is_salary,
                expected_insurance_start_date: health.value?.expected_insurance_start_date,
                customer_source: null,
                is_health: 1,
                utm_source: null,
                utm_medium: null,
                utm_campaign: null,
                utm_content: null,
                dob: health.value?.dob,
                nationality_id: health.value?.nationality_id,
                gender: health.value?.gender,
                is_married: health.value?.is_married,
                company_name: health.value?.company_name,
                is_self: null,
                agent: health.value?.agent,
                health_lead_map_id: health.value?.health_lead_map_id,
                deleted_members: [],
                members: [{
                    id: null,
                    member_name: null,
                    relationship,
                    gender: null,
                    dob: null,
                    nationality_id: null,
                    is_salary: null,
                    declaration_answers: [],
                    is_married: false
                }],
                categories: [{
                    id: null,
                    name: null,
                    groups: [{
                        id: null,
                        insurance_provider_id: null,
                        insurance_providers: store.state.etc.providers,
                        insurance_policy_id: null,
                        insurance_policies: [],
                        group_count: 1
                    }]
                }]
            })
        }

        const addMeToSponsor = () => {
            console.log('check')
            if (health.value?.is_self) {
                const sponsor = health.value?.deleted_members?.find(x => x.relationship === 'Sponsor');
                if(sponsor){
                    health.value?.members?.push(sponsor);
                    health.value.deleted_members = health.value?.deleted_members.filter(x => x.relationship != 'Sponsor') 
                }
                else{
                    health.value?.members?.push({
                        id: null,
                        insured_type_id: null,
                        insured_city: null,
                        member_name: health.value.name,
                        relationship: 'Sponsor',
                        gender: health.value.gender,
                        dob: health.value.dob,
                        nationality_id: health.value.nationality_id,
                        is_salary: health.value.is_salary,
                        declaration_answers: [],
                        is_married: health.value.gender === 'FEMALE' ? true : false
                    });
                }
                Etcs.setRelationships(true);
            } else {
                const sponsor = health.value?.members?.find(x => x.relationship === 'Sponsor');
                if (sponsor) {
                    health.value.deleted_members.push(sponsor);
                    health.value.members = health.value?.members.filter(x => x.relationship != 'Sponsor') 
                }
                Etcs.setRelationships(false);
            }
            context.emit('re-validate')
        }

        const minStartDate = computed(() => {
            const datenow = new Date()
            const year = datenow.getFullYear();
            const month = datenow.getMonth() + 1;
            const day = ('0' + datenow.getDate()).slice(-2);
            return year + '-' + month + '-' + day;
        })

        const currentdate = computed(() => {
            const datenow = new Date()
            const day = datenow.getDate();
            const month = datenow.getUTCMonth();
            const year = datenow.getFullYear();
            return new Date(year, month, day);
        })

        const disabledDate = (date: any) => {
            return date.getTime() < new Date(currentdate.value)
        }

        const inst = getCurrentInstance()
        const focus = () => {
            const toFocus: any = inst?.refs[rule.customerDetails]
            if (toFocus !== undefined) {
                toFocus.focus()
                window.scrollTo(0, common.getY('middle'))
            }
        }

        const disableInsuranceType = computed(() => {
            if (health.value?.insured_type_id === 2) return true
            if (_.disabledInsuranceType) return true

            return false
        })

        const checkQlMember = () => {
            if(_.editScreen) {
                console.log('check');
                health.value.members = health.value.members.map((x) =>  {
                    if(x.relationship == 'QL'){
                        x.member_name = null;
                        x.relationship = null;
                    }
                    if(x.relationship == 'myself'){
                        x.member_name = null;
                        x.dob = null;
                        x.gender = null;
                        x.is_salary = false;
                        x.is_married = false;
                        x.nationality_id = null;
                        x.relationship = null;
                    }
                    return x;
                })
            }
        }

        const changeInsuranceType = async () => {
            console.log('here');
            const ITmaster = [constant.insuredFamilyValue, constant.insuredParentValue, constant.insuredEmployeeValue, constant.insuredOtherValue];
            if (_.editScreen && ITmaster.includes(health.value.insured_type_id)) {
                console.log('inside if');
                // on edit screen changing insurance type from Basic to Enhanced for family
                const leadId = parseInt(router.currentRoute.value.params.lead_id.toString())
                let payload = {};
                if (health.value.insurance_type === 1) {
                    payload = {
                        insurance_type: 1,
                        health_lead_map_id: health.value.health_lead_map_id,
                        lead_id: leadId,
                    }
                }
                else {
                    payload = {
                        insurance_type: 2,
                        health_lead_map_id: health.value.health_lead_map_id
                    }
                }

                const result = await Customers.getMembersUsingMap(payload);
                if (result.status == 200) {

                    // addMeToSponsor()
                    const data = result.data.data;

                    const sponsor = data.find(x => x.relationship === 'Myself');
                    if(sponsor)
                        health.value.deleted_members.push(sponsor)
                        
                    const members = data.filter(x => x.relationship != 'Myself').map((x, index) => {

                        return {
                            id: x.id,
                            lead_id: x.lead_id,
                            insured_type_id: health.value.insured_type_id,
                            insured_city: x.insured_city,
                            member_name:  x.is_temp === 0 ? x.member_name : null,
                            relationship: x.is_temp === 0 ? x.relationship : null,
                            gender: x.is_temp === 0 ? x.gender : null,
                            dob: x.is_temp === 0 ? x.dob : null,
                            nationality_id: x.is_temp === 0 ? x.nationality_id : null,
                            is_salary: x.is_temp === 0 ? x.is_salary : false,
                            declaration_answers: x.declaration_answers,
                            is_married: x.is_temp === 0 ? x.is_married == 1 ? true : false : false
                        }
                    })
                    

                    // console.log('members');
                    // console.log(members);

                    // health.value.members = members;
                    if(members.length > 0)
                        health.value.members = members.reverse();
                    // console.log(health.value.members);
                }



            }
        }


        onMounted(() => {
            // const sponsor = health.value?.members.filter(x => x.relationship === 'Sponsor')[0];
            // let members = health.value?.members.filter(x => x.relationship != 'Sponsor'); 
            // members.push(sponsor);
            // health.value.members = members;
        })

        const disabledInsuranceType = () => {
            if(_.makeInvoiceDisabled) 
                return true;
            if(health.value.insured_type_id == 1){
                if(health.value.hasDealInvoice && !health.value.is_renewal && !health.value.is_llr)
                    return true

                return false
            }
            return (_.disabled || disableInsuranceType.value || health.value.is_renewal || (health.value.is_llr && !_.isllrNa)) ? true : false;
        }

        const disabledInsuranceForValue = () => {
            // disabled || disabledGQInsuranceFor || health.is_renewal || (health.insured_type_id == 1 ? false : disabledInsuranceFor )
            if(_.makeInvoiceDisabled) 
                return true;
            if(health.value.insured_type_id == 1){
                if(health.value.is_renewal || health.value.is_llr)
                    return true

                return false
            }
            return (_.disabled || _.disabledGQInsuranceFor || health.value.is_renewal || health.value.is_llr || _.disabledInsuranceFor) ? true : false;
        }

        return {
            rule,
            focus,
            health,
            disabledDate,
            addMeToSponsor,
            selectedInsurance,
            minStartDate,
            disableInsuranceType,
            changeInsuranceType,
            checkQlMember,
            disabledInsuranceType,
            disabledInsuranceForValue
        }
    },
})
