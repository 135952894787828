import { GetterTree } from 'vuex';
import { common } from '@/store/stateless'
import moment from 'moment';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';


import { State } from './state';


export type Getters = {
}

export const getters: GetterTree<State, RootState> & Getters = {

    getGenerateQuotePolicies: (state) => {
        const policies = state.policies;
        // console.log(policies);
        if(policies) {
            const data:Array<any> = [];
            let id = 1;
            policies.forEach((x:Object ) => {

                if(x.hasOwnProperty('memberPremiums')){
                    const memberPremiums = x['memberPremiums'] ?? [];
                    memberPremiums.forEach((premiums,index) => {
                        // console.log(premiums);
                        let name = x['insurance_provider']['provider_name'] + ' - ' + x['policy_name'] ;
                       
                        if(x['insurance_type'] == 2)
                            name += ' - ' + premiums['name'];

                        const object = {
                            id: id,
                            policy_id: x['id'],
                            insurance_type:x['insurance_type'],
                            name: name,
                            premium: index,
                            memberPremiums: [premiums]
                        }

                        data.push(object);
                        id++;
                    })
                }
            })
            // console.log(data);

            return data;
        }
        return [];
    }
}