
import { View, Hide } from '@element-plus/icons-vue';
import { defineComponent, ref } from "vue";

export default defineComponent({
    props: {
        label: {
            type: String,
            required: true,
            default: '',
        },
        subLabel: {
            type: String,
            required: false,
            default: '',
        },
        prop: {
            type: String,
            required: true,
            default: 'User'
        },
        modelValue: {
            type: String,
            required: true,
            default: ''
        },
        placeholder: {
            type: [String, Boolean],
            required: false,
            default: ''
        },
        min: {
            type: String,
            required: false,
            default: ""
        },
        max: {
            type: String,
            required: false,
            default: ""
        },
        maxlength: {
            type: String,
            required: false,
            default: "190"
        },
        minlength: {
            type: [String, Boolean],
            required: false,
            default: false
        },
        isPrefix: {
            type: Boolean,
            required: false,
            default: false
        },
        isSuffix: {
            type: Boolean,
            required: false,
            default: false
        },
        isPrepend: {
            type: Boolean,
            required: false,
            default: false
        },
        isAppend: {
            type: Boolean,
            required: false,
            default: false
        },
        isOptionGroup: {
            type: Boolean,
            required: false,
            default: false
        },
        selectFilterable: {
            type: Boolean,
            required: false,
            default: false
        },
        isSelectMultiple: {
            type: Boolean,
            required: false,
            default: false
        },
        selectMultipleLimit: {
            type: [Number, Boolean],
            required: false,
            default: false
        },
        options: {
            type: Array,
            required: false,
            default: []
        },
        size: {
            type: [String, Boolean],
            required: false,
            default: 'default'
        },
        isInput: {
            type: String,
            required: false,
            default: 'text'
        },
        checkLabel: {
            type: String,
            required: false,
            default: ''
        },
        keydown: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        isOptionButton: {
            type: Boolean,
            required: false,
            default: false
        },
        border: {
            type: Boolean,
            required: false,
            default: false
        },
        isRequired: {
            type: Boolean,
            required: false,
            default: false
        },
        rules: {
            type: Array,
            required: false,
            default: false,
        },
        tooltip: {
            type: String,
            required: false,
        },
        rows: {
            type: Number,
            required: false,
            default: 2
        },
        isShowLabel: {
            type: Boolean,
            required: false,
            default: true
        },
        className: {
            type: String,
            required: false,
            default: 'el-h-44'
        },
        

    }, 

    setup(props, context) {
        const show = ref(false);

        const updateValue = (event) => {
            context.emit("update:modelValue", event)
            context.emit('press', event)
        }

        const inputRef = ref()
        const focus = () => {
            setTimeout(() => {
                if(inputRef.value) inputRef.value.focus()
            }, 500);
        }

        return {
            focus,
            show,
            View,
            Hide,
            inputRef,
            updateValue
        }
    }
})
