
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useStore } from '@/store';
import { common } from '@/store/stateless'
import router from '@/router/clean';
import Customers from '@/core/services/Callable/Customers';
import { store } from "@/store";

export default defineComponent({
    props: {
        topbar: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup() {

        const filter = computed(() => {
            return store.state.customer.filter
        })

        const leads = computed(() => {
            const counts = store.state.customer.customerListCounts

            if (counts) {
                return Object.values(counts).filter((f: any) => f.status !== 2).map((e: any) => {

                    if (e.status == 1) {
                        e.value = "New Leads";
                        e.color = "btn-outline-new";
                        e.click = 'btn-new';
                        e.order = 1
                    }
                    if (e.status == 3) {
                        e.value = "Pending";
                        e.color = "btn-outline-warning";
                        e.click = 'btn-warning';
                        e.order = 2
                    }
                    if (e.status == 4) {
                        e.value = "Deal";
                        e.color = "btn-outline-success";
                        e.click = 'btn-success';
                        e.order = 7
                    }
                    if (e.status == 5) {
                        e.value = "Renewals";
                        e.color = "btn-outline-renewals";
                        e.click = 'btn-renewals';
                        e.order = 4
                    }
                    if (e.status == 6) {
                        e.value = "Lost Leads";
                        e.color = "btn-outline-danger";
                        e.click = 'btn-danger';
                        e.order = 3
                    }
                    if (e.status == 7) {
                        e.value = "LLR";
                        e.color = "btn-outline-lost-renewals";
                        e.click = 'btn-renewals';
                        e.order = 5
                    }
                    if (e.status == 8) {
                        e.value = "LRL";
                        e.color = "btn-outline-lrlost";
                        e.click = 'btn-lr-lost';
                        e.order = 6
                    }
                    if (e.status == 9) {
                        e.value = "Others";
                        e.color = "btn-outline-others";
                        e.click = 'btn-others';
                        e.order = 9
                    }

                    return {
                        text: e.value,
                        counts: e.count,
                        click: e.click,
                        value: e.status,
                        color: e.color,
                        order: e.order
                    }


                }).sort((a, b) => {
                    return a.order - b.order
                })
            }
            return null
        })

        onMounted(async () => {
            const page = router.currentRoute.value.query ? router.currentRoute.value.query.page : 1;
            if(!store.getters.showByRole([1,9,13, 14])) filter.value.agent = store.getters.myId
            
            if(router.currentRoute.value.name?.toString() == 'CustomerDetails')
                await getLists(page);
        })

        const getLists = async (e: any) => {
            const page = parseInt(e ? e : 1);
            
            filter.value.page = page;
            
            // await Customers.customerLists(filter.value)
            await Customers.customerListsCount(filter.value)
        }
        return {
            common,
            leads,
            filter
        }
    },
})
